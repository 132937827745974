import React from "react";
import { Container, Typography } from "@material-ui/core";
import "./sliderCard.css";

const SliderCard = ({ img, name, review }) => {
  return (
    <Container className="slider-container">
      {img && "img"}

      <Typography className="slider-review">{review}</Typography>
      <Typography className="slider-name">{name}</Typography>
    </Container>
  );
};

export default SliderCard;
