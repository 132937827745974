export const CONTACT_US_ACTION = "CONTACT_US_ACTION";
export const SET_LOGIN = "SET_LOGIN";
export const SET_TAB = "SET_TAB";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_LOGOUT_MODAL = "SET_LOGOUT_MODAL";
export const CHANGED_IMAGE = "CHANGED_IMAGE";
export const SET_TRIAL_STATUS = "SET_TRIAL_STATUS";

export const SET_CONTENT_LOADER = "SET_CONTENT_LOADER";
export const SET_FIND_JOBS = "SET_FIND_JOBS";
export const GET_JOBS_CHECK = "GET_JOBS_CHECK";
export const SET_DEFAULT_JOBS = "SET_DEFAULT_JOBS";
export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";
