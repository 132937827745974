import React from "react";
import { Chip } from "@material-ui/core";
import HeartIcon from "../../assets/images/heart.svg";
import FullHeartIcon from "../../assets/images/heartFull.svg";
import locationIcon from "../../assets/images/location.svg";
import breifcaseIcon from "../../assets/images/briefcase.svg";

//Job Card
const JobCard = ({ item, removeJob, saveJob, history, inSavedJobs }) => {
  const [isJobSaved, setJobSaved] = React.useState(
    inSavedJobs ? true : item.is_job_saved
  );

  return (
    <div
      className="job-list-item-container"
      onClick={() => {
        inSavedJobs
          ? history.push(`/dashboard/job/${item.job_id}`)
          : history.push(`/dashboard/job/${item.id}`);
      }}
    >
      <div className="job-header">
        <div className="job-list-item-left">{item.job_title}</div>
        <div className="job-list-item-right cursor-pointer ">
          {isJobSaved ? (
            <img
              src={FullHeartIcon}
              alt="img"
              onClick={e => {
                e.stopPropagation();
                inSavedJobs ? removeJob(item.job_id) : removeJob(item.id);
                !inSavedJobs && setJobSaved(false);
              }}
            />
          ) : (
            <img
              src={HeartIcon}
              alt="img"
              onClick={e => {
                e.stopPropagation();
                saveJob(item.id);
                setJobSaved(true);
              }}
            />
          )}
        </div>
      </div>
      <div className="job-list-item-body filter-heading">
        <div className="spider_name">
          {item.company_name && (
            <>
              <img src={breifcaseIcon} alt="img" className="job-img" />
              <span style={{ textTransform: "capitalize" }}>
                {item.company_name}
              </span>
            </>
          )}
        </div>
        {item.address ? (
          <div className="ml-10 spider_name ">
            <img
              src={locationIcon}
              alt="img"
              className="job-img"
              style={{ marginTop: "1px" }}
            />
            <span className="job_address">{item.address}</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="job-list-item-footer">
        {item.job_type ? (
          <Chip
            className={
              ((item.job_type === "Full-time" ||
                item.job_type === "Full-Time") &&
                "full-time") ||
              (item.job_type === "Contract" && "contract") ||
              (item.job_type === "Part-time" && "part-time") ||
              (item.job_type === "Internship" && "internship") ||
              (item.job_type === "Temporary" && "temporary") ||
              (item.job_type === "Freelance" && "freelance")
            }
            label={item.job_type}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default JobCard;
