import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { DateTime as dt } from "luxon";
import NoData from "../../components/NoData";
import DashboardboardBanner from "../../components/DashboardBanner";
import * as actions from "../../modules/actions";
import TrashIcon from "../../assets/images/trash.svg";
import "./dashboard.css";
class SavedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      count: null,
      next: null,
      previous: null,
      data: [],
      contentLoader: false,
    };
  }

  componentDidMount() {
    this.getAllSavedFilters();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      window.scrollTo(0, 0);
      this.getAllSavedFilters();
    }
  }

  getAllSavedFilters = () => {
    this.setState({ contentLoader: true });
    let { page } = this.state;
    actions.getAllSavedFilter(page, data => {
      if (data) {
        this.setState({ data: [...this.state.data, ...data.results], ...data });
      }
      this.setState({ contentLoader: false });
    });
  };

  handleRemoveFilter = id => {
    // this.setState({ contentLoader: true });
    let { data } = this.state;
    let removeIndex = data
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    // remove object
    data.splice(removeIndex, 1);

    this.setState({ data: data, count: this.state.count - 1 });
    actions.removeSavedFilter(id, cb => {
      if (cb) {
        this.props.getAllSavedFilters();
        this.props.setFindJobData({ getJobsCheck: false });
      }
      // this.setState({ contentLoader: false });
    });
  };

  handleShowMore = () => {
    this.setState({ page: this.state.page + 1 });
  };

  getProgress = () => {
    let { count, page } = this.state;
    let total_pages = Math.ceil(count / 20);
    let progress = (page / total_pages) * 100;

    return progress;
  };

  render() {
    let { contentLoader, data, count, next, page } = this.state;

    return (
      <div className="saved-filter">
        <DashboardboardBanner
          heading="Looking for a Job You Noticed Earlier"
          subHeading={`${count ? count : 0} Filtered Jobs`}
          content={
            "Check back here for any positions you previously searched for using our various filter functions. We want to make sure you don’t miss the perfect opportunity for you!"
          }
        />

        <div className="job-show">
          <Typography className="job-list-heading">
            {count ? (
              <>
                Showing {page ? 1 : page * 20 - 19} {"-"}
                {Math.ceil(count / 20) === page
                  ? count
                  : count <= 20
                  ? count
                  : page * 20}{" "}
                of {count} jobs
              </>
            ) : (
              ""
            )}
          </Typography>
        </div>
        <Grid
          container
          className={
            contentLoader ? "pe-none main-container" : " main-container"
          }
          spacing={2}
        >
          {!contentLoader ? (
            <>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <Grid
                    xs={12}
                    md={6}
                    item
                    key={index}
                    className="saved-filters"
                  >
                    <div
                      className="job-list-item"
                      onClick={() => {
                        this.props.setFindJobData({
                          fromFilter: false,
                          getJobsCheck: false,
                          changedState: false,
                          selectedFilter: {
                            label: item.filter_name,
                            value: item.id,
                          },
                          page: 1,
                        });
                        this.props.history.push(`/dashboard`);
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={7} md={8}>
                          <div className="filter-name mb-20">
                            {item.filter_name}
                          </div>
                          <div className="filter-date">
                            Created on{" "}
                            {dt.fromISO(item.created_on).toLocaleString()}
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          md={4}
                          className="remove-filter-container"
                          onClick={e => {
                            e.stopPropagation();
                            this.handleRemoveFilter(item.id);
                          }}
                        >
                          <img src={TrashIcon} alt="trash" />
                          <span className="delete-filter">Remove</span>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                ))
              ) : (
                <NoData msg="No Saved Filters Found" />
              )}
              {data.length > 0 && (
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  className="filter-footer"
                >
                  <Typography className="filter-heading">
                    {count ? (
                      <>
                        Showing {page ? 1 : page * 20 - 19} {"-"}
                        {Math.ceil(count / 20) === page
                          ? count
                          : count <= 20
                          ? count
                          : page * 20}{" "}
                        of {count} jobs
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={this.getProgress()}
                    className="progress-bar"
                  />
                  {next !== null && (
                    <Typography
                      className="filter-heading show-more cursor-pointer"
                      onClick={this.handleShowMore}
                    >
                      Show More
                    </Typography>
                  )}
                </Grid>
              )}
            </>
          ) : (
            <>
              <ContentLoader />
            </>
          )}
        </Grid>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFindJobData: payload => dispatch(actions.setFindJobData(payload)),
    getAllSavedFilters: () => dispatch(actions.getAllSavedFilters()),
  };
};

export default connect(undefined, mapDispatchToProps)(SavedFilter);

//Skeleton Content Loader
const ContentLoader = () => {
  return (
    <>
      {[...Array(10)].map((_, index) => (
        <Grid xs={12} md={6} item key={index}>
          <div className="loader job-list-item">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <div className="filter-name mb-20">
                  <Skeleton variant="rect" animation="wave" width="25vw" />
                </div>
                <div className="banner-subheading">
                  <Skeleton variant="rect" animation="wave" width="10vw" />
                </div>
              </Grid>
              <Grid item xs={4} className="remove-filter-container">
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width="7vw"
                  height={30}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      ))}
    </>
  );
};
