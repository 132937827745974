import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBar, Toolbar, Button, Drawer } from "@material-ui/core";
import Loader from "../../assets/images/loader.svg";
import MenuImg from "../../assets/images/menu.svg";

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: "",
      mystate: {
        top: false,
        left: false,
      },
    };
  }

  componentDidMount() {
    this.setState({
      currentPath: window.location.pathname.split("/")[1],
    });
  }

  toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    console.log("ancor", anchor, open);
    this.setState({ mystate: { ...this.state.mystate, [anchor]: open } });
  };

  render() {
    let { currentPath } = this.state;
    return (
      <header
        className={
          currentPath === "contact" ? "contact-main-header" : "main-header"
        }
      >
        <AppBar>
          <Toolbar>
            <div className="app-drawer">
              {["left"].map(anchor => (
                <React.Fragment key={anchor}>
                  <img
                    src={MenuImg}
                    alt="menu"
                    id={`drawerIcon-${anchor}`}
                    onClick={this.toggleDrawer(anchor, true)}
                    className={anchor + " menu-icon-dimensions cursor-pointer"}
                  />
                  <Drawer
                    open={this.state.mystate[anchor]}
                    onClose={this.toggleDrawer(anchor, false)}
                    className="back-color"
                  >
                    <div className="draw-flex">
                      <div style={{ flex: "1", marginTop: "20px" }}>
                        <Button
                          className="join-btn text-capitalized"
                          onClick={this.props.handleLogout}
                        >
                          {this.props.showLoader ? (
                            <img src={Loader} height="20" alt="img" />
                          ) : (
                            "Logout"
                          )}
                        </Button>
                      </div>
                    </div>
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
            <div className="logo">
              <img
                className="cursor-pointer"
                src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
                alt="logo"
                height="50px"
                width={"180px"}
              />
            </div>
            <div className="header-div">
              <Button
                className="join-btn text-capitalized"
                onClick={this.props.handleLogout}
              >
                {this.props.showLoader ? (
                  <img src={Loader} height="20" alt="img" />
                ) : (
                  "Logout"
                )}
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </header>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth,
});

export default withRouter(connect(mapStateToProps, undefined)(AppHeader));
