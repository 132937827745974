import React, { Fragment, useState } from "react";
import { Grid, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import AddCardForm from "./AddCardForm";
import CANCEL_BLACK from "../../assets/images/close.svg";
import stripe from "../../assets/images/stripe-logo.svg";


let STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
let stripe_key = STRIPE_KEY;
const stripePromise = loadStripe(stripe_key);

const CardDialog = props => {
  const classes = useStyles();
  const [openCardForm, setOpenCardForm] = useState(false);

  const handleCardFormOpen = val => {
    setOpenCardForm(val);
  };

  window.openAddCardAcc = handleCardFormOpen;

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openCardForm}
        onClose={() => {
          handleCardFormOpen(true);
          if (props.fromMain) {
            props.onCloseModal();
          }
        }}
      >
        <Grid container direction="column" className={classes.dialogContainer}>
          <div
            className={classes.title}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>ADD CARD DETAIL</span>
            <img
              src={CANCEL_BLACK}
              alt="Close"
              width="15px"
              height="15px"
              onClick={() => handleCardFormOpen(false)}
              className="cursor-pointer"
            />
          </div>

          <AddCardForm
            stripePromise={stripePromise}
            handleCardFormOpen={() => handleCardFormOpen(false)}
            getCards={props.getCards ? props.getCards : null}
            coupon={props.coupon}
            total={props.total}
            couponValue={props.couponValue}
            applyCoupon={props.applyCoupon}
            handleCouponChange={props.handleCouponChange}
            removeCoupon={props.removeCoupon}
            fromMain={props.fromMain}
            loader1={props.loader1}
            amountOff={props.amountOff}
          />
             <div className="stripe-logo" >
               <img src={stripe} style={{width:"140px"}} alt="img" />
             </div>
        </Grid>
     
      </Dialog>
    </Fragment>
  );
};
export default CardDialog;

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "#FFFFFF",
    padding: "40px",
    "@media(max-width:768px)": {
      padding: "40px 15px",
    },
    borderBottom: "1px solid #E0E0E0",
  },
  title: {
    fontFamily: "URW DIN",
    fontSize: "20px",
    lineHeight: "20px",
  },
  secondaryTitle: {
    fontFamily: "URW DIN-reg",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#828282",
    "@media(max-width:768px)": {
      marginBottom: "30px",
    },
  },
  addNewTitle: {
    fontFamily: "URW DIN",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "2px",
    color: "#BDBDBD",
  },
  newCardContainer: {
    backgroundColor: "#FFFFFF",
    padding: "20px 40px",
    "@media(max-width:768px)": {
      padding: "16px",
    },
  },
  cardConatiner: {
    // backgroundImage: "url(" + CARD_BG + ")",
    backgroundRepeat: "no-repeat",
    borderRadius: "8px",
    maxWidth: "268px",
    maxHeight: "168px",
    padding: "18px",
    marginTop: "20px",
    marginBottom: "10px",
  },
  dialogContainer: {
    padding: "50px",
    background: "#F3F3F3",
    "@media(max-width:768px)": {
      padding: "20px",
    },
  },
  cardNoText: {
    fontFamily: "URW DIN-reg",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "25px",
    color: "#FFFFFF",
    marginTop: "40px",
    "@media(max-width:400px)": {
      fontSize: "15px",
      lineHeight: "20px",
    },
  },
  expiryDate: {
    fontFamily: "URW DIN-reg",
    fontSize: "11px",
    lineHeight: "13px",
    color: "#FFFFFF",
  },
  expiryDateText: {
    fontFamily: "URW DIN-reg",
    fontSize: "8px",
    lineHeight: "12px",
    color: "#65D2D2",
    marginTop: "8px",
  },
  deleteText: {
    fontFamily: "URW DIN",
    fontSize: "14px",
    lineHeight: "16px",
    textDecoration: "underline",
    color: "#5E9C9C",
    cursor: "pointer",
  },
  deleteDialogHeading: {
    fontFamily: "URW DIN",
    fontSize: "18px",
    lineHeight: "18px",
    color: "#000000",
    marginBottom: "18px",
  },
  deleteDialogText: {
    fontFamily: "URW DIN-reg",
    fontSize: "24px",
    lineHeight: "39px",
    color: "#495A5A",
    marginBottom: "46px",
    "@media(max-width:768px)": {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
}));
