/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import Loader from "../../assets/images/loader.svg";
import Logo from "../../assets/images/logo.png";
import MailIcon from "../../assets/images/mail.svg";
import { validateEmail } from "../../utils/validations";
import { MESSAGE } from "../../constants/messages";
import * as Action from "../../modules/actions";
import "./forgot-password.css";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
      },
      formErrors: {
        email: null,
      },
      showLoader: false,
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;

    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(name, value);
      formErrorsObj = { ...formErrors, [name]: errorMsg };

      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
      case "email":
        if (!value) {
          errorMsg = MESSAGE.EMPTY_FIELD;
        } else {
          if (!validateEmail(value)) {
            errorMsg = MESSAGE.EMAIL_INVALID;
          }
        }
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.handleForgotPassword();
    }
  };

  handleForgotPassword = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);

    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      let payload = {
        email: form.email,
      };

      this.setState({
        showLoader: true,
      });
      Action.ForgotPassword(payload, cb => {
        if (cb === true) {
          this.setState({
            form: {
              email: "",
            },
            formErrors: {
              email: null,
            },
          });
        }
        this.setState({ showLoader: false });
      });
    }
  };

  render() {
    let { form, formErrors, showLoader } = this.state;

    return (
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div
          className="mt-90"
          onClick={() => {
            this.props.history.push("/home");
          }}
        >
          <img
            className="cursor-pointer"
            src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
            width={"180px"}
            alt="logo"
            height="50px"
          />
        </div>
        <div className="terms-heading mt-40">Forgot password</div>

        <div className="mt-28">
          <img src={MailIcon} alt="img" className="icon" />
          <input
            className={
              formErrors.email
                ? "signup-input-field error-border"
                : "signup-input-field"
            }
            type="text"
            placeholder="Email"
            autoComplete="off"
            name="email"
            value={form.email}
            onChange={this.handleChange}
            onKeyDown={this.handleEnterPress}
          />
          {formErrors.email && <div className="err">{formErrors.email}</div>}
        </div>

        <div>
          <Button
            value="Submit"
            className="forgot-pass-btn text-capitalized mt-26"
            onClick={() => {
              this.handleForgotPassword();
            }}
          >
            {showLoader ? (
              <img src={Loader} height="20" alt="img" />
            ) : (
              "Send reset password link"
            )}
          </Button>
        </div>

        <div className="disply-flex bottom-text-signin mt-30">
          <div>Back to</div>
          <div
            className="blue-color pl-6 cursor-pointer"
            onClick={() => {
              this.props.history.push("/login");
            }}
          >
            Log In
          </div>
        </div>
      </Grid>
    );
  }
}

export default ForgetPassword;
