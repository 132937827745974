import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Typography, Chip, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DateTime as dt } from "luxon";
import MemoMap from "./GoogleMap";
import * as actions from "../../modules/actions";
import NoData from "../../components/NoData";
import locationIcon from "../../assets/images/location.svg";
import breifcaseIcon from "../../assets/images/briefcase.svg";
import blueCaseIcon from "../../assets/images/briefcaseBlue.svg";
import HeartIcon from "../../assets/images/heart.svg";
import FullHeartIcon from "../../assets/images/heartFull.svg";
import ShareIcon from "../../assets/images/share.svg";
import Loader from "../../assets/images/loader.svg";
import LeftArrow from "../../assets/images/leftArrow.svg";
import "./job.css";

class Job extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      contentLoader: false,
      showLoader: false,
      jobsaved: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getJobDetail();
  }

  getJobDetail = () => {
    this.setState({
      contentLoader: true,
    });
    let id = this.props.match.params.id;
    if (id) {
      actions.getJobDetails(id, data => {
        if (data) {
          this.setState({
            job: data,
            jobsaved: data.is_job_saved,
          });
        }
        this.setState({
          contentLoader: false,
        });
      });
    }
  };

  saveJob = jobId => {
    let payload = {
      job_id: jobId,
    };
    this.setState({
      jobsaved: !this.state.jobsaved,
    });
    this.handleJob(jobId, true);

    actions.saveJob(payload, cb => {
      if (cb === true) {
      }
    });
  };

  removeSavedJob = jobId => {
    this.setState({
      jobsaved: !this.state.jobsaved,
    });
    this.handleJob(jobId, false);

    actions.removeSavedJob(jobId, cb => {
      if (cb === true) {
      }
    });
  };

  handleJob = (jobId, value) => {
    let { results } = this.props.dashboard;
    const jobIndex = results.findIndex(element => element.id === jobId);
    if (jobIndex !== -1) {
      results[jobIndex] = {
        ...results[jobIndex],
        is_job_saved: value,
      };
      this.props.setFindJobData({
        results,
      });
    }
  };

  handleCopy = () => {
    toast.success("Copied to Clipboard", {
      position: toast.POSITION.BOTTOM_RIGHT,
      closeButton: true,
      autoClose: true,
    });
  };

  getRelativeTime = value => {
    if (value) {
      let d = dt.fromISO(value).toLocal();
      let now = dt.local();
      let dif = d.diff(now, "days");
      return now.plus(dif).toRelative();
    }
    return "";
  };

  render() {
    let { job, showLoader, contentLoader, jobsaved } = this.state;
    return (
      <div className="job-details">
        {!contentLoader ? (
          Object.keys(job).length === 0 &&
          job.constructor === Object &&
          !contentLoader ? (
            <NoData msg="No Data Found" />
          ) : (
            <>
              <Grid
                container
                className="banner "
                spacing={0}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={7} className="banner-left">
                  <div className="d-flex">
                    <div className="backIcon cursor-pointer">
                      <img
                        src={LeftArrow}
                        alt="img"
                        className="job-img"
                        onClick={() => this.props.history.goBack()}
                      />
                    </div>
                    <div>
                      <Typography className="job-list-item-left">
                        {job.job_title}
                      </Typography>
                      <div className="job-list-item-body filter-heading">
                        {
                          <div className="spider_name">
                            <img
                              src={breifcaseIcon}
                              alt="img"
                              className="job-img"
                            />
                            <span style={{ textTransform: "capitalize" }}>
                              {job.company_name}
                            </span>
                          </div>
                        }
                        {job.address && (
                          <div className="ml-10 spider_name">
                            <img
                              src={locationIcon}
                              alt="img"
                              className="job-img"
                              style={{ marginBottom: "3px" }}
                            />
                            <span className="job_address">{job.address}</span>
                          </div>
                        )}
                      </div>
                      <div className="job-list-item-footer">
                        {job.job_type ? (
                          <Chip
                            className={
                              ((job.job_type === "Full-time" ||
                                job.job_type === "Full-Time") &&
                                "full-time") ||
                              (job.job_type === "Contract" && "contract") ||
                              (job.job_type === "Part-time" && "part-time") ||
                              (job.job_type === "Internship" && "internship") ||
                              (job.job_type === "Temporary" && "temporary") ||
                              (job.job_type === "Freelance" && "freelance")
                            }
                            label={job.job_type}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={5} className="banner-right">
                  <>
                    <div className="job-list-item-right cursor-pointer ">
                      {jobsaved ? (
                        <img
                          src={FullHeartIcon}
                          alt="img"
                          onClick={e => {
                            e.stopPropagation();
                            this.removeSavedJob(job.id);
                          }}
                        />
                      ) : (
                        <img
                          src={HeartIcon}
                          alt="img"
                          onClick={e => {
                            e.stopPropagation();
                            this.saveJob(job.id);
                          }}
                        />
                      )}
                    </div>
                    <div className="cursor-pointer copy">
                      <CopyToClipboard
                        text={job.page_url}
                        onCopy={() => this.handleCopy()}
                      >
                        <img
                          src={ShareIcon}
                          alt="img"
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        />
                      </CopyToClipboard>
                    </div>
                    <div className="btn-container">
                      <Link to={{ pathname: job.page_url }} target={"_blank"}>
                        <Button
                          value="Submit"
                          variant="contained"
                          className=" text-capitalized btn-primary"
                          onClick={this.props.applyFilter}
                          style={{ marginTop: "-10px" }}
                        >
                          {showLoader ? (
                            <img src={Loader} height="20" alt="img" />
                          ) : (
                            "Apply"
                          )}
                        </Button>
                      </Link>
                    </div>
                  </>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="job-container main-container"
              >
                { !job.html ?
              <Grid item xs={12} md={8}>
              <div >
                <br/>
                <strong>Job Description:-</strong>
                <p style={{wordSpacing:"6px",lineHeight:"22px"}}>  {job?.job_description}</p>
                <strong>Key Skills:-</strong>
                <div style={{wordSpacing:"1px",lineHeight:"22px"}}>
               {job?.key_skills?  <ul>
                {job?.key_skills?.split(",").map((item)=>
                  <li>{item}</li>
                )}
                </ul> : "NA"}
                </div>
                <strong>Experience Level:-</strong>
                <p>{job?.skills_and_experience_level ? job.skills_and_experience_level : "NA"}</p>
                <strong>Salary:-</strong>
                <p>{job?.salary ? job.salary:"NA"}</p>
               </div>
              </Grid>
                :
                <Grid item xs={12} md={8}>
                  {!isEmpty(job.html) && (
                    <div
                      className="job-html"
                      dangerouslySetInnerHTML={{
                        __html: job.html.replace(/\n/g, "<br />"),
                      }}
                    ></div>
                  )}
                </Grid>
                }
                <Grid item xs={12} md={4} className="job-overview">
                  <div>
                    <Typography className="filter-name">
                      Job Overview
                    </Typography>
                    <div className="job-overview-content mt-30">
                      <Typography className="job-overview-content-title">
                        <span>
                          <img src={blueCaseIcon} alt="img" />
                        </span>
                        <span style={{ marginLeft: "3%" }}>Date Posted:</span>
                      </Typography>

                      <Typography className="job-overview-content-body">
                        Posted {this.getRelativeTime(job.date_posted)}
                      </Typography>
                    </div>
                    <div className="job-overview-content mt-30">
                      <Typography className="job-overview-content-title">
                        <span>
                          <img src={blueCaseIcon} alt="img" />
                        </span>
                        <span style={{ marginLeft: "3%" }}>Location:</span>
                      </Typography>

                      <Typography className="job-overview-content-body">
                        {job.address}
                      </Typography>
                    </div>
                    <div className="job-overview-content mt-30">
                      <Typography className="job-overview-content-title">
                        <span>
                          <img src={blueCaseIcon} alt="img" />
                        </span>

                        <span style={{ marginLeft: "3%" }}>Job Title:</span>
                      </Typography>

                      <Typography className="job-overview-content-body">
                        {job.job_title}
                      </Typography>
                    </div>
                    {job.lat && (
                      <>
                        <Typography className="filter-name mt-30">
                          Job Location
                        </Typography>
                        <div className="map-content mt-30">
                          <MemoMap
                            position={{
                              lat: job.lat,
                              lng: job.lon,
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )
        ) : (
          <>
            <ContentLoader />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard }) => ({
  dashboard: dashboard,
});

const mapDispatchToProps = dispatch => {
  return {
    setFindJobData: payload => dispatch(actions.setFindJobData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Job);

//Skeleton Content Laoder
const ContentLoader = () => {
  return (
    <>
      <Grid
        container
        className="banner "
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={7} className="banner-left">
          <>
            <Typography className="job-list-item-left">
              <Skeleton variant="rect" animation="wave" width="60%" />
            </Typography>
            <div className="job-list-item-body filter-heading">
              <Skeleton variant="rect" animation="wave" width="50%" />
            </div>
            <div className="job-list-item-footer">
              <Skeleton variant="rect" animation="wave" width="20%" />
            </div>
          </>
        </Grid>

        <Grid item xs={5} className="banner-right">
          <Skeleton variant="rect" animation="wave" width="30%" height={50} />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="job-container main-container">
        <Grid item xs={12} md={8}>
          {Array(2)
            .fill(0)
            .map((_, index) => (
              <div key={index}>
                <Typography className="filter-name">
                  <Skeleton variant="rect" animation="wave" width="30%" />
                </Typography>
                <div className="job-description">
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width="95%"
                    height="20vh"
                  />
                </div>
              </div>
            ))}
          <Typography className="filter-name">
            <Skeleton variant="rect" animation="wave" width="40%" />
          </Typography>
          <div className="job-description">
            <Skeleton
              variant="rect"
              animation="wave"
              width="80%"
              height="3vh"
            />{" "}
            <br></br>
            <Skeleton
              variant="rect"
              animation="wave"
              width="50%"
              height="3vh"
            />{" "}
            <br></br>
            <Skeleton
              variant="rect"
              animation="wave"
              width="75%"
              height="3vh"
            />
          </div>
        </Grid>

        <Grid item xs={12} md={4} className="job-overview">
          <div>
            <Skeleton variant="rect" animation="wave" width="50%" />
            <div className="job-overview-content mt-30">
              <Typography className="job-overview-content-title">
                <span>
                  <Skeleton variant="rect" animation="wave" width="40%" />
                </span>
              </Typography>
              <br />
              <Skeleton variant="rect" animation="wave" width="70%" /> <br />
            </div>
            <div className="job-overview-content mt-30">
              <Typography className="job-overview-content-title">
                <span>
                  <Skeleton variant="rect" animation="wave" width="40%" />
                </span>
              </Typography>
              <br />
              <Skeleton variant="rect" animation="wave" width="70%" />
            </div>
            <div className="job-overview-content mt-30">
              <Typography className="job-overview-content-title">
                <span>
                  <Skeleton variant="rect" animation="wave" width="40%" />
                </span>
              </Typography>
              <br />
              <Skeleton variant="rect" animation="wave" width="70%" /> <br />
            </div>
            <Typography className="job-overview-content-title">
              <span>
                <Skeleton variant="rect" animation="wave" width="40%" />
              </span>
            </Typography>
            <br />
            <Skeleton
              variant="rect"
              animation="wave"
              width="95%"
              height="20vh"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};
