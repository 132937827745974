import * as actionTypes from "../actions/actionTypes";

const initialState = {
  tab: "1",
  logoutModal: false,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case actionTypes.SET_LOGOUT_MODAL:
      return {
        ...state,
        logoutModal: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;
