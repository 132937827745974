import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import AppFooter from "../../AppFooter";
import "./dashboard.css";

class DashboardLayout extends Component {
  render() {
    return (
      <>
        <DashboardHeader {...this.props} />
        {this.props.children}
        <div>
          <AppFooter {...this.props} />
        </div>
      </>
    );
  }
}

export default withRouter(DashboardLayout);
