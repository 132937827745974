import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuImg from "../../assets/images/menu.svg";
import Close from "../../assets/images/close.svg";
import { Link as RouterLink } from "react-router-dom";

import { Grid } from "@material-ui/core";
import "./appdrawer.css";

const useStyles = makeStyles({
  list: {
    width: "100%",
  },
  fullList: {
    width: "auto",
  },
});

export default function AppDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
  });

  const headersData = [
    {
      label: "Home",
      href: "/home",
    },
    {
      label: "How it works",
      href: "/how-it-works",
    },
    // {
    //   label: "About us",
    //   href: "/about-us",
    // },
    {
      label: "Contact ",
      href: "/contact",
    },
  ];
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const ClickListItem = id => {
    if (id.route !== "") {
      try {
        if (id.name === "CREWS") {
          sessionStorage.setItem("gs-tab", "crews");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const list = anchor => (
    <div className="side-drawer-pad">
      <div className="ham-menu-close">
        <img
          src={Close}
          alt="close"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
          className="app-drawer-close-btn cursor-pointer"
        />
      </div>

      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <List className="col1-mar">
              {(function () {
                return headersData;
              })().map(text => (
                <ListItem
                  button
                  key={text.label}
                  id={text.label}
                  to={text.href}
                  component={RouterLink}
                  className="col-padding"
                  onClick={e => {
                    ClickListItem(text.label);
                    toggleDrawer(anchor, false);
                  }}
                  onKeyDown={toggleDrawer(anchor, false)}
                >
                  <span
                    id={text.label}
                    className={
                      text.href?.split("/")[1] ===
                      window.location.pathname.split("/")[1]
                        ? "active-link col1-font"
                        : "col1-font"
                    }
                  >
                    {text.label}
                  </span>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return (
    <div>
      {["left"].map(anchor => (
        <React.Fragment key={anchor}>
          <img
            src={MenuImg}
            alt="menu"
            id={`drawerIcon-${anchor}`}
            onClick={toggleDrawer(anchor, true)}
            className={anchor + " menu-icon-dimensions cursor-pointer"}
          />
          <Drawer
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="back-color"
          >
            <div className="draw-flex">
              <div style={{ flex: "1" }}>{list(anchor)}</div>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
