import React, { Component, memo } from "react";
import { connect } from "react-redux";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import * as actions from "../../modules/actions";
import NoData from "../../components/NoData";
import JobCard from "../../components/JobCard";
import DashboardboardBanner from "../../components/DashboardBanner";
import "./dashboard.css";
class SavedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      count: null,
      next: null,
      previous: null,
      data: [],
      contentLoader: false,
    };
  }

  componentDidMount() {
    this.getSavedJobs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      window.scrollTo(0, 0);
      this.getSavedJobs();
    }
  }

  getSavedJobs = () => {
    let { page } = this.state;
    this.setState({ contentLoader: true });
    actions.getAllJobsOfUser(page, data => {
      if (data) {
        this.setState({
          data: [...this.state.data, ...data.results],
          count: data.count,
          next: data.next,
          previous: data.previous,
        });
      }
      this.setState({ contentLoader: false });
    });
  };

  removeSavedJob = jobId => {
    let { data } = this.state;
    let removeIndex = data
      .map(function (item) {
        return item.job_id;
      })
      .indexOf(jobId);
    // remove object
    data.splice(removeIndex, 1);
    this.handleJob(jobId, false);

    this.setState({ data: data, count: this.state.count - 1 });
    actions.removeSavedJob(jobId, cb => {
      if (cb === true) {
        // this.getSavedJobs();
      }
    });
  };

  handleJob = (jobId, value) => {
    let { results } = this.props.dashboard;
    const jobIndex = results.findIndex(element => element.id === jobId);
    if (jobIndex !== -1) {
      results[jobIndex] = {
        ...results[jobIndex],
        is_job_saved: value,
      };
      this.props.setFindJobData({
        results,
      });
    }
  };

  handleShowMore = () => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  getProgress = () => {
    let { count, page } = this.state;
    let total_pages = Math.ceil(count / 20);
    let progress = (page / total_pages) * 100;

    return progress;
  };

  render() {
    let { contentLoader, data, count, next, page } = this.state;
    return (
      <div className="saved-jobs">
        <DashboardboardBanner
          heading="Save Time with Visa Bridge"
          subHeading={`${count ? count : 0} Jobs Saved to Your Profile`}
          content={
            "As a visa-ready job seeker, Visa Bridge has the tools to save you time and ease the process. Check back here to review data on companies and positions you’re interested in and start the application process for the ones that peek your interest."
          }
        />
        <div className="job-show">
          <Typography className="job-list-heading">
            {count ? (
              <>
                Showing {page ? 1 : page * 20 - 19} {"-"}
                {Math.ceil(count / 20) === page
                  ? count
                  : count <= 20
                  ? count
                  : page * 20}{" "}
                of {count} jobs
              </>
            ) : (
              ""
            )}
          </Typography>
        </div>
        <Grid
          container
          className={
            contentLoader ? "pe-none main-container" : " main-container"
          }
          spacing={2}
        >
          {!contentLoader ? (
            <>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <Grid xs={12} md={6} item key={index}>
                    <div className="job-list-item cursor-pointer">
                      <JobCard
                        item={item}
                        removeJob={this.removeSavedJob}
                        history={this.props.history}
                        inSavedJobs={true}
                      />
                    </div>
                  </Grid>
                ))
              ) : (
                <NoData msg="No Saved Jobs" />
              )}

              {data.length !== 0 && (
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  className="filter-footer"
                >
                  <Typography className="filter-heading">
                    {count ? (
                      <>
                        Showing {page ? 1 : page * 20 - 19} {"-"}
                        {Math.ceil(count / 20) === page
                          ? count
                          : count <= 20
                          ? count
                          : page * 20}{" "}
                        of {count} jobs
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={this.getProgress()}
                    className="progress-bar"
                  />
                  {next !== null && (
                    <Typography
                      className="filter-heading show-more cursor-pointer"
                      onClick={this.handleShowMore}
                    >
                      Show More
                    </Typography>
                  )}
                </Grid>
              )}
            </>
          ) : (
            <>
              <ContentLoader />
            </>
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard }) => ({
  dashboard: dashboard,
});

const mapDispatchToProps = dispatch => {
  return {
    setFindJobData: payload => dispatch(actions.setFindJobData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedJobs);

//Skeleton Content Laoder
const ContentLoader = () => {
  return (
    <>
      {[...Array(10)].map((item, index) => (
        <Grid xs={12} md={6} item key={index}>
          <div className="job-list-item" key={index}>
            <div className="job-list-item-container">
              <div className="job-header">
                <div className="job-list-item-left">
                  <Skeleton variant="rect" animation="wave" width="30vw" />
                </div>
              </div>
              <div className="job-list-item-body filter-heading">
                <div>
                  <span>
                    <Skeleton variant="rect" width="10vw" />
                  </span>
                </div>
                <div className="ml-10">
                  <span>
                    <Skeleton variant="rect" width="10vw" />
                  </span>
                </div>
              </div>
              <div className="job-list-item-footer">
                <Skeleton variant="rect" width={50} />
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </>
  );
};
