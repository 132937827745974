/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import * as actions from "../../modules/actions";
import Loader from "../../assets/images/loader.svg";
import Logo from "../../assets/images/logo.png";
import MailIcon from "../../assets/images/mail.svg";
import LockIcon from "../../assets/images/lock.svg";
import EyeIcon from "../../assets/images/eye.svg";
import EyeOffIcon from "../../assets/images/eye-off.svg";
import { validateEmail, passwordValidation } from "../../utils/validations";
import { MESSAGE } from "../../constants/messages";
import "./sign-in.css";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: "",
      form: {
        email: "",
        password: "",
      },

      passwordVisibility: false,
      formErrors: {
        email: null,
        password: null,
      },
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;

    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(name, value);
      formErrorsObj = { ...formErrors, [name]: errorMsg };

      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
      case "email":
        if (!value) {
          errorMsg = MESSAGE.EMPTY_FIELD;
        } else {
          if (!validateEmail(value)) {
            errorMsg = MESSAGE.EMAIL_INVALID;
          }
        }
        break;
      case "password":
        if (!value) {
          errorMsg = MESSAGE.EMPTY_FIELD;
        } else {
          if (!passwordValidation(value)) {
            errorMsg = MESSAGE.PASSWORD_INVALID;
          }
        }
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.handleSignIn();
    }
  };

  handleSignIn = () => {
    const { form, formErrors } = this.state;
    if (this.state.selectedCountry === null) {
      this.setState({
        countryErrorMsg: "Field can’t be empty.",
      });
    } else {
      this.setState({
        countryErrorMsg: null,
      });
    }

    const errorObj = this.validateForm(form, formErrors, this.validateField);

    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      let payload = {
        email: form.email,
        password: form.password,
      };
      this.setState({ showLoader: true });

      this.props.SignIn(payload, val => {
        if (val === "login") {
          this.props.history.push("/dashboard");
        } else if (val === true) {
          this.props.history.push("/subscription-plan");
        }
        this.setState({ showLoader: false });
      });
    }
  };

  render() {
    let { form, formErrors, passwordVisibility, showLoader } = this.state;
    return (
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div
          className="mt-90"
          onClick={() => {
            this.props.history.push("/home");
          }}
        >
          <img
            className="cursor-pointer"
            src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
            alt="logo"
            height="50px"
            width={"180px"}
          />
        </div>
        <div className="terms-heading mt-40">LOG IN</div>

        <div onKeyDown={this.handleEnterPress}>
          <div className="mt-28">
            <img src={MailIcon} alt="img" className="icon" />
            <input
              className={
                formErrors.email
                  ? "signup-input-field error-border"
                  : "signup-input-field"
              }
              type="text"
              placeholder="Email"
              autoComplete="off"
              name="email"
              value={form.email}
              onChange={this.handleChange}
            />
            {formErrors.email && <div className="err">{formErrors.email}</div>}
          </div>
          <div className="mt-28">
            <img src={LockIcon} alt="img" className="icon" />
            <input
              className={
                formErrors.password
                  ? "signup-input-field error-border pr-50"
                  : "signup-input-field pr-50"
              }
              type={passwordVisibility ? "text" : "password"}
              placeholder="Password"
              autoComplete="off"
              name="password"
              value={form.password}
              onChange={this.handleChange}
            />
            {passwordVisibility ? (
              <img
                onClick={() => {
                  this.setState({
                    passwordVisibility: false,
                  });
                }}
                src={EyeOffIcon}
                alt="img"
                className="eye-icon"
              />
            ) : (
              <img
                onClick={() => {
                  this.setState({
                    passwordVisibility: true,
                  });
                }}
                src={EyeIcon}
                alt="img"
                className="eye-icon"
              />
            )}

            {formErrors.password && (
              <div className="err err-w-59">{formErrors.password}</div>
            )}
          </div>
        </div>
        <div
          onClick={() => {
            this.props.history.push("/forgot-password");
          }}
          className="mob-pad-24 bottom-text-signin blue-color text-left cursor-pointer mt-26 bottom-text-account"
        >
          Forgot password?
        </div>
        <div>
          <Button
            value="Submit"
            className="login-btn text-capitalized mt-26"
            onClick={this.handleSignIn}
          >
            {showLoader ? <img src={Loader} height="20" alt="img" /> : "Log In"}
          </Button>
        </div>
        <div className="horizontal-line-div mt-30">
          <span>OR</span>
        </div>
        <div className="disply-flex bottom-text-signin ">
          <div>Don’t have an Account?</div>
          <div
            className="blue-color pl-6 cursor-pointer"
            onClick={() => {
              this.props.history.push("/signup");
            }}
          >
            Sign Up
          </div>
        </div>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    SignIn: (payload, cb) => dispatch(actions.SignIn(payload, cb)),
  };
};

export default connect(undefined, mapDispatchToProps)(SignIn);
