import React from "react";
import { Grid, Container } from "@material-ui/core";

const DashboardboardBanner = props => {
  return (
    <>
      <Grid container className="banner " spacing={0} direction="column">
        <Grid item>
          <p className="banner-heading">
            {props.heading}
            <span onClick={props.toggleDrawer}>{props.children}</span>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p className="banner-subheading subheading-titlecase">{props.subHeading}</p>
            <Container>
              <p className="banner-subheading subheading-low">
                {" "}
                {props.content}
              </p>
            </Container>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardboardBanner;
