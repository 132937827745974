import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Button,
  Hidden,
  Popper,
  Grow,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import * as actions from "../../../modules/actions";
import DashboardDrawer from "./DashboardDrawer";
// import Logo from "../../../assets/images/logo.png";
import DefaultUser from "../../../assets/images/defaultUser.png";
import DropDownIcon from "../../../assets/images/dropdown.svg";
import CloseIcon from "../../../assets/images/x.svg";

const headersData = [
  {
    label: "Find Jobs",
    href: "/dashboard",
  },
  {
    label: "Saved Jobs",
    href: "/saved-jobs",
  },
  {
    label: "Saved Filters",
    href: "/saved-filters",
  },
];

const getMenuButtons = () => {
  return headersData.map(({ label, href }) => {
    return (
      <Button
        className={
          href?.split("/")[1] === window.location.pathname.split("/")[1]
            ? "active-link text-capitalized route-link"
            : "text-capitalized route-link"
        }
        {...{
          key: label,
          color: "inherit",
          to: `${href}`,
          component: RouterLink,
        }}
      >
        {label}
      </Button>
    );
  });
};

const DashboardHeader = props => {
  const [profile, setProfile] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  useEffect(() => {
    if (props.location.pathname === "/profile") {
      setProfile(true);
    } else {
      setProfile(false);
    }
  }, [props.location.pathname]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <header className={profile ? "dashboard profile" : "dashboard"}>
      <AppBar>
        <Toolbar>
          <div className="app-drawer">
            <DashboardDrawer />
          </div>
          <div
            className="logo"
            onClick={() => {
              props.history.push("/home");
            }}
          >
            <img
              className="cursor-pointer"
              src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
              alt="logo"
              height="50px"
              width={"180px"}
              style={{marginTop:"-9px"}}
            />
          </div>
          <div className="header-div">
            {getMenuButtons()}
            <span onClick={() => props.history.push("/profile")}>
              <img
                className="cursor-pointer default-user"
                src={props.imageUrl || DefaultUser}
                alt="logo"
              />
            </span>
          </div>
            <div className="">
          <Hidden lgUp>
            <span onClick={() => props.history.push("/profile")}
              className="tablet-view-profile">
              <img
                className="cursor-pointer default-user"
                src={props.imageUrl || DefaultUser}
                alt="logo"
              />
            </span>
            <span>
              {profile && (
                <>
                  <div
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                   className="dropdown-icon-div"
                  >
                    <img
                      className="cursor-pointer dropdown-icon"
                      src={DropDownIcon}
                      alt="logo"
                    />
                  </div>

                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{
                      zIndex: "999",
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",

                          marginTop: "10px",
                        }}
                        className="profile-dropdown"
                      >
                        <Paper
                          style={{
                            height: "max-content",
                            width: "162px",
                            marginLeft: "-140px",
                          }}
                        >
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={e => {
                                  handleClose(e);
                                  props.setTab("1");
                                }}
                                className={
                                  props.tab === "1"
                                    ? "active-link menu-list-item"
                                    : "menu-list-item"
                                }
                              >
                                View Profile
                              </MenuItem>
                              <MenuItem
                                onClick={e => {
                                  handleClose(e);
                                  props.setTab("2");
                                }}
                                className={
                                  props.tab === "2"
                                    ? "active-link menu-list-item"
                                    : "menu-list-item"
                                }
                              >
                                Payment Details
                              </MenuItem>
                              <MenuItem
                                onClick={e => {
                                  handleClose(e);
                                  props.setTab("3");
                                }}
                                className={
                                  props.tab === "3"
                                    ? "active-link menu-list-item"
                                    : "menu-list-item"
                                }
                              >
                                Change Password
                              </MenuItem>
                              <MenuItem
                                onClick={e => {
                                  handleClose(e);
                                  props.setLogoutModal(true);
                                }}
                                className="menu-list-item"
                              >
                                Logout
                              </MenuItem>
                              <MenuItem
                                onClick={handleClose}
                                className="menu-list-close"
                              >
                                <img
                                  className="cursor-pointer "
                                  src={CloseIcon}
                                  alt="logo"
                                />
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </span>
          </Hidden>
          </div>
        </Toolbar>
      </AppBar>
    </header>
  );
};

const mapStateToProps = ({ profile, auth }) => ({
  tab: profile.tab,
  imageUrl: auth.profile,
});

const mapDispatchToProps = dispatch => {
  return {
    setTab: value => dispatch(actions.setTab(value)),
    setLogoutModal: value => dispatch(actions.setLogoutModal(value)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardHeader));
