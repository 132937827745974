import React from "react";
import classnames from "classnames";

const iconLayouts = (color, height, width) => ({
  clear: (
    <svg
      version="1.1"
      id="mdi-filter-remove"
      width={width || "28"}
      height={height || "28"}
      viewBox="0 0 28 28"
      fill={color || "#3be8b0"}
    >
      <path d="M14.76,20.83L17.6,18L14.76,15.17L16.17,13.76L19,16.57L21.83,13.76L23.24,15.17L20.43,18L23.24,20.83L21.83,22.24L19,19.4L16.17,22.24L14.76,20.83M12,12V19.88C12.04,20.18 11.94,20.5 11.71,20.71C11.32,21.1 10.69,21.1 10.3,20.71L8.29,18.7C8.06,18.47 7.96,18.16 8,17.87V12H7.97L2.21,4.62C1.87,4.19 1.95,3.56 2.38,3.22C2.57,3.08 2.78,3 3,3V3H17V3C17.22,3 17.43,3.08 17.62,3.22C18.05,3.56 18.13,4.19 17.79,4.62L12.03,12H12Z" />
    </svg>
  ),
  edit: (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8553 0.0014432C13.5932 0.0039297 13.337 0.0913516 13.1366 0.284512L6.22458 6.94582L6.21208 6.95787C6.06539 7.09212 6.01699 7.21221 5.94335 7.37948C5.86724 7.55236 5.79732 7.76018 5.73711 7.99983C5.61668 8.47912 5.54418 9.06865 5.64962 9.65612C5.67544 9.81318 5.81214 9.94747 5.97459 9.97532C6.58416 10.0769 7.19588 10.0071 7.69323 9.89102C7.9419 9.83298 8.16381 9.75959 8.34318 9.68623C8.51671 9.61526 8.64137 9.57465 8.78065 9.43327C8.78535 9.42845 8.78842 9.42594 8.79315 9.42131L15.7052 2.76001C16.1633 2.31842 16.0563 1.59875 15.5989 1.15793C15.3323 0.900953 15.0656 0.643982 14.799 0.387005C14.5703 0.166592 14.2682 0.0275607 13.9678 0.00153732C13.9302 -0.00172019 13.8928 0.00115181 13.8553 0.00153732L13.8553 0.0014432ZM13.8553 0.772368C13.8774 0.771211 13.9003 0.769496 13.9241 0.772368C14.0191 0.783856 14.1262 0.828643 14.2303 0.928967C14.4998 1.18832 14.761 1.44644 15.0302 1.70591C15.2385 1.9066 15.2247 2.12682 15.1365 2.21184L14.599 2.7298L13.1616 1.34454L13.6991 0.826579C13.7322 0.794692 13.789 0.776034 13.8553 0.772368ZM12.5991 1.89262L14.0303 3.27187L8.21194 8.87916C8.20291 8.88109 8.12905 8.93531 8.0307 8.97554C7.89958 9.02914 7.71452 9.08945 7.50574 9.13814C7.17973 9.21424 6.79653 9.24101 6.42456 9.22247C6.40619 8.8658 6.43356 8.49292 6.51206 8.18051C6.56261 7.9793 6.62515 7.80697 6.68079 7.68061C6.72253 7.58581 6.7794 7.51031 6.78079 7.49992L12.5991 1.89262ZM1.79988 2.69968C0.809664 2.69968 0 3.47998 0 4.43427V13.3C0 14.2543 0.809664 15.0345 1.79988 15.0345H10.9993C11.9895 15.0345 12.7991 14.2543 12.7991 13.3V7.32526C12.8021 7.12161 12.6105 6.9343 12.3992 6.9343C12.1878 6.9343 11.9962 7.12161 11.9992 7.32526V13.3C11.9992 13.8405 11.5601 14.2636 10.9993 14.2636H1.79988C1.239 14.2636 0.799946 13.8405 0.799946 13.3V4.43427C0.799946 3.89374 1.239 3.47062 1.79988 3.47062H8.7994C9.01071 3.47349 9.20507 3.28882 9.20507 3.08515C9.20507 2.88149 9.01071 2.69681 8.7994 2.69968H1.79988Z"
        fill={color || "#3be8b0"}
      />
    </svg>
  ),
});

const DashboardIcons = ({
  onClick,
  className,
  style,
  type,
  color,
  height,
  width,
}) => {
  const icon = iconLayouts(color, height, width)[type];
  return (
    <span
      className={classnames(className)}
      onClick={onClick}
      style={{
        cursor: "pointer",
        ...style,
      }}
    >
      {icon}
    </span>
  );
};

export default DashboardIcons;
