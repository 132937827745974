/* eslint-disable array-callback-return */
import api from "../../services";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";

export const setContentLoader = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_CONTENT_LOADER,
      payload,
    });
  };
};

export const setFindJobData = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_FIND_JOBS,
      payload,
    });
  };
};

export const SetLoadingData = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.GET_JOBS_CHECK,
      payload,
    });
  };
};

export const getAllJobs = () => {
  return (dispatch, getState) => {
    dispatch(setContentLoader(true));
    let page = getState().dashboard.page;
    console.log("action called how many times");
    api
      .getAllJobsService(page)
      .then(response => {
        let data = response.data;

        if (response.status === 200) {
          dispatch(setFindJobData({ ...data, getJobsCheck: true }));
          dispatch(setContentLoader(false));
        }
      })
      .catch(error => {
        let err = error.response.data;
        console.log("Error: ", error.response);
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        dispatch(setContentLoader(false));
      });
  };
};

export const saveJob = (data, cb) => {
  api
    .saveJobService(data)
    .then(response => {
      let data = response.data;
      if (response.status === 200) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const removeSavedJob = (data, cb) => {
  api
    .removeSavedJobService(data)
    .then(response => {
      let data = response.data;
      if (response.status === 200) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const getAllSavedFilter = (page, cb) => {
  api
    .getSavedFilterService(page)
    .then(response => {
      let data = response.data;
      if (response.status === 200) {
        cb(data);
      }
    })
    .catch(error => {
      console.log("error", error);
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const saveFilter = (data, cb) => {
  api
    .saveFilterService(data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const applySavedFilter = () => {
  return (dispatch, getState) => {
    let id = getState().dashboard.selectedFilter.value;
    let page = getState().dashboard.page;
    api
      .applySavedFilterService(id, page)
      .then(response => {
        let data = response.data;
        if (data.status_code === 200) {
          let {
            filter_name,
            keyword,
            location,
            date_posted,
            min_salary,
            max_salary,
            included_jobs_without_salary_mentioned,
            job_type,
            radius,
          } = data.saved_filter;
          let appliedFilters = {
            filter_name,
            keyword,
            location,
            date_posted: date_posted || "All",
            salary: [min_salary || 11200, max_salary || 30000],
            included_jobs_without_salary_mentioned,
            job_type,
            radius: radius || 20,
          };
          dispatch(
            setFindJobData({
              ...appliedFilters,
            })
          );
          let { count, next, previous, results } = data.jobs;
          let filterJobs = {
            count,
            next,
            previous,
            results,
          };
          dispatch(
            setFindJobData({
              ...filterJobs,
            })
          );
          dispatch(
            setFindJobData({
              showLoader: false,
              contentLoader: false,
              mobileDrawer: false,
            })
          );
        }
      })
      .catch(error => {
        let err = error.response.data;
        console.log("Error: ", error.response);
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
      });
  };
};

const checkValueForBlank = (dashboard, values) => {
  let data = {};

  values.map((value, index) => {
    if (
      dashboard[value] !== "" &&
      dashboard[value] !== null &&
      dashboard[value] !== false &&
      JSON.stringify(dashboard[value]) !== JSON.stringify([11200, 30000])
    ) {
      data[value] = dashboard[value];
    }
  });
  return data;
};

export const applyFilter = (
  refresh = () => false,
  clearData = () => false,
  fromApplyFilter = () => false
) => {
  return (dispatch, getState) => {
    let { page, changedState, getJobsCheck, salary } = getState().dashboard;

    let payload;
    let values = [
      "keyword",
      "location",
      "date_posted",
      "included_jobs_without_salary_mentioned",
      "job_type",
      "radius",
    ];
    if (changedState || fromApplyFilter) {
      payload = checkValueForBlank(getState().dashboard, values);
      payload = {
        ...payload,
        min_salary: salary[0],
        max_salary: salary[1],
      };
    } else if (clearData || refresh) {
      payload = {};
    } else if (getJobsCheck === !changedState) {
      payload = {};
    } else {
      payload = checkValueForBlank(getState().dashboard, values);
      payload = {
        ...payload,
        min_salary: salary[0],
        max_salary: salary[1],
      };
    }
    api
      .applyFilterService(payload, page)
      .then(response => {
        let data = response.data;
        if (data.status_code === 200) {
          let { count, next, previous, results } = data?.jobs;
          let filterJobs = {
            count,
            next,
            previous,
            results,
          };
          if (data.jobs.error) {
            dispatch(
              setFindJobData({
                count: 0,
                next: null,
                previous: null,
                results: [],
              })
            );
          } else {
            dispatch(
              setFindJobData({
                ...filterJobs,
              })
            );
          }
        }

        dispatch(
          setFindJobData({
            showLoader: false,
            contentLoader: false,
            mobileDrawer: false,
            clearData: false,
            page: page,
          })
        );
      })
      .catch(error => {
        let err = error.response.data;
        console.log("Error: ", error.response);
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        dispatch(
          setFindJobData({
            showLoader: false,
            contentLoader: false,
            mobileDrawer: false,
            clearData: false,
          })
        );
      });
  };
};

export const removeSavedFilter = (data, cb) => {
  api
    .removeSavedFilterService(data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const getAllJobsOfUser = (page, cb) => {
  api
    .getUserSavedJobService(page)
    .then(response => {
      let data = response.data;
      if (response.status === 200) {
        cb(data);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const getAllSavedFilters = () => {
  return dispatch => {
    api
      .getAllSavedFilterService()
      .then(response => {
        let data = response.data;
        if (data.status_code === 200) {
          let res = data.data;
          if (res) {
            let tempData = [];
            res.map(filter => {
              tempData.push({
                label: filter.filter_name,
                value: filter.id,
              });
            });
            tempData = [
              {
                label: "Choose Filter",
                value: "none",
              },
              ...tempData,
            ];
            dispatch(setFindJobData({ filters: tempData }));
          }
        }
      })
      .catch(error => {
        let err = error.response.data;
        console.log("Error: ", error.response);
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
      });
  };
};

export const getJobDetails = (id, cb) => {
  api
    .getJobDetailService(id)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        cb(data.data);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const setDefaultJobs = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_DEFAULT_JOBS,
    });
  };
};

export const getJobCount = () => {
  return dispatch => {
    api
      .getJobCountService()
      .then(response => {
        let data = response.data;
        if (data.status_code === 200) {
          dispatch(
            setFindJobData({
              liveJobs: data.live_jobs,
              todayJobs: data.today_added,
            })
          );
        }
      })
      .catch(error => {
        let err = error.response.data;
        console.log("Error: ", error.response);
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
      });
  };
};
