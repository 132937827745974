import api from "../../services";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";

export const contactUsAction = val => {
  return {
    type: actionTypes.CONTACT_US_ACTION,
    val,
  };
};

export const contactUsCall = (data, updateLoader, dispatch) => {
  api
    .contactUs(data)
    .then(response => {
      let data = response.data;
      toast.success(data.message, {
        className: "onboarding-toast",
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      dispatch(contactUsAction(data));

      if (data.statusCode === 200) {
      }
    })
    .catch(error => {
      console.log("Error: ", error);
    });
};
