import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import ChracterImg from "../../../assets/images/student-in-university.jpg";

class RegisterationLayout extends Component {
  render() {
    return (
      <div className="sign-up-container">
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} className="center-element">
            <img src={ChracterImg} alt="img" className="chracter-img" />
          </Grid>
          {this.props.children}
        </Grid>
      </div>
    );
  }
}

export default RegisterationLayout;
