import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link as RouterLink } from "react-router-dom";
import AppDrawer from "../AppDrawer";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import "./appheader.css";

const headersData = [
  {
    label: "Home",
    href: "/home",
  },
  {
    label: "How it works",
    href: "/how-it-works",
  },
  // {
  //   label: "About us",
  //   href: "/about-us",
  // },
  {
    label: "Contact ",
    href: "/contact",
  },
];
const getMenuButtons = () => {
  return headersData.map(({ label, href }) => {
    return (
      <Button
        className={
          href?.split("/")[1] === window.location.pathname.split("/")[1]
            ? "active-link text-capitalized route-link"
            : "text-capitalized route-link"
        }
        {...{
          key: label,
          color: "inherit",
          to: href,
          component: RouterLink,
        }}
      >
        {label}
      </Button>
    );
  });
};
class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPath: "" };
  }

  componentDidMount() {
    this.setState({
      currentPath: window.location.pathname.split("/")[1],
    });
  }

  render() {
    let { currentPath } = this.state;
    return (
      <header
        className={
          currentPath === "contact" ? "contact-main-header" : "main-header"
        }
      >
        <AppBar>
          <Toolbar>
            <div className="app-drawer">
              <AppDrawer />
            </div>
            <div
              className="logo"
              onClick={() => {
                this.props.history.push("/home");
              }}
            >
              <img
                className="cursor-pointer"
                src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
                alt="logo"
                height="50px"
                width={"180px"}
              />
            </div>
            <div className="header-div">
              {getMenuButtons()}{" "}
              <Button
                className="join-btn text-capitalized"
                onClick={() =>
                  this.props.isAuth
                    ? this.props.history.push("/dashboard")
                    : this.props.history.push("/signup")
                }
              >
                {this.props.isAuth ? "Dashboard" : <span style={{marginTop:"-3px"}}>Join Now</span>}
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </header>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth,
});

export default withRouter(connect(mapStateToProps, undefined)(AppHeader));
