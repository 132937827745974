import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Button,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import Modal from "react-responsive-modal";
import { DateTime as dt } from "luxon";
import { isEmpty } from "lodash";
import classnames from "classnames";
import CardSlider from "../../components/CardSlider";
import CardDialog from "../../components/StripeCard/CardDialog";
import NoData from "../../components/NoData";
import * as actions from "../../modules/actions";
import { getCustomerData } from "../../utils/localStorage";
import downloadIcon from "../../assets/images/download.svg";
import Loader from "../../assets/images/loader.svg";
import LeftArrow from "../../assets/images/left-arrow.svg";
import RightArrow from "../../assets/images/right-arrow.svg";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#ecf4ff",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentLoader: false,
      email: this.props.email,
      cards: [],
      invoices: [],
      hoverState: false,
      hoveredCard: null,
      setOpen: "",

      cancelSubcriptionModal: false,
      showLoader: false,
      subscription_id: null,
      defaultCard: null,
      makeDefaultLoader: false,
      page: 1,
      cardLoader: false,
    };

    window.showCardDetails = this.showCardDetails;
  }

  showCardDetails = data => {
    this.setState({ showCard: data });
  };

  componentDidMount() {
    this.getCards();
    this.getInvoice();
    this.customerData();
  }

  getCards = () => {
    this.setState({ cardLoader: true });
    actions.getCustomerCards({ email: this.state.email }, data => {
      if (data) {
        this.setState({
          cards: data.data,
          defaultCard: data.defaultCard,
        });
      }
      this.setState({ cardLoader: false });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getInvoice();
    }
  }

  getInvoice = () => {
    this.setState({
      contentLoader: true,
    });
    actions.getInvoices(
      { email: this.state.email, page: this.state.page },
      data => {
        if (data) {
          this.setState({
            invoices: data,
          });
        }
        this.setState({
          contentLoader: false,
        });
      }
    );
  };

  onDeleteCard = (index, card) => {
    let { cards } = this.state;
    if (cards.length <= 1) {
      toast.success("Can't Remove All Cards", {
        position: toast.POSITION.TOP_RIGHT,
        closeButton: true,
        autoClose: true,
      });
    } else {
      if (this.state.defaultCard === card.id) {
        toast.success("Can't remove default card", {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });
      } else {
        cards.splice(index, 1);
        this.setState({
          cards,
        });

        let payload = {
          email: this.props.email,
          card_id: card.id,
        };
        actions.deleteCustomerCard(payload, data => {
          console.log("delete card", data);
        });
      }
    }
  };

  handleCancelSubscription = () => {
    this.setState({
      showLoader: true,
    });

    actions.cancelSubscription({ email: this.state.email }, data => {
      if (data) {
        this.props.getUserProfileOnDashboard(cb => {
          this.customerData();
        });
        this.setState({
          cancelSubcriptionModal: false,
        });
      }
      this.setState({
        showLoader: false,
      });
    });
  };

  makeDefault = cardId => {
    this.setState({
      makeDefaultLoader: true,
    });
    let payload = {
      email: this.props.email,
      card_id: cardId,
    };
    actions.setDefaultCard(payload, data => {
      this.setState({
        makeDefaultLoader: false,
      });
      this.getCards();
    });
  };

  openAddCard = () => {
    window.openAddCardAcc(true);
  };

  customerData = () => {
    this.setState({
      subscription_id: getCustomerData().subscription_id,
    });
  };

  render() {
    let {
      cards,
      invoices,
      contentLoader,
      showLoader,
      cancelSubcriptionModal,
      subscription_id,
      defaultCard,
      makeDefaultLoader,
      cardLoader,
      page,
    } = this.state;

    return (
      <>
        <CardDialog getCards={this.getCards} />

        {/* Cancel Subscription Modal */}
        <Modal
          open={cancelSubcriptionModal}
          onClose={() => this.setState({ cancelSubcriptionModal: false })}
          aria-labelledby="cancel-subscription"
          aria-describedby="cancel-subscription-modal"
          center
          classNames={{ modalContainer: "cancel-subscription" }}
        >
          <div className="coupon-modal-title">Cancel Subscription</div>
          <div className="mt-28">
            Are sure you want to cancel the subscription?{" "}
          </div>
          <div className="display-flex btn-box">
            <Button
              value="Submit"
              className="apply-btn text-capitalized mt-28"
              onClick={this.handleCancelSubscription}
            >
              {showLoader ? <img src={Loader} height="20" alt="img" /> : "Ok"}
            </Button>
            <Button
              value="Submit"
              className="cancel-btn text-capitalized mt-28"
              onClick={() => this.setState({ cancelSubcriptionModal: false })}
            >
              Close
            </Button>
          </div>
        </Modal>

        <div className="payment-details">
          <div
            className={classnames(
              subscription_id !== null && "payment-details-padding",
              "terms-heading1  mb-20"
            )}
          >
            Payment Details
          </div>

          <div>
            <div className="btn-container ml-auto">
              {subscription_id !== null && (
                <Button
                  value="cancel"
                  variant="contained"
                  className=" text-capitalized btn-primary mr-5"
                  onClick={() =>
                    this.setState({ cancelSubcriptionModal: true })
                  }
                >
                  Cancel Subscription
                </Button>
              )}
              <Button
                value="Submit"
                variant="contained"
                className=" text-capitalized btn-primary"
                onClick={this.openAddCard}
              >
                Add Card
              </Button>
            </div>
          </div>
        </div>

        <Grid container justifyContent="center" spacing={2}>
          {!contentLoader ? (
            <Grid item xs={12} md={7}>
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>S.No</StyledTableCell>
                      <StyledTableCell>Created</StyledTableCell>
                      <StyledTableCell align="center">Due</StyledTableCell>
                      <StyledTableCell align="center">Paid</StyledTableCell>
                      <StyledTableCell align="center">
                        Subscription End Date
                      </StyledTableCell>
                      <StyledTableCell align="center">Download</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isEmpty(invoices) &&
                      invoices?.results.map((invoice, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">
                            {page > 1 ? index + 1 + (page - 1) * 10 : index + 1}
                          </StyledTableCell>
                          <StyledTableCell>
                            {invoice.created
                              ? dt.fromSeconds(invoice.created).toLocaleString()
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {"$"}
                            {invoice.amount_due / 100}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {"$"}
                            {invoice.amount_paid / 100}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {invoice?.lines?.data[0]?.period?.end
                              ? dt
                                  .fromSeconds(
                                    invoice?.lines?.data[0]?.period?.end
                                  )
                                  .toLocaleString()
                              : ""}
                          </StyledTableCell>
                          <StyledTableCell
                            className="cursor-pointer"
                            align="center"
                          >
                            <Link
                              target="_blank"
                              to={{ pathname: invoice.invoice_pdf }}
                            >
                              <img
                                src={downloadIcon}
                                alt="download"
                                className=" h-20"
                              />
                            </Link>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {!isEmpty(invoices) && invoices.count > 10 && (
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  className="invoice-pagination"
                >
                  <Grid item>
                    <Button
                      className={classnames(
                        !invoices.previous ? "has-prev" : ""
                      )}
                      disabled={!invoices.previous}
                      onClick={() => this.setState({ page: page - 1 })}
                    >
                      <img src={LeftArrow} height="40" alt="img-left" />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classnames(!invoices.next ? "has-next" : "")}
                      disabled={!invoices.next}
                      onClick={() => this.setState({ page: page + 1 })}
                    >
                      <img src={RightArrow} height="40" alt="img-right" />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <ContentLaoder />
          )}
          {cardLoader ? (
            <CardContentLoader />
          ) : (
            <>
              <Grid item xs={12} md={5}>
                <div className="saved-cards">
                  <CardSlider
                    cards={cards}
                    name={this.props.name}
                    onDeleteCard={this.onDeleteCard}
                    defaultCard={defaultCard}
                    makeDefaultLoader={makeDefaultLoader}
                    makeDefault={this.makeDefault}
                  />
                </div>
                {cards.length === 0 && <NoData msg="No Saved Cards" />}
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  email: auth.email,
  name: auth.name,
});

const mapDispatchToProps = dispatch => {
  return {
    getUserProfileOnDashboard: cb =>
      dispatch(actions.getUserProfileOnDashboard(cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);

const ContentLaoder = () => {
  return (
    <>
      <Grid item xs={12} md={7}>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Created</StyledTableCell>
                <StyledTableCell align="center">Due</StyledTableCell>
                <StyledTableCell align="center">Paid</StyledTableCell>
                <StyledTableCell align="center">
                  Subscription End Date
                </StyledTableCell>
                <StyledTableCell>Download</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(8)].map((_, index) => (
                <StyledTableRow key={index}>
                  {[...Array(6)].map((_, i) => (
                    <StyledTableCell component="th" scope="row" key={i}>
                      <Skeleton variant="rect" height={10} />
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

const CardContentLoader = () => {
  return (
    <Grid item xs={12} md={5}>
      <div className="saved-cards" style={{ padding: "3% 5%" }}>
        {[...Array(2)].map((row, index) => (
          <React.Fragment key={index}>
            <Skeleton variant="rect" width="100%" height={150} />
            <br /> <br />
          </React.Fragment>
        ))}
      </div>
    </Grid>
  );
};
