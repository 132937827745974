/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Grid, Button, Typography, Container } from "@material-ui/core";
import * as ActionCreators from "../../modules/actions";
import { connect } from "react-redux";
import UserProfileIcon from "../../assets/images/user-name-icon.svg";
import MailIcon from "../../assets/images/mail.svg";
import MapPinIcon from "../../assets/images/map-pin.svg";
import PhoneIcon from "../../assets/images/phone.svg";
import Loader from "../../assets/images/loader.svg";
import Select from "react-select";
import { countries } from "../../utils/countries";
import { validateEmail } from "../../utils/validations";
import { MESSAGE } from "../../constants/messages";
import "./contact-us.css";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: "",
      selectedCountry: null,
      countryErrorMsg: null,
      showLoader: false,
      form: {
        name: "",
        email: "",
        message: "",
      },
      formErrors: {
        name: null,
        email: null,
        message: null,
      },
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;

    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(name, value);
      formErrorsObj = { ...formErrors, [name]: errorMsg };

      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "name":
        if (!value) errorMsg = MESSAGE.EMPTY_FIELD;
        break;
      case "email":
        if (!value) {
          errorMsg = MESSAGE.EMPTY_FIELD;
        } else {
          if (!validateEmail(value)) {
            errorMsg = MESSAGE.EMAIL_INVALID;
          }
        }

        break;

      case "message":
        if (!value) errorMsg = MESSAGE.EMPTY_FIELD;
        break;

      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.handleSubmit();
    }
  };
  handleSubmit = () => {
    const { form, formErrors } = this.state;
    if (this.state.selectedCountry === null) {
      this.setState({
        countryErrorMsg: "Field can’t be empty.",
      });
    } else {
      this.setState({
        countryErrorMsg: null,
      });
    }
    const errorObj = this.validateForm(form, formErrors, this.validateField);

    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      let payload = {
        name: form.name,
        email: form.email,
        country: this.state.selectedCountry.label,
        message: form.message,
      };
      this.setState({
        showLoader: true,
      });
      this.props.ContactUsCall(payload, value => {
        let form = {
          name: "",
          email: "",
          message: "",
        };
        this.setState({
          showLoader: false,
          form: form,
          selectedCountry: null,
        });
      });
    }
  };
  handleCountryChange = selectedOption => {
    this.setState({ selectedCountry: selectedOption });
    this.setState({
      countryErrorMsg: null,
    });
  };
  render() {
    let { selectedCountry, form, formErrors, countryErrorMsg, showLoader } =
      this.state;
    const customStyles = {
      menuList: (provided, state) => ({
        ...provided,

        maxHeight: "200px",
        fontFamily: "GothamBook",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "15px",
        cursor: "pointer",
      }),

      input: (provided, state) => ({
        ...provided,

        paddingLeft: "64px",
        fontFamily: "GothamBook",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "15px",
        cursor: "pointer",
      }),
    };
    return (
      <Container>
        <Grid container>
          <div className="contact-us-top-content">
            <Grid container>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <div className="find-job-content">
                  <Typography>Connect with Us</Typography>
                </div>
                <div className="find-job-sub-content">
                  Whether you have a question or just want to learn more, we're
                  here to help!
                </div>
                <div className="disply-flex mt-20 pl-mb-20">
                  <img src={MapPinIcon} className="phone-icon" alt="img" />
                  <div className="contact-us-phone">
                    600 Anton Blvd, Costa Mesa, CA 92626 – Plaza Tower Floor 21
                    Unit C
                  </div>
                </div>
                <div className="disply-flex mt-20 pl-mb-20">
                  <img src={PhoneIcon} className="phone-icon" alt="img" />
                  <div className="contact-us-phone">
                    <a className="mailto" href="tel:1-712-330-5564">
                      1-712-330-5564
                    </a>
                  </div>
                </div>
                <div className="disply-flex mt-20 pl-mb-20">
                  <img src={MailIcon} className="phone-icon" alt="img" />
                  <div className="contact-us-phone">
                    <a className="mailto" href="mailto:support@visabridge.co">
                      support@visabridge.co
                    </a>
                  </div>
                </div>
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="form-box mt-40"
              >
                <div>
                  <div className="contact-us-title">Get in Touch</div>
                </div>
                <div onKeyDown={this.handleEnterPress}>
                  <div className="mt-40">
                    <img src={UserProfileIcon} alt="img" className="icon" />
                    <input
                      className={
                        formErrors.name
                          ? "input-field error-border"
                          : "input-field"
                      }
                      type="text"
                      placeholder="Name"
                      autoComplete="off"
                      name="name"
                      value={form.name}
                      onChange={this.handleChange}
                    />
                    {formErrors.name && (
                      <div className="err">{formErrors.name}</div>
                    )}
                  </div>
                  <div className="mt-30">
                    <img src={MailIcon} alt="img" className="icon" />
                    <input
                      className={
                        formErrors.email
                          ? "input-field error-border"
                          : "input-field"
                      }
                      type="text"
                      name="email"
                      placeholder="Email"
                      autoComplete="off"
                      value={form.email}
                      onChange={this.handleChange}
                      onBlur={this.handleChange}
                    />
                    {formErrors.email && (
                      <div className="err">{formErrors.email}</div>
                    )}
                  </div>
                  <div className="mt-30">
                    <img src={MapPinIcon} alt="img" className="icon z-2" />

                    <Select
                      className={countryErrorMsg ? "error-border" : ""}
                      options={countries}
                      styles={customStyles}
                      value={selectedCountry}
                      placeholder="Country"
                      onChange={this.handleCountryChange}
                      classNamePrefix="select"
                    />
                    {countryErrorMsg && (
                      <div className="err">{countryErrorMsg}</div>
                    )}
                  </div>

                  <div className="mt-30">
                    <img src={MailIcon} alt="img" className="icon" />
                    <input
                      className={
                        formErrors.message
                          ? "input-field error-border"
                          : "input-field"
                      }
                      type="text"
                      placeholder="Message"
                      name="message"
                      autoComplete="off"
                      value={form.message}
                      onChange={this.handleChange}
                      onBlur={this.handleChange}
                    />
                  </div>
                  {formErrors.message && (
                    <div className="err">{formErrors.message}</div>
                  )}
                </div>
                <Button
                  value="Submit"
                  onClick={this.handleSubmit}
                  className="get-in-touch-btn text-capitalized mt-40"
                >
                  {showLoader ? (
                    <img src={Loader} height="20" alt="img" />
                  ) : (
                    "Contact us"
                  )}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    ContactUsCall: (payload, updateLoader) =>
      ActionCreators.contactUsCall(payload, dispatch, updateLoader),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
