/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Typography,
  Grid,
  Tab,
  Hidden,
  Button,
  TextField,
  Slider,
} from "@material-ui/core";
import { TabList, TabPanel, TabContext, Skeleton } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import Modal from "react-responsive-modal";
import Cropper from "react-easy-crop";
import ChangePassword from "./ChangePassword";
import PaymentDetails from "./PaymentDetails";
import * as actions from "../../modules/actions";
import { uploadFiletoS3 } from "../../utils/aws";
import { countries } from "../../utils/countries";
// import textData from "../../constants/text-data";
// import { MESSAGE } from "../../constants/messages";
import Loader from "../../assets/images/loader.svg";
import DefaultUser from "../../assets/images/defaultUser.png";
// import EditIcon from "../../assets/images/edit.svg";
import DashboardIcons from "../../components/icons/DashboardIcons";
import UserProfileIcon from "../../assets/images/user-name-icon.svg";
import MailIcon from "../../assets/images/mail.svg";
// import EarthIcon from "../../assets/images/earth.svg";
import MapPinIcon from "../../assets/images/map-pin.svg";
import AddIcon from "../../assets/images/add.svg";
import getCroppedImg, { dataURLtoFile } from "../../utils/cropImage";
import "./profile.css";
// import { isEmpty } from "lodash";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: "75%",
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})(props => <TabList {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#303030",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "16px",
    letterSpacing: "0.05em",
    marginRight: 0,
    minWidth: "130px",
  },
}))(props => <Tab disableRipple {...props} />);

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      contentLoader: false,
      showLoader: false,
      editMode: false,
      profile: null,

      name: "",
      nameError: false,
      nameErrorMsg: "",

      email: "",
      emailError: false,
      emailErrorMsg: "",

      selectedCountry: null,
      countryError: false,
      countryErrorMsg: "",

      // selectedVisa: null,
      // visaError: false,
      // visaErrorMsg: "",

      // language: [],
      // languageError: false,
      // languageErrorMsg: "",

      // designation: "",
      // designationError: false,
      // designationErrorMsg: "",

      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 3,
      rotation: 0,
      croppedAreaPixels: null,
      imageSelected: false,
      croppedImage: null,
      imageName: "",
    };
    this.imageRef = React.createRef();
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = () => {
    this.setState({ contentLoader: true });
    actions.getUserProfile(data => {
      if (data) {
        this.setState({
          id: data.id,
          name: data.name,
          email: data.email,
          profile: data.profile,
          // designation: data?.designation || "",
        });
        countries.some(country => {
          if (country.label === data.country) {
            this.setState({
              selectedCountry: country,
            });
            return true;
          }
        });
        // textData.visaList.some(visa => {
        //   if (visa.label === data.type_of_visa) {
        //     this.setState({
        //       selectedVisa: visa,
        //     });
        //     return true;
        //   }
        // });
        // let allLanguage = [];
        // textData.languageList.map(language => {
        //   data.languages_known.map(lang => {
        //     if (language.value === lang) {
        //       allLanguage.push(language);
        //     }
        //   });
        // });
        // this.setState({
        //   language: allLanguage,
        // });
      }
      this.setState({ contentLoader: false });
    });
  };

  handleEditChanges = () => {
    // if (this.state.designation !== "") {
    this.setState({
      editMode: false,
      contentLoader: true,
      showLoader: true,
      // designationError: false,
      // designationErrorMsg: "",
    });
    let { croppedImage, imageName, id, profile, imageSrc } = this.state;
    let payload = {
      name: this.state.name,
      email: this.state.email,
      country: this.state.selectedCountry.label,
      // type_of_visa: this.state.selectedVisa.value,
      // languages_known: this.state.language.map(res => res.value),
      // designation: this.state.designation,
    };

    if (imageSrc) {
      let file = dataURLtoFile(croppedImage, `${imageName}`);
      uploadFiletoS3(file, data => {
        if (data.location) {
          payload = {
            ...payload,
            profile: data.location,
          };
          actions.updateUserProfile(id, payload, cb => {
            if (cb) {
              if (profile) {
                let delPayload = {
                  url: profile,
                };
                actions.deleteImage(delPayload, cb => {
                  console.log("cb", cb);
                });
                this.getUserDetails();
              } else {
                this.getUserDetails();
              }
              this.props.changedImage(data.location);
              this.setState({ contentLoader: false, showLoader: false });
            }
            this.setState({ contentLoader: false, showLoader: false });
          });
        } else {
          this.setState({ contentLoader: false, showLoader: false });
        }
      });
    } else {
      payload = {
        ...payload,
        profile: profile,
      };
      actions.updateUserProfile(id, payload, cb => {
        if (cb) {
          this.getUserDetails();
        }
        this.setState({ contentLoader: false, showLoader: false });
      });
    }
    // } else {
    //   this.setState({
    //     designationError: true,
    //     designationErrorMsg: "Field can’t be empty.",
    //   });
    // }
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.handleEditChanges();
    }
  };

  handleLogout = () => {
    this.setState({ showLoader: true });
    this.props.SignOut(cb => {
      if (cb === true) {
        this.props.history.push("/home");
        
      }else if(cb === false){   //if token deleted directly from localstorage ==> this code work for that condition
        localStorage.clear();
        console.log("first",this.props.auth.isAuth)
        this.props.auth.isAuth=false
        this.props.history.push("/home");
      }
      this.props.setLogoutModal(false);
      this.setState({ showLoader: false });
    });
  };

  handleTabChange = (event, newValue) => {
    this.props.setTab(newValue);
  };

  handleChange = e => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCountryChange = selectedOption => {
    this.setState({ selectedCountry: selectedOption });
  };

  // handleVisaChange = selectedOption => {
  //   this.setState({ selectedVisa: selectedOption });
  // };

  // handleLanguageChange = selectedOption => {
  //   if (selectedOption.length < 4) {
  //     this.setState({ language: selectedOption });
  //   } else {
  //     toast.error(MESSAGE.LANGUAGE_LIMIT);
  //   }
  // };

  onSelectFile = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      let size = parseFloat(img.size / (1024 * 1024)).toFixed(2);

      if (size <= 2) {
        this.setState({
          imageSrc: URL.createObjectURL(img),
          imageModal: true,
          imageName: img.name,
        });
      } else {
        toast.success("Image should be less than 2Mb ", {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });
      }
    }
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = (e, zoom) => {
    this.setState({ zoom });
  };

  setRotation = (e, rotation) => {
    this.setState({ rotation });
  };

  handleCrop = async () => {
    this.setState({ imageModal: false, imageSelected: true });
    this.handleImageCancel();
    try {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        this.state.rotation
      );

      this.setState({ croppedImage });
    } catch (e) {
      console.error(e);
    }
  };

  handleImageCancel = () => {
    this.setState({
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 3,
      imageModal: false,
    });
  };

  render() {
    let {
      name,
      nameError,
      nameErrorMsg,
      email,
      emailError,
      emailErrorMsg,
      selectedCountry,
      countryError,
      countryErrorMsg,
      // selectedVisa,
      // visaError,
      // visaErrorMsg,
      showLoader,
      editMode,
      contentLoader,
      imageModal,
      zoom,
      crop,
      imageSrc,
      aspect,
      rotation,
      croppedImage,
      profile,
      // language,
      // languageError,
      // languageErrorMsg,
      // designation,
      // designationError,
      // designationErrorMsg,
    } = this.state;
    let { tab, logoutModal } = this.props;

    return (
      <div>
        {/* Image Upload and crop Modal */}
        <Modal
          open={imageModal}
          aria-labelledby="save-image"
          aria-describedby="save-image-modal"
          center
          classNames={{ modalContainer: "save-image" }}
          onClose={this.handleImageCancel}
        >
          <Grid
            container
            className="save-filter-modal"
            justifyContent="center"
            direction="column"
          >
            <Typography className="modal-heading">
              Profile Image Upload
            </Typography>
            <div className="p-2 mb-20 mt-20">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                onRotationChange={this.setRotation}
                cropShape="round"
              />
            </div>
            <div className="img-controls">
              <div>
                <Typography variant="overline">Zoom</Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={this.onZoomChange}
                />
              </div>
              <div>
                <Typography variant="overline">Rotation</Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={this.setRotation}
                />
              </div>
            </div>

            <div className="btn-container " style={{ paddingBottom: "30px" }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    value="Submit"
                    variant="contained"
                    className=" text-capitalized btn-primary"
                    onClick={this.handleCrop}
                  >
                    Crop
                  </Button>

                  <Button
                    value="cancel"
                    variant="outlined"
                    className=" text-capitalized btn-secondary"
                    onClick={this.handleImageCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Modal>

        {/* Logout Modal */}
        <Modal
          open={logoutModal}
          aria-labelledby="logout-modal"
          aria-describedby="logout-modal"
          center
          classNames={{ modalContainer: "logout-modal" }}
          onClose={() => this.props.setLogoutModal(false)}
        >
          <div className="coupon-modal-title">Logout Confirmation</div>
          <div className="mt-28">Are sure you want to logout? </div>
          <div className="display-flex btn-box">
            <Button
              value="Submit"
              className="apply-btn text-capitalized mt-28"
              onClick={this.handleLogout}
            >
              {showLoader ? (
                <img src={Loader} height="20" alt="img" />
              ) : (
                "Logout"
              )}
            </Button>
            <Button
              value="Submit"
              className="cancel-btn text-capitalized mt-28"
              onClick={() => this.props.setLogoutModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>

        <Grid container justifyContent="center">
          <div className="profile-tabs ">
            <TabContext value={tab}>
              <Hidden smDown>
                <StyledTabs
                  onChange={this.handleTabChange}
                  aria-label="profile styled tabs"
                >
                  <StyledTab label="Profile" value="1" />
                  <StyledTab label="Payment Details" value="2" />
                  <StyledTab label="Change Password" value="3" />
                  <Typography
                    className="logout-tab"
                    onClick={() => this.props.setLogoutModal(true)}
                  >
                    Log Out
                  </Typography>
                </StyledTabs>
              </Hidden>
              <Typography />
              <TabPanel value="1" className="profile">
                <div className="profile-tab-banner"></div>
                {!contentLoader ? (
                  <>
                    <div
                      className="profile-tab-content"
                      onKeyDown={this.handleEnterPress}
                    >
                      <Grid container justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            zIndex: 2,
                          }}
                        >
                          <div className="userprofileimg">
                            <img
                              className="cursor-pointer"
                              src={
                                croppedImage
                                  ? croppedImage
                                  : profile
                                  ? profile
                                  : DefaultUser
                              }
                              alt="logo"
                            />
                            {editMode && (
                              <>
                                <img
                                  className="cursor-pointer add-icon"
                                  src={AddIcon}
                                  alt="logo"
                                  onClick={() => {
                                    this.imageRef.current.click();
                                  }}
                                />
                                <input
                                  ref={this.imageRef}
                                  type="file"
                                  accept="image/*"
                                  onChange={this.onSelectFile}
                                  style={{ display: "none" }}
                                />
                              </>
                            )}
                          </div>
                          <div>
                            {!editMode && (
                              <Typography
                                className="edit-profile"
                                onClick={() =>
                                  this.setState({ editMode: true })
                                }
                              >
                                <span>
                                  {/* <img
                                    src={EditIcon}
                                    alt="img"
                                    className="job-img"
                                  /> */}
                                  <DashboardIcons type="edit" color="#6a67ce" />
                                </span>
                                <span
                                  style={{
                                    verticalAlign: "text-bottom",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Edit Profile
                                </span>
                              </Typography>
                            )}
                          </div>
                          <div
                            className={
                              editMode ? "profile-form" : "pe-none profile-form"
                            }
                          >
                            <div onKeyDown={this.handleEnterPress}>
                              <div className="mt-28">
                                {editMode ? (
                                  <TextField
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                    className="profile-inputs"
                                    error={nameError}
                                    helperText={nameErrorMsg}
                                    value={name}
                                    size="small"
                                    onChange={this.handleChange}
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={UserProfileIcon}
                                      alt="img"
                                      className="icon"
                                    />
                                    <input
                                      className={
                                        nameError
                                          ? "signup-input-field error-border"
                                          : "signup-input-field"
                                      }
                                      type="text"
                                      placeholder="Name"
                                      autoComplete="off"
                                      name="name"
                                      value={name}
                                      onChange={this.handleChange}
                                    />
                                  </>
                                )}
                              </div>
                              <div className="mt-28">
                                {editMode ? (
                                  <TextField
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    className="profile-inputs"
                                    error={emailError}
                                    helperText={emailErrorMsg}
                                    value={email}
                                    size="small"
                                    onChange={this.handleChange}
                                    disabled
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={MailIcon}
                                      alt="img"
                                      className="icon"
                                    />
                                    <input
                                      className={
                                        emailError
                                          ? "signup-input-field error-border"
                                          : "signup-input-field"
                                      }
                                      type="text"
                                      placeholder="Email"
                                      autoComplete="off"
                                      name="email"
                                      value={email}
                                      onChange={this.handleChange}
                                      disabled
                                    />
                                  </>
                                )}
                              </div>

                              <div className="mt-28 profile-country-div country-div">
                                <img
                                  src={MapPinIcon}
                                  alt="img"
                                  className="icon icon-2 z-2"
                                />

                                <Select
                                  className={countryError ? "error-border" : ""}
                                  options={countries}
                                  value={selectedCountry}
                                  placeholder="Country"
                                  onChange={this.handleCountryChange}
                                  classNamePrefix="select"
                                />
                                {countryErrorMsg && (
                                  <div className="err">{countryErrorMsg}</div>
                                )}
                              </div>
                              {/* 
                              <div className="mt-28">
                                <img
                                  src={EarthIcon}
                                  alt="img"
                                  className="icon"
                                />
                                <input
                                  className={
                                    designationError
                                      ? "signup-input-field error-border"
                                      : "signup-input-field"
                                  }
                                  type="text"
                                  placeholder="Designation"
                                  autoComplete="off"
                                  name="designation"
                                  value={designation}
                                  onChange={e =>
                                    this.setState({
                                      designation: e.target.value,
                                    })
                                  }
                                  maxLength={255}
                                />
                                {designationError && (
                                  <div className="err">
                                    {designationErrorMsg}
                                  </div>
                                )}
                              </div> */}

                              {/* <div className="mt-28 profile-country-div country-div">
                                <img
                                  src={EarthIcon}
                                  alt="img"
                                  className="icon icon-2 z-2"
                                />

                                <Select
                                  className={visaError ? "error-border" : ""}
                                  options={textData.visaList}
                                  value={selectedVisa}
                                  placeholder="Choose Type of Visa"
                                  onChange={this.handleVisaChange}
                                  classNamePrefix="select"
                                />
                                {visaError && (
                                  <div className="err">{visaErrorMsg}</div>
                                )}
                              </div> */}
                            </div>
                            {/* {editMode ? (
                              <div className="mt-28 profile-country-div country-div language-div">
                                <img
                                  src={EarthIcon}
                                  alt="img"
                                  className="icon icon-2 z-2"
                                />

                                <Select
                                  className={
                                    languageError ? "error-border" : ""
                                  }
                                  options={textData.languageList}
                                  value={language}
                                  placeholder="Choose Language"
                                  onChange={this.handleLanguageChange}
                                  classNamePrefix="select-language"
                                  isMulti
                                  isClearable
                                />
                                {languageError && (
                                  <div className="err">{languageErrorMsg}</div>
                                )}
                              </div>
                            ) : (
                              <Box className="profile-languages mt-20">
                                {!isEmpty(language) ? (
                                  <Typography style={{ textAlign: "left" }}>
                                    Languages :
                                  </Typography>
                                ) : (
                                  <Typography style={{ textAlign: "left" }}>
                                    Languages : N/A
                                  </Typography>
                                )}

                                <ul style={{ textAlign: "left" }}>
                                  {language.map((lang, index) => (
                                    <li key={index}>{lang.label} </li>
                                  ))}
                                </ul>
                              </Box>
                            )} */}
                          </div>
                          {editMode && (
                            <div className="btn-container mt-28">
                              <Button
                                value="Submit"
                                variant="contained"
                                className=" text-capitalized btn-primary"
                                onClick={this.handleEditChanges}
                              >
                                {showLoader ? (
                                  <img src={Loader} height="20" alt="img" />
                                ) : (
                                  "Save Change"
                                )}
                              </Button>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  <ContentLaoder />
                )}
              </TabPanel>
              <TabPanel value="2">
                <PaymentDetails />
              </TabPanel>
              <TabPanel value="3">
                <ChangePassword />
              </TabPanel>
            </TabContext>
          </div>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ profile,auth }) => ({
  tab: profile.tab,
  logoutModal: profile.logoutModal,
  auth:auth
});

const mapDispatchToProps = dispatch => {
  return {
    setTab: value => dispatch(actions.setTab(value)),
    setLogoutModal: value => dispatch(actions.setLogoutModal(value)),
    SignOut: cb => dispatch(actions.LogoutAuth(cb)),
    changedImage: url => dispatch(actions.changedImage(url)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));

//Content Skeleton Loader
const ContentLaoder = () => {
  return (
    <div className="profile-tab-content">
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          style={{
            zIndex: 2,
          }}
        >
          <div className="userprofileimg userprofileimg1">
            <Skeleton variant="circle" width={40} height={40} />
          </div>
          <div className="edit-pr">
            <Skeleton variant="rect" animation="wave" width="15%" />
          </div>

          <div className="mt-28 ">
            {Array(6)
              .fill(0)
              .map((_, i) => (
                <div key={i} className="edit-pr">
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width="60%"
                    height={25}
                  />
                  <br />
                </div>
              ))}

            <div className="edit-pr mt-28">
              <Skeleton
                variant="rect"
                animation="wave"
                width="23%"
                height={32}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
