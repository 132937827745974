import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "../utils/store";
class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <App />
          <ToastContainer hideProgressBar={true} newestOnTop={false} />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default Root;
