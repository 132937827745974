/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import CountUp from "react-countup";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import SliderCard from "../../components/SliderCard";
import AboutUsHead from "../../assets/images/handshake-front-view-job-interview-successful-pa-2022-01-28-11-24-14-utc.jpg";
import ProfileIcon from "../../assets/images/profile-icon.svg";
import SearchIcon from "../../assets/images/search-icon.svg";
import JobIcon from "../../assets/images/job-icon.svg";
import BottomImg1 from "../../assets/images/cheerful-girl-in-hijab-sitting-at-cafe-looking-fo-2022-02-02-03-59-02-utc.jpg";
import BottomImg2 from "../../assets/images/young-man-looking-for-job-in-internet-while-sittin-2022-01-12-17-06-55-utc.jpg";
import BottomImg3 from "../../assets/images/smiling-caucasian-female-hr-manager-handshake-hire-2021-09-02-07-54-30-utc.jpg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./how-it-works.css";

const reviewData = [
  {
    name: "Tarra Blough",
    review:
      "Searching for companies that had the capability of sponsoring my Visa was becoming very time consuming. After trying Visa Bridge for 2 months I had 4 interviews and 2 job offers. Visa Bridge did all my hard work for me. ",
  },
  {
    name: "Lars Christenson",
    review:
      "Seamless and time saving. Will use the Visa Bridge platform again when if I need another Visa sponsorship",
  },
  {
    name: "Clara Jungers ",
    review:
      "Was stressed about finding a company that would be able to sponsor my Visa. Turned to Visa Bridge and the platform. The platform had very similar functions to LinkedIn and Career Builder but all for Visa Jobs. I’ve recommended this to my friends and colleagues who will need help finding Visa Jobs",
  },
  {
    name: "Xia Chung",
    review:
      "I’ve recommended Visa Bridge to all my international college friends. 3 months on the site and was able to secure 4 job offers. I was able to make a decision on where I wanted to work, which most of us Visa sponsorship workers don’t get to do often",
  },
];

class HowItWorks extends Component {
  render() {
    return (
      <>
        <div className="how-it-works-top-content">
          <video
            id="background-video"
            autoPlay
            loop
            muted
            src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/video/People.mp4"
            type="video/mp4"
          ></video>
          <Container
            style={{
              zindex: 99,
              position: "inherit",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <div className="find-job-content">
                  <Typography>Our Technology</Typography>
                  <Typography>Makes It Easy!</Typography>
                </div>
                <div className="find-job-sub-content">
                Our Platform “Many Bridges” collects and correlates sponsor ready jobs, 
                saving time, 
                effort and energy on your job search
                </div>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Grid
                  container
                  style={{
                    height: "100%",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ position: "relative" }}
                  >
                    <img className="main-image" alt="box" src={AboutUsHead} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Grid container className="mb-90">
          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} className="mb-90">
            <div className="who-are-we-content text-center">How It Works</div>
            <div className="who-are-we-sub-content text-center">
              Using proprietary technology, mining relevant data streams and
              establishing key partnerships, Visa Bridge can more seamlessly
              connect visa-required workers with companies and in-demand
              employment opportunities in the United States.
            </div>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          <Grid item xs={1} sm={1} md={2} lg={2}></Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} className="text-center">
            <div className="prof-img-border">
              <img src={ProfileIcon} alt="img" />
            </div>
            <div className="register-icon-text mt-20 ">Active Candidates</div>
            <div className="number-align-center counter-number">
              <CountUp end={2871} duration={4} />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} className="text-center">
            <div className="job-img-border">
              <img src={JobIcon} alt="img" />
            </div>
            <div className="register-icon-text mt-20">Partner Universities</div>
            <div className="number-align-center counter-number">
              <CountUp end={8} duration={4} />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} className="text-center">
            <div className="search-img-border">
              <img src={SearchIcon} alt="img" />
            </div>
            <div className="register-icon-text mt-20">Partner Businesses</div>
            <div className="number-align-center counter-number">
              <CountUp end={33} duration={4} />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={2} lg={2}></Grid>
          <Grid container>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={5}
              lg={5}
              className="mt-130 how-it-works-bottom-dektop"
            >
              <div>
                <img
                  className="how-it-works-bottom-1"
                  alt="img"
                  src={BottomImg1}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} className="mt-130">
              <div className="sticky-note-img-content SAVVE-TIME">
                What our users are saying
              </div>
              <div className="sticky-note-img-sub-content mt-30 mob-padding">
                Job seekers will find reliable, accurate, and complete data on
                visa-sponsor companies to ease the stress of the unknown. In
                addition, we can guide and verify visa-ready workers to reduce
                delays in getting key in-demand skilled positions filled.
              </div>
              {/* <div className="disply-flex mt-30 mob-padding">
                <div>
                  <img
                    className="how-it-works-bottom"
                    alt="img"
                    src={BottomImg2}
                  />
                </div>
                <div>
                  <img
                    className="how-it-works-bottom"
                    alt="img"
                    src={BottomImg3}
                  />
                </div>
              </div> */}


              <Grid container className="testimonial-slider">
            <Grid item xs={1} sm={1} md={0} lg={0}></Grid>

            <Grid item xs={10} sm={10} md={12} lg={12} className="text-center">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {reviewData.map(res => (
                  <SwiperSlide>
                    <SliderCard name={res.name} review={res.review} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
            <Grid item xs={1} sm={12} md={0} lg={0}></Grid>
          </Grid> 









            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
          </Grid>

          {/* <Grid container className="testimonial-slider">
            <Grid item xs={1} sm={1} md={3} lg={3}></Grid>

            <Grid item xs={10} sm={10} md={6} lg={6} className="text-center">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {reviewData.map(res => (
                  <SwiperSlide>
                    <SliderCard name={res.name} review={res.review} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
            <Grid item xs={1} sm={12} md={3} lg={3}></Grid>
          </Grid> */}
        </Grid>
      </>
    );
  }
}

export default HowItWorks;
