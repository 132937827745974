import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isAuth: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_LOGOUT:
      return {
        isAuth: false,
      };
    case actionTypes.CHANGED_IMAGE:
      return {
        ...state,
        profile: action.payload,
      };
    case actionTypes.SET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscription_status: action.payload,
      };
    case actionTypes.SET_TRIAL_STATUS:
      return {
        ...state,
        trial_status: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
