import React, { useState } from "react";
import { Hidden } from "@material-ui/core";
import TrashIcon from "../../assets/images/trash.svg";
import VisaLogo from "../../assets/cards/visa-logo.png";
import MasterCardLogo from "../../assets/cards/mastercard-logo.png";
import DiscoverCardLogo from "../../assets/cards/discover-logo.png";
import AmericanExpressLogo from "../../assets/cards/americanexpress-logo.svg";
import DinerClubLogo from "../../assets/cards/diners_club-logo.png";
import JcbLogo from "../../assets/cards/JCB_logo.png";
import classnames from "classnames";

const imageUrls = [
  VisaLogo,
  MasterCardLogo,
  DiscoverCardLogo,
  AmericanExpressLogo,
  DinerClubLogo,
  JcbLogo,
];

function BankCards(props) {
  const [cardTypeUrl, setCardTypeUrl] = useState(VisaLogo);

  const handleType = type => {
    if (type === "visa") {
      setCardTypeUrl(imageUrls[0]);
    } else if (type === "mastercard") {
      setCardTypeUrl(imageUrls[1]);
    } else if (type === "discover") {
      setCardTypeUrl(imageUrls[2]);
    } else if (type === "amex") {
      setCardTypeUrl(imageUrls[3]);
    } else if (type === "diners") {
      setCardTypeUrl(imageUrls[4]);
    } else if (type === "jcb") {
      setCardTypeUrl(imageUrls[5]);
    }
  };

  React.useState(() => {
    handleType(props.cardType.toLowerCase());
  }, []);

  return (
    <div className="card-container">
      <div
        id="card"
        className={classnames(props.defaultCard && "defaultCard")}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        <div className="header">
          <div className="sticker"></div>
          <div>
            <img className="logo" src={cardTypeUrl} alt="Card logo" />
          </div>
        </div>
        <div className="body">
          <h2 id="creditCardNumber">{`#### #### #### ${props.last4}`}</h2>
        </div>
        <div className="footer">
          <div>
            <h5>Card Holder</h5>
            <h3 id="creditCardNumber">{props.userName}</h3>
          </div>
          <div>
            <h5>Expires</h5>
            <h3 id="creditCardNumber">
              {props.month} / {props.year}
            </h3>
          </div>
        </div>
        <div className="remove-card-container">
          {!props.defaultCard &&
            props.hoverState &&
            props.hoveredCard === props.index && (
              <div
                className="remove-card cursor-pointer"
                onClick={() => props.onDeleteCard(props.index)}
              >
                <img src={TrashIcon} alt="trash" />
              </div>
            )}
        </div>
      </div>
      <div className="text-center">
        <Hidden smUp>
          <>
            <div
              className="cursor-pointer remove-card-mobile"
              onClick={() => props.onDeleteCard(props.index)}
            >
              <img src={TrashIcon} alt="trash" />{" "}
              <span className="delspan">Delete</span>
            </div>
            <hr />
          </>
        </Hidden>
      </div>
    </div>
  );
}

export default BankCards;
