const REDUX_STATE = "state";
const ACCESS_TOKEN = "token";
const REFRESH_TOKEN = "refreshToken";
const EXPIRATION_DATE = "expirationDate";
const USER_DATA = "userData";
const COUPON = "coupon";
const CUSTOMER_DATA = "customerData";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(REDUX_STATE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(REDUX_STATE, serializedState);
  } catch (err) {
    console.log(err);
  }
};

export const saveLoginInfo = ({ token, refreshToken, customerData }) => {
  try {
    const serilizedCustomerData = JSON.stringify(customerData);
    if (token || refreshToken) {
      saveToken(token, refreshToken);
    }
    localStorage.setItem(CUSTOMER_DATA, serilizedCustomerData);
  } catch (err) {
    console.log(err);
  }
};

export const saveToken = (accessToken, refreshToken) => {
  try {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  } catch (err) {}
};

export const getToken = () => {
  try {
    let token = localStorage.getItem(ACCESS_TOKEN);
    return token ? token : false;
  } catch (err) {
    console.log(err);
  }
};

export const getRefreshToken = () => {
  try {
    let token = localStorage.getItem(REFRESH_TOKEN);
    return !token || token == null || token === "" ? "sdjnsc" : token;
  } catch (err) {
    console.log(err);
  }
};

export const getExpirationDate = () => {
  try {
    return localStorage.getItem(EXPIRATION_DATE);
  } catch (err) {
    console.log(err);
  }
};

export const getUserData = () => {
  try {
    let userData = localStorage.getItem(USER_DATA);
    return userData ? JSON.parse(userData) : false;
  } catch (err) {
    console.log(err);
  }
};

export const setCoupon = coupon => {
  try {
    return localStorage.setItem(COUPON, coupon);
  } catch (err) {
    console.log(err);
  }
};

export const getCoupon = () => {
  try {
    let coupon = localStorage.getItem(COUPON);
    return coupon ? coupon : "";
  } catch (err) {
    console.log(err);
  }
};

export const getCustomerData = () => {
  try {
    let customerData = localStorage.getItem(CUSTOMER_DATA);
    return customerData && String(customerData) !== "{}"
      ? JSON.parse(customerData)
      : false;
  } catch (err) {
    console.log(err);
  }
};
