import api from "../../services";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import { saveLoginInfo } from "../../utils/localStorage";
import { setLogoutAuth } from "./authActions";
export const getUserProfile = cb => {
  api
    .getUserProfile()
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        cb(data.data);
      }
    })
    .catch(error => {
      if(error.response.status>=400){// error
        localStorage.clear();// if error is related to token then
         window.location.reload();  // this condition work for that
            
      }
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const getUserProfileOnDashboard = cb => {
  return dispatch => {
    api
      .getUserProfile()
      .then(response => {
        let data = response.data;
        if (data.status_code === 200) {
          let { ...customer } = data.customer;
          saveLoginInfo({
            customerData: customer[0],
          });
          cb(data.data);
        }
      })
      .catch(error => {
        if(error.response.status>=400){// error
          localStorage.clear(); // if error is related to token then    
          dispatch(setLogoutAuth());   // this condition work for that
        }
        let err = error.response.data;
        console.log("Error: ", error.response);
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(false);
      });
  };
};

export const updateUserProfile = (id, data, cb) => {
  api
    .updateUserProfileService(id, data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });

        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const setTab = value => {
  return dispatch =>
    dispatch({
      type: actionTypes.SET_TAB,
      payload: value,
    });
};

export const changePassword = (id, data, cb) => {
  api
    .changePasswordService(id, data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });

        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const setLogoutModal = value => {
  return dispatch =>
    dispatch({
      type: actionTypes.SET_LOGOUT_MODAL,
      payload: value,
    });
};

export const deleteImage = (data, cb) => {
  api
    .deleteImageSevice(data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 204) {
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const getCustomerCards = (data, cb) => {
  api
    .getCustomerCardService(data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        cb({ data: data.list_of_cards.data, defaultCard: data.default_source });
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const getInvoices = (data, cb) => {
  api
    .getInvoiceService(data)
    .then(response => {
      let data = response.data;

      if (data.status_code === 200) {
        cb(data);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      // toast.error(err.message, {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      //   closeButton: true,
      //   autoClose: true,
      // });
      cb(false);
    });
};

export const deleteCustomerCard = (data, cb) => {
  api
    .removeSavedCardService(data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const cancelSubscription = (data, cb) => {
  api
    .cancelSubscriptionService(data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};

export const setDefaultCard = (data, cb) => {
  api
    .setDefaultCardService(data)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.TOP_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", error.response);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      cb(false);
    });
};
