import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import { Modal } from "react-responsive-modal";
import Loader from "../../assets/images/loader.svg";
import LockIcon from "../../assets/images/lock.svg";
import EyeIcon from "../../assets/images/eye.svg";
import EyeOffIcon from "../../assets/images/eye-off.svg";
// import Logo from "../../assets/images/logo.png";
import { passwordValidation } from "../../utils/validations";
import { MESSAGE } from "../../constants/messages";
import CheckCircleBlue from "../../assets/images/check-circle-blue.svg";
import * as Action from "../../modules/actions";
import "./reset-password.css";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      passwordVisibility: false,
      confPasswordVisibility: false,

      password: "",
      passswordError: false,
      passwordErrorString: "",
      confPassword: "",
      confPassswordError: false,
      confPasswordErrorString: "",

      email: "",
      otp: "",

      open: false,
    };
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params) {
      this.setState({
        email: params.get("email"),
        otp: params.get("otp"),
      });
    }
  }

  handleInputChange = (evt, inputName) => {
    this.setState({
      [inputName]: evt.target.value,
    });
    this.validateField(inputName, evt.target.value);
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.history.push("/login");
  };

  validatePassword = val => {
    let error = "";
    let value = val || this.state.password;
    if (value.trim() === "") {
      error = MESSAGE.EMPTY_FIELD;
      this.setState({
        passswordError: true,
        passwordErrorString: error,
      });
    } else if (!passwordValidation(value)) {
      error = MESSAGE.PASSWORD_INVALID;

      this.setState({
        passswordError: true,
        passwordErrorString: error,
      });
    } else {
      this.setState({
        passswordError: false,
        passwordErrorString: "",
      });
    }
    return error === "";
  };

  validateConfirmPassword = val => {
    let error = "";
    let value = val || this.state.confPassword;
    if (value.trim() === "") {
      error = MESSAGE.EMPTY_FIELD;
      this.setState({
        confPassswordError: true,
        confPasswordErrorString: error,
      });
    } else if (value !== this.state.password) {
      error = MESSAGE.PASSWORD_NOT_MATCH;
      this.setState({
        confPassswordError: true,
        confPasswordErrorString: error,
      });
    } else {
      this.setState({
        confPassswordError: false,
        confPasswordErrorString: "",
      });
    }
    return error === "";
  };

  validateField = (name, val) => {
    let isValid = false;
    if (name === "password") {
      isValid = this.validatePassword(val);
    } else if (name === "confPassword") {
      isValid = this.validateConfirmPassword(val);
    }
    return isValid;
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.handleResetPassword();
    }
  };

  handleResetPassword = () => {
    let isValid = true;
    let formFields = ["password", "confPassword"];
    formFields.forEach(field => {
      isValid = this.validateField(field) && isValid;
    });
    if (isValid) {
      this.setState({
        showLoader: true,
      });
      let { email, password, otp } = this.state;
      let payload = {
        email,
        password,
        otp,
      };
      Action.ResetPassword(payload, cb => {
        if (cb === true) {
          this.setState({ open: true });
        }
        this.setState({ showLoader: false });
      });
    }
  };

  render() {
    let {
      password,
      confPassword,
      passswordError,
      confPassswordError,
      passwordErrorString,
      confPasswordErrorString,
      passwordVisibility,
      confPasswordVisibility,
      showLoader,
      open,
    } = this.state;
    return (
      <>
        {/* Success Reset Password Modal */}

        <Modal
          open={open}
          aria-labelledby="reset-password"
          aria-describedby="reset-password-modal"
          center
          classNames={{ modalContainer: "reset-modal" }}
        >
          <p className="coupon-modal-title">Yayyy!</p>
          <p className="coupon-modal-sub-title">
            Your password has been succesfully reset
          </p>
          <Button
            value="Submit"
            className="apply-btn text-capitalized mt-28"
            onClick={this.handleClose}
          >
            Ok
          </Button>
        </Modal>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div
            className="mt-90"
            onClick={() => {
              this.props.history.push("/home");
            }}
          >
            <img
              className="cursor-pointer"
              src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
              width={"180px"}
              alt="logo"
              height="50px"
            />
          </div>
          <div className="terms-heading mt-40">Reset password</div>

          <div onKeyDown={this.handleEnterPress}>
            <div className="mt-28">
              <img src={LockIcon} alt="img" className="icon" />
              <input
                className={
                  passswordError
                    ? "signup-input-field error-border pr-50"
                    : "signup-input-field pr-50"
                }
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                autoComplete="off"
                name="password"
                value={password}
                onChange={e => this.handleInputChange(e, "password")}
              />
              {passwordVisibility ? (
                <img
                  onClick={() => {
                    this.setState({
                      passwordVisibility: false,
                    });
                  }}
                  src={EyeOffIcon}
                  alt="img"
                  className="eye-icon"
                />
              ) : (
                <img
                  onClick={() => {
                    this.setState({
                      passwordVisibility: true,
                    });
                  }}
                  src={EyeIcon}
                  alt="img"
                  className="eye-icon"
                />
              )}

              {passswordError && (
                <div className="err err-w-59">{passwordErrorString}</div>
              )}
            </div>
            <div className="mt-28">
              <img src={CheckCircleBlue} alt="img" className="icon" />
              <input
                className={
                  confPassswordError
                    ? "signup-input-field error-border pr-50"
                    : "signup-input-field pr-50"
                }
                type={confPasswordVisibility ? "text" : "password"}
                placeholder="Confirm Password"
                autoComplete="off"
                name="confPassword"
                value={confPassword}
                onChange={e => this.handleInputChange(e, "confPassword")}
              />
              {confPasswordVisibility ? (
                <img
                  onClick={() => {
                    this.setState({
                      confPasswordVisibility: false,
                    });
                  }}
                  src={EyeOffIcon}
                  alt="img"
                  className="eye-icon"
                />
              ) : (
                <img
                  onClick={() => {
                    this.setState({
                      confPasswordVisibility: true,
                    });
                  }}
                  src={EyeIcon}
                  alt="img"
                  className="eye-icon"
                />
              )}

              {confPassswordError && (
                <div className="err err-w-59">{confPasswordErrorString}</div>
              )}
            </div>
          </div>

          <div>
            <Button
              value="Submit"
              className="reset-pass-btn text-capitalized mt-26"
              onClick={this.handleResetPassword}
            >
              {showLoader ? (
                <img src={Loader} height="20" alt="img" />
              ) : (
                "Reset"
              )}
            </Button>
          </div>
        </Grid>
      </>
    );
  }
}

export default ResetPassword;
