/* eslint-disable array-callback-return */
import { REGEX } from "../constants";

export const validateEmail = email => {
  return REGEX.EMAIL.test(email);
};

export const passwordValidation = password => {
  return REGEX.PASSWORD.test(password);
};

export const validateConfirmPassword = (password, confirmPassword) => {
  return password === confirmPassword && confirmPassword.length > 0;
};

export const validateContact = contact => {
  return REGEX.CONTACT_NEW.test(contact);
};
export const validatePhone = contact => {
  return REGEX.PHONE.test(contact);
};

export const validateName = name => {
  return REGEX.NAME.test(name);
};

export const validateFax = fax => {
  return REGEX.FAX.test(fax);
};

export const validateCode = code => {
  return REGEX.USERNAME.test(code);
};

export const validateBlankSpace = name => {
  return REGEX.NOT_BLANK_SPACE.test(name);
};
export const validateYear = year => {
  return REGEX.ONLY_NO.test(year);
};

export const validateYearOfExperiance = year => {
  return REGEX.ONLY_ONE_DEC_ALLOW.test(year);
};

export const validateBusinessName = name => {
  return REGEX.BUSINESS_NAME_NEW.test(name);
};

export const validateAddress = address => {
  let onlyNoValid = REGEX.ONLY_TEXT.test(address);
  let blankSpacceValid = REGEX.NOT_BLANK_SPACE.test(address);
  // let onlyCharValid = REGEX.ONLY_CHAR.test(address);
  const result = onlyNoValid && blankSpacceValid;
  return result;
};

export const validateURL = url => {
  var regex = new RegExp(REGEX.CHECK_URL);
  if (url.match(regex)) {
    return true;
  } else {
    return false;
  }
};

export const containsChar = inputtxt => {
  var letters = /^[0-9a-zA-Z]+$/;
  if (inputtxt.value.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const BusinessInfoValidation = data => {
  let incomplete = false;

  data.map(ele => {
    if (ele.stepName === "Step business info") {
      incomplete = true;
    }
  });
  return incomplete;
};
