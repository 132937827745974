import React from "react";
import { Grid } from "@material-ui/core";

const NoData = props => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <div className="no-data-container">
        <p className="no-data-text">{props.msg}</p>
      </div>
    </Grid>
  );
};

export default NoData;
