import axios from "axios";
let apiVersion = process.env.REACT_APP_API_URL;

const exportedObject = {
  contactUs: function (data) {
    return axios.post(`${apiVersion}/user/contact-us`, data);
  },

  signUp: function (data) {
    return axios.post(`${apiVersion}/user/signup`, data);
  },

  signIn: function (data) {
    return axios.post(`${apiVersion}/user/login`, data);
  },

  forgotPassword: data => {
    return axios.post(`${apiVersion}/user/forgotpassword`, data);
  },

  VerifyCoupon: data => {
    return axios.post(`${apiVersion}/user/verify-coupon`, data);
  },

  CreateSubscription: data => {
    return axios.post(`${apiVersion}/user/create-subscription`, data);
  },

  VerifyUser: otp => {
    return axios.get(`${apiVersion}/user/verify?otp=${otp}`);
  },

  resetPassword: data => {
    return axios.put(`${apiVersion}/user/updatepassword`, data);
  },

  addCard: data => {
    return axios.post(`${apiVersion}/user/add-card`, data);
  },

  logoutService: () => {
    return axios.post(`${apiVersion}/user/logout`);
  },

  addCustomerService: data => {
    return axios.post(`${apiVersion}/user/add-customer`, data);
  },

  getAllJobsService: page => {
    return axios.get(`${apiVersion}/jobs/get-all-jobs?page=${page}`);
  },

  saveJobService: data => {
    return axios.post(`${apiVersion}/jobs/save-job`, data);
  },

  removeSavedJobService: id => {
    return axios.get(`${apiVersion}/jobs/remove-saved-jobs/${id}`);
  },

  getSavedFilterService: page => {
    return axios.get(`${apiVersion}/jobs/get-all-saved-filters?page=${page}`);
  },

  saveFilterService: data => {
    return axios.post(`${apiVersion}/jobs/save-filter`, data);
  },

  applySavedFilterService: (id, page) => {
    return axios.get(
      `${apiVersion}/jobs/apply-saved-filter/${id}?page=${page}`
    );
  },

  applyFilterService: (data, page) => {
    return axios.post(`${apiVersion}/jobs/apply-filter?page=${page}`, data);
  },

  removeSavedFilterService: id => {
    return axios.get(`${apiVersion}/jobs/remove-saved-filter/${id}`);
  },

  getUserSavedJobService: page => {
    return axios.get(`${apiVersion}/jobs/get-all-saved-jobs?page=${page}`);
  },

  getAllSavedFilterService: () => {
    return axios.get(`${apiVersion}/jobs/get-all-saved-filters?dropdown=true`);
  },

  getJobDetailService: id => {
    return axios.get(`${apiVersion}/jobs/get-one-job/${id}`);
  },

  getUserProfile: () => {
    return axios.get(`${apiVersion}/user/profile`);
  },

  updateUserProfileService: (id, data) => {
    return axios.put(`${apiVersion}/user/update-profile/${id}`, data);
  },

  changePasswordService: (id, data) => {
    return axios.put(`${apiVersion}/user/change-password/${id}`, data);
  },

  deleteImageSevice: data => {
    return axios.put(`${apiVersion}/user/s3-delete`, data);
  },

  getCustomerCardService: data => {
    return axios.post(`${apiVersion}/user/get-customer-card`, data);
  },

  getInvoiceService: data => {
    return axios.post(`${apiVersion}/user/get-invoice?page=${data.page}`, data);
  },

  removeSavedCardService: data => {
    return axios.post(`${apiVersion}/user/remove-card`, data);
  },

  cancelSubscriptionService: data => {
    return axios.post(`${apiVersion}/user/cancel-subscription`, data);
  },

  getJobCountService: () => {
    return axios.get(`${apiVersion}/jobs/get-today-added-jobs`);
  },
  setDefaultCardService: data => {
    return axios.post(`${apiVersion}/user/set-default-source`, data);
  },

  getPriceService: () => {
    return axios.get(`${apiVersion}/user/get-price`);
  },
  activateTrialService: () => {
    return axios.get(`${apiVersion}/user/activate-trial`);
  },
};

export default exportedObject;
