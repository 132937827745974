/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Hidden,
  Drawer,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { Skeleton, Pagination } from "@material-ui/lab";
import { Modal } from "react-responsive-modal";
import * as actions from "../../modules/actions";
import JobCard from "../../components/JobCard";
import NoData from "../../components/NoData";
import FilterBar from "../../components/FilterBar/FilterBar";
import DashboardboardBanner from "../../components/DashboardBanner";
import SettingIcon from "../../assets/images/setting.svg";
import Loader from "../../assets/images/loader.svg";
import "./dashboard.css";

class FindJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addFilterLoader: false,
      filterModal: false,
      filter_name: "",
      filterNameError: false,
      filterNameErrorString: "",

      jobTypes: [
        {
          label: "Seasonal",
          value: "Seasonal",
        },
        {
          label: "Full Time",
          value: "Full-time",
        },
        {
          label: "Part Time",
          value: "Part-time",
        },
        {
          label: "Internship",
          value: "Internship",
        },
        {
          label: "Temporary",
          value: "Temporary",
        },
        {
          label: "Contract",
          value: "contract",
        },
      ],

      datePosted: [
        {
          label: "All",
          name: "All",
        },
        {
          label: "Last hour",
          name: "Last hour",
        },
        {
          label: "Last 24 hours",
          name: "Last 24 hours",
        },
        {
          label: "Last 7 days",
          name: "Last 7 days",
        },
        {
          label: "Last 14 days",
          name: "Last 14 days",
        },
        {
          label: "Last 30 days",
          name: "Last 30 days",
        },
      ],
    };
  }

  componentDidMount() {
    let { selectedFilter, getJobsCheck, fromFilter, liveJobs, todayJobs } =
      this.props.dashboard;
    if (!liveJobs || !todayJobs) {
      this.props.getJobCount();
    }

    if (!getJobsCheck) {
      if (selectedFilter) {
        if (selectedFilter.value === "none") {
          this.props.getAllSavedFilters();
          this.applyFilter({ refresh: true, selectedFilter });
        } else {
          this.applySavedFilterWithId(selectedFilter);
        }
      } else {
        this.props.getAllSavedFilters();
        this.applyFilter({ refresh: true, selectedFilter: null });
      }
    } else if (!fromFilter && getJobsCheck) {
      if (selectedFilter) {
        if (selectedFilter.value === "none") {
          this.applyFilter({ selectedFilter });
        } else {
          this.applySavedFilterWithId(selectedFilter);
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setFindJobData({ getJobsCheck: true, fromFilter: true });
  }

  applySavedFilterWithId = selectedFilter => {
    let filterId = selectedFilter.value;
    this.props.dashboard.filters.map(filter => {
      if (filter.value === Number(filterId)) {
        this.props.setFindJobData({ selectedFilter: filter });
      }
    });

    this.applyFilter({ selectedFilter });
  };

  saveJob = jobId => {
    let payload = {
      job_id: jobId,
    };

    this.handleJob(jobId, true);
    actions.saveJob(payload, cb => {});
  };

  removeSavedJob = jobId => {
    this.handleJob(jobId, false);

    actions.removeSavedJob(jobId, cb => {});
  };

  saveFilter = () => {
    let {
      keyword,
      location,
      date_posted,
      salary,
      included_jobs_without_salary_mentioned,
      job_type,
      radius,
    } = this.props.dashboard;
    let { filter_name } = this.state;
    let payload = {
      filter_name,
      keyword,
      location,
      date_posted,
      min_salary: salary[0],
      max_salary: salary[1],
      included_jobs_without_salary_mentioned,
      job_type,
      radius,
    };
    let error = "";
    if (filter_name === "") {
      error = "This field is required";
      this.setState({ filterNameError: true, filterNameErrorString: error });
    } else {
      this.setState({ addFilterLoader: true });
      actions.saveFilter(payload, cb => {
        if (cb === true) {
          this.props.getAllSavedFilters();
        }
        this.setState({
          filterModal: false,
        });
      });
    }
  };

  applyFilter = ({
    refresh = false,
    clearData = false,
    fromApplyFilter = false,
    fromPage = false,
    selectedFilter,
  }) => {
    window.scrollTo(0, 0);
    let { changedState } = this.props.dashboard;
    this.props.setFindJobData({ showLoader: true, contentLoader: true });
    if (fromPage) {
      this.props.applyFilter(false, false, false);
    } else if (fromApplyFilter) {
      this.props.applyFilter(refresh, clearData, fromApplyFilter);
    } else if (clearData || refresh) {
      this.props.applyFilter({ refresh, clearData });
    } else if (!changedState) {
      if (selectedFilter && selectedFilter.value !== "none") {
        this.props.applySavedFilter();
      } else if (selectedFilter && selectedFilter.value === "none") {
        this.props.applyFilter(refresh, clearData, fromApplyFilter);
        this.handleCancel();
      } else {
        this.props.applyFilter(refresh, clearData, fromApplyFilter);
      }
    } else {
      this.props.applyFilter(refresh, clearData, fromApplyFilter);
    }
  };

  handleJob = (jobId, value) => {
    let { results } = this.props.dashboard;
    const jobIndex = results.findIndex(element => element.id === jobId);
    if (jobIndex !== -1) {
      results[jobIndex] = {
        ...results[jobIndex],
        is_job_saved: value,
      };
      this.props.setFindJobData({
        results,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSetData = e => {
    const { name, value } = e.target;
    this.props.setFindJobData({ [name]: value });
    if (value !== "") {
      this.props.setFindJobData({
        changedState: true,
      });
    }
  };

  handleClear = () => {
    this.props.setFindJobData({
      page: 1,
      keyword: "",
      location: "",
      radius: 20,
      job_type: "",
      salary: [11200, 30000],
      included_jobs_without_salary_mentioned: true,
      date_posted: "All",
      changedState: false,
      selectedFilter: null,
      clearData: true,
    });
    this.props.history.push("/dashboard");
    this.applyFilter({ refresh: false, clearData: true });
  };

  handleCancel = () => {
    this.props.setFindJobData({
      filter_name: "",
      filterNameError: false,
      filterNameErrorString: "",
      keyword: "",
      location: "",
      radius: 20,
      job_type: "",
      salary: [11200, 30000],
      included_jobs_without_salary_mentioned: true,
      date_posted: "All",
      changedState: false,
    });
  };

  handleFilterChange = selectedFilter => {
    this.props.setFindJobData({
      page: 1,
      changedState: false,
      mobileDrawer: false,
    });

    if (selectedFilter.value === "none") {
      if (this.props.dashboard.selectedFilter.value !== "none") {
        this.props.setFindJobData({
          selectedFilter,
        });
        this.handleCancel();
        this.applyFilter({ refresh: false, clearData: true });
      }
    } else {
      this.applySavedFilterWithId(selectedFilter);
    }
  };

  handleRangeValue = (e, newValue) => {
    this.props.setFindJobData({
      radius: newValue,
      changedState: true,
    });
  };

  handleSalaryValue = (e, newValue) => {
    this.props.setFindJobData({
      salary: newValue,
      changedState: true,
    });
  };

  handleJobType = e => {
    if (e.target.checked) {
      this.props.setFindJobData({
        job_type: e.target.name,
        changedState: true,
      });
    } else {
      this.props.setFindJobData({
        job_type: "",
        changedState: true,
      });
    }
  };

  handleSalaryChecked = e => {
    this.props.setFindJobData({
      included_jobs_without_salary_mentioned: e.target.checked,
      changedState: true,
    });
  };

  toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.props.setFindJobData({ mobileDrawer: open });
  };

  handleSaveFilterModal = () => {
    this.setState({
      filterModal: !this.state.filterModal,
      addFilterLoader: false,
      filter_name: "",
      filterNameError: false,
      filterNameErrorString: "",
    });
    this.props.setFindJobData({ mobileDrawer: false });
  };

  render() {
    const {
      filters,
      selectedFilter,
      keyword,
      location,
      radius,
      date_posted,
      salary,
      showLoader,
      included_jobs_without_salary_mentioned,
      mobileDrawer,
      results,
      contentLoader,
      page,
      count,
      job_type,
      changedState,
      liveJobs,
      todayJobs,
    } = this.props.dashboard;

    const {
      filterModal,
      filter_name,
      filterNameError,
      filterNameErrorString,
      addFilterLoader,
      jobTypes,
      datePosted,
    } = this.state;

    return (
      <>
        {/* Save Filter confirm Popup  */}
        <Modal
          open={filterModal}
          aria-labelledby="save-filter"
          aria-describedby="save-filter-modal"
          center
          classNames={{ modalContainer: "save-filter" }}
        >
          <Grid
            container
            className="save-filter-modal"
            justifyContent="center"
            direction="column"
          >
            <Typography className="modal-heading">Save Filter</Typography>
            <Typography className="modal-sub-heading mt-0 ">
              Add a name for the filter
            </Typography>
            <div className="filter-input-wrapper mb-20 mt-20">
              <TextField
                name="filter_name"
                id="outlined-basic"
                label="Filter Name"
                variant="outlined"
                error={filterNameError}
                helperText={filterNameErrorString}
                value={filter_name}
                size="small"
                onChange={this.handleChange}
              />
            </div>

            <div className="btn-container " style={{ paddingBottom: "30px" }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    value="Submit"
                    variant="contained"
                    className=" text-capitalized btn-primary"
                    onClick={this.saveFilter}
                  >
                    {addFilterLoader ? (
                      <img src={Loader} height="20" alt="img" />
                    ) : (
                      "Save"
                    )}
                  </Button>

                  <Button
                    value="cancel"
                    variant="outlined"
                    className=" text-capitalized btn-secondary"
                    onClick={this.handleSaveFilterModal}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Modal>

        <DashboardboardBanner
          toggleDrawer={this.toggleDrawer}
          heading="Many Bridges Portal"
          subHeading={`${liveJobs ? liveJobs : 0} Jobs live- ${
            todayJobs ? todayJobs : 0
          } jobs added today.`}
          content="Your dream job below."
        >
          <Hidden mdUp > 
          {/* this part will hidden from all breakpoint which is ahead of md */}
        
            {/* Mobile Drawer */}
            <div>
              {["right"].map(anchor => (
                <React.Fragment key={anchor}>
                  <img
                    src={SettingIcon}
                    alt="menu"
                    id={`drawerIcon-${anchor}`}
                    onClick={this.toggleDrawer(anchor, true)}
                    className={anchor + " menu-icon-dimensions cursor-pointer"}
                  />

                  <Drawer
                    open={mobileDrawer}
                    anchor={anchor}
                    onClose={this.toggleDrawer(anchor, false)}
                    className="find-job-container back-color main-container"
                  >
                    <FilterBar
                      isMobile={true}
                      showLoader={showLoader}
                      filters={filters}
                      selectedFilters={selectedFilter}
                      search={keyword}
                      location={location}
                      range={radius}
                      handleChange={this.handleChange}
                      handleSetData={this.handleSetData}
                      handleFilterChange={this.handleFilterChange}
                      handleRangeValue={this.handleRangeValue}
                      jobTypes={jobTypes}
                      handleJobType={this.handleJobType}
                      datePosted={datePosted}
                      selectedDate={date_posted}
                      salary={salary}
                      handleSalaryValue={this.handleSalaryValue}
                      salaryMentioned={included_jobs_without_salary_mentioned}
                      handleSalaryChecked={this.handleSalaryChecked}
                      handleSaveFilter={this.handleSaveFilterModal}
                      job_type={job_type}
                      applyFilter={this.applyFilter}
                      handleClear={this.handleClear}
                      changedState={changedState}
                      setFindJobData={this.props.setFindJobData}
                    />
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
          </Hidden>
        </DashboardboardBanner>

        <Grid
          container
          className={
            contentLoader ? "pe-none main-container" : " main-container"
          }
          spacing={2}
        >
          <Hidden smDown>
            <FilterBar
              showLoader={showLoader}
              filters={filters}
              selectedFilters={selectedFilter}
              search={keyword}
              location={location}
              range={radius}
              handleChange={this.handleChange}
              handleSetData={this.handleSetData}
              handleFilterChange={this.handleFilterChange}
              handleRangeValue={this.handleRangeValue}
              jobTypes={jobTypes}
              handleJobType={this.handleJobType}
              datePosted={datePosted}
              selectedDate={date_posted}
              salary={salary}
              handleSalaryValue={this.handleSalaryValue}
              salaryMentioned={included_jobs_without_salary_mentioned}
              handleSalaryChecked={this.handleSalaryChecked}
              handleSaveFilter={this.handleSaveFilterModal}
              job_type={job_type}
              applyFilter={this.applyFilter}
              handleClear={this.handleClear}
              changedState={changedState}
              setFindJobData={this.props.setFindJobData}
            />
          </Hidden>
          <Grid xs={12} md={8} item>
            <div className="job-list">
              <Typography className="job-list-heading">
                {!contentLoader && count ? (
                  <>
                    Showing {page === 1 ? 1 : page * 20 - 19} {"-"}
                    {Math.ceil(count / 20) === page
                      ? count
                      : count <= 20
                      ? count
                      : page * 20}{" "}
                    of {count} jobs
                  </>
                ) : (
                  ""
                )}
              </Typography>
              {!contentLoader ? (
                <div className="job-list-container">
                  {/* Jobs List  */}
                  {results && results.length > 0 ? (
                    results.map((item, index) => (
                      <div className="job-list-item cursor-pointer" key={index}>
                        <JobCard
                          item={item}
                          removeJob={this.removeSavedJob}
                          saveJob={this.saveJob}
                          history={this.props.history}
                        />
                      </div>
                    ))
                  ) : (
                    <NoData msg="No Jobs Found" />
                  )}
                  {results.length !== 0 && (
                    <div className="job-list-footer">
                      <Pagination
                        page={page}
                        count={Math.ceil(count / 20)}
                        showFirstButton
                        showLastButton
                        onChange={(e, val) => {
                          this.props.setFindJobData({ page: val });
                          this.applyFilter({});
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <ContentLoader />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ dashboard }) => ({
  dashboard: dashboard,
});

const mapDispatchToProps = dispatch => {
  return {
    getAllJobs: () => dispatch(actions.getAllJobs()),
    setFindJobData: payload => dispatch(actions.setFindJobData(payload)),
    setContentLoader: data => dispatch(actions.setContentLoader(data)),
    SetLoadingData: data => dispatch(actions.SetLoadingData(data)),
    getAllSavedFilters: () => dispatch(actions.getAllSavedFilters()),
    applySavedFilter: () => dispatch(actions.applySavedFilter()),
    applyFilter: (refresh, clearData, fromApplyFilter) =>
      dispatch(actions.applyFilter(refresh, clearData, fromApplyFilter)),
    getJobCount: () => dispatch(actions.getJobCount()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindJobs);

//Skeleton Content Loader
const ContentLoader = () => {
  return (
    <div className="job-list-container">
      <Skeleton variant="rect" animation="wave" width="20vw" />

      {Array(10)
        .fill(0)
        .map((_, index) => (
          <div className="job-list-item" key={index}>
            <div className="job-list-item-container">
              <div className="job-header">
                <div className="job-list-item-left">
                  <Skeleton variant="rect" animation="wave" width="30vw" />
                </div>
              </div>
              <div className="job-list-item-body filter-heading">
                <div>
                  <span>
                    <Skeleton variant="rect" width="10vw" />
                  </span>
                </div>
                <div className="ml-10">
                  <span>
                    <Skeleton variant="rect" width="10vw" />
                  </span>
                </div>
              </div>
              <div className="job-list-item-footer">
                <Skeleton variant="rect" width={50} />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
