import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Logo from "../../assets/images/logo.png";
import Loader from "../../assets/images/loader.svg";
import * as Action from "../../modules/actions";

class VerifyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      msg: "",
    };
  }
  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params) {
      Action.VerifyUserAction(params.get("otp"), cb => {
        if (cb === true) {
          this.props.history.push("/login");
        }
        this.setState({ showLoader: false, msg: cb.message });
      });
    }
  }

  render() {
    return (
      <>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div
            className="mt-90"
            onClick={() => {
              this.props.history.push("/home");
            }}
          >
            <img
              className="cursor-pointer"
              src={Logo}
              alt="logo"
              height="50px"
              width={"120px"}
            />
          </div>
          <div>
            {this.state.showLoader ? (
              <img src={Loader} height="20" alt="img" />
            ) : (
              this.state.msg
            )}
          </div>
        </Grid>
      </>
    );
  }
}

export default VerifyUser;
