import React, { Component } from "react";
import "./terms-and-conditions.css";
import { Grid } from "@material-ui/core";
// import Logo from "../../assets/images/logo.png";
import CloseIcon from "../../assets/images/close.svg";
class TermsAndConditions extends Component {
  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={2} sm={2} md={2} lg={2} className="terms-logo">
            <img
              className="cursor-pointer"
              src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
              width={"180px"}
              alt="logo"
              height="50px"
            />
          </Grid>
          <Grid item xs={7} sm={7} md={8} lg={8}></Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} className="close-icon ">
            <img
              className="cursor-pointer close-img-icon"
              onClick={() => {
                this.props.history.push("/home");
              }}
              src={CloseIcon}
              alt="close"
            />
          </Grid>
        </Grid>
        <div className="text-container-margin">
          <div className="terms-heading mt-60">Terms & Conditions</div>
          {/* <div className="date-title mt-40">
            Effective as of November 17, 2021
          </div> */}
          <div className="privacy-content">
            These terms and conditions (“Agreement”) set forth the general terms
            and conditions of your use of the visabridge.com website (“Website”
            or “Service”) and any of its related products and services
            (collectively, “Services”). This Agreement is legally binding
            between you (“User”, “you” or “your”) and this Website operator
            (“Operator”, “we”, “us” or “our”). If you are entering into this
            agreement on behalf of a business or other legal entity, you
            represent that you have the authority to bind such entity to this
            agreement, in which case the terms “User”, “you” or “your” shall
            refer to such entity. If you do not have such authority, or if you
            do not agree with the terms of this agreement, you must not accept
            this agreement and may not access and use the Website and Services.
            By accessing and using the Website and Services, you acknowledge
            that you have read, understood, and agree to be bound by the terms
            of this Agreement. You acknowledge that this Agreement is a contract
            between you and the Operator, even though it is electronic and is
            not physically signed by you, and it governs your use of the Website
            and Services. This terms and conditions policy was created with the
            help of the terms and conditions generator at
            https://www.websitepolicies.com/terms-and-conditions-generator
          </div>

          <div className="second-terms-title mt-30">
            Accounts and membership
          </div>
          <div className="privacy-content">
            You must be at least 18 years of age to use the Website and
            Services. By using the Website and Services and by agreeing to this
            Agreement you warrant and represent that you are at least 18 years
            of age. If you create an account on the Website, you are responsible
            for maintaining the security of your account and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it. We may monitor and review
            new accounts before you may sign in and start using the Services.
            Providing false contact information of any kind may result in the
            termination of your account. You must immediately notify us of any
            unauthorized uses of your account or any other breaches of security.
            We will not be liable for any acts or omissions by you, including
            any damages of any kind incurred as a result of such acts or
            omissions. We may suspend, disable, or delete your account (or any
            part thereof) if we determine that you have violated any provision
            of this Agreement or that your conduct or content would tend to
            damage our reputation and goodwill. If we delete your account for
            the foregoing reasons, you may not re-register for our Services. We
            may block your email address and Internet protocol address to
            prevent further registration.
          </div>

          <div className="second-terms-title mt-30">
            Links to other resources
          </div>
          <div className="privacy-content">
            Although the Website and Services may link to other resources (such
            as websites, mobile applications, etc.), we are not, directly or
            indirectly, implying any approval, association, sponsorship,
            endorsement, or affiliation with any linked resource, unless
            specifically stated herein. We are not responsible for examining or
            evaluating, and we do not warrant the offerings of, any businesses
            or individuals or the content of their resources. We do not assume
            any responsibility or liability for the actions, products, services,
            and content of any other third parties. You should carefully review
            the legal statements and other conditions of use of any resource
            which you access through a link on the Website. Your linking to any
            other off-site resources is at your own risk.
          </div>

          <div className="second-terms-title mt-30">
            Changes and amendments{" "}
          </div>
          <div className="privacy-content">
            We reserve the right to modify this Agreement or its terms related
            to the Website and Services at any time at our discretion. When we
            do, we will revise the updated date at the bottom of this page, post
            a notification on the main page of the Website. We may also provide
            notice to you in other ways at our discretion, such as through the
            contact information you have provided. An updated version of this
            Agreement will be effective immediately upon the posting of the
            revised Agreement unless otherwise specified. Your continued use of
            the Website and Services after the effective date of the revised
            Agreement (or such other act specified at that time) will constitute
            your consent to those changes.
          </div>

          <div className="second-terms-title mt-30">
            Acceptance of these terms
          </div>
          <div className="privacy-content">
            You acknowledge that you have read this Agreement and agree to all
            its terms and conditions. By accessing and using the Website and
            Services you agree to be bound by this Agreement. If you do not
            agree to abide by the terms of this Agreement, you are not
            authorized to access or use the Website and Services.
          </div>
          <div className="second-terms-title mt-30">
            Visa Bridge Privacy Policy.
          </div>
          <div className="privacy-content">
            Visa Bridge operates the http://www.visabridge.com website, which
            provides the service of connecting visa-required workers with
            companies and employment opportunities in the United States. This
            page is used to inform website visitors regarding our policies with
            the collection, use, and disclosure of Personal Information if
            anyone decided to use our Service, the Visa Bridge website.
            <span>
              If you choose to use our Service, then you agree to the collection
              and use of information in relation with this policy. The Personal
              Information that we collect are used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy. The terms used in this
              Privacy Policy have the same meanings as in our Terms and
              Conditions, which is accessible at http://www.visabridge.com,
              unless otherwise defined in this Privacy Policy.
            </span>
          </div>

          <div className="second-terms-title mt-30">
            Information Collection and Use
          </div>
          <div className="privacy-content">
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, phone number, and postal
            address. The information that we collect will be used to contact or
            identify you.
          </div>

          <div className="second-terms-title mt-30">Log Data</div>
          <div className="privacy-content">
            We want to inform you that whenever you visit our Service, we
            collect information that your browser sends to us that is called Log
            Data. This Log Data may include information such as your computer’s
            Internet Protocol ("IP") address, browser version, pages of our
            Service that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
          </div>

          <div className="second-terms-title mt-30">Cookies</div>
          <div className="privacy-content">
            Cookies Cookies are files with small amount of data that is commonly
            used an anonymous unique identifier. These are sent to your browser
            from the website that you visit and are stored on your computer’s
            hard drive. Our website uses these "cookies" to collection
            information and to improve our Service. You have the option to
            either accept or refuse these cookies, and know when a cookie is
            being sent to your computer. If you choose to refuse our cookies,
            you may not be able to use some portions of our Service.
          </div>

          <div className="second-terms-title mt-30">Service Providers</div>
          <div className="privacy-content">
            We may employ third-party companies and individuals due to the
            following reasons: To facilitate our Service; To provide the Service
            on our behalf; To perform Service-related services; or To assist us
            in analyzing how our Service is used. We want to inform our Service
            users that these third parties have access to your Personal
            Information. The reason is to perform the tasks assigned to them on
            our behalf. However, they are obligated not to disclose or use the
            information for any other purpose.
          </div>

          <div className="second-terms-title mt-30">Security</div>
          <div className="privacy-content">
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </div>

          <div className="second-terms-title mt-30">Links to Other Sites</div>
          <div className="privacy-content">
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </div>

          <div className="second-terms-title mt-30">Children’s Privacy</div>
          <div className="privacy-content">
            Our Services do not address anyone under the age of 13. We do not
            knowingly collect personal identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </div>

          <div className="second-terms-title mt-30">
            Changes to This Privacy Policy
          </div>
          <div className="privacy-content">
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately, after they are posted on
            this page.
          </div>
          {/* 
          <div className="second-terms-title mt-30">Contact Us</div>
          <div className="privacy-content">
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us.
          </div> */}

          <div className="second-terms-title mt-30">Contacting us </div>
          <div className="privacy-content">
            If you have any questions, concerns, or complaints regarding this
            Agreement, we encourage you to contact us using this email address:
            <a href="mailto:support@visabridge.com"> support@visabridge.com</a>.
            This document was last updated on April 8, 2022.
          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndConditions;
