import React, { Component, useState } from "react";
import Select from "react-select";
import {
  Grid,
  Typography,
  Slider,
  withStyles,
  Switch,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Checkbox,
  Button,
} from "@material-ui/core";
import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import heart from "../../assets/images/heart.svg";
import searchIcon from "../../assets/images/search.svg";
import locationIcon from "../../assets/images/location.svg";
import Loader from "../../assets/images/loader.svg";
import DashboardIcons from "../../components/icons/DashboardIcons";
import "./filterbar.css";

//IOS style Switch
const IOSSwitch = withStyles(theme => ({
  root: {
    width: 60,
    height: 28,
    padding: 0,
    margin: 0,
    overflow: "inherit",
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(32px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#6A67CE",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#6A67CE",
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 28 / 2,
    border: `1px solid #bdbdbd`,
    backgroundColor: "#bdbdbd",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const libraies = ["places"];

const GoogleAutoCompleteInput = props => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: libraies,
  });
  const [searchBox, setSearchBox] = useState(null);

  const onLoad = ref => setSearchBox(ref);

  const onPlaceChanged = () => {
    let place = searchBox.getPlaces()[0];
    props.setFindJobData({
      location: place.formatted_address,
    });
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlaceChanged}>
      <input
        className={"signup-input-field"}
        type="location"
        placeholder="City or Pincode"
        autoComplete="off"
        name="location"
        value={props.location}
        onChange={props.handleSetData}
      />
    </StandaloneSearchBox>
  ) : (
    "loading"
  );
};

class FilterBar extends Component {
  salaryValue = value => {
    if (value === 60000) {
      return `$${value}+`;
    }
    return `$${value}`;
  };

  valueText = value => {
    return `${value} Mi`;
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.props.setFindJobData({
        page: 1,
      });
      this.props.applyFilter({ fromApplyFilter: true });
    }
  };

  render() {
    const {
      filters,
      selectedFilters,
      search,
      location,
      range,
      jobTypes,
      datePosted,
      selectedDate,
      salary,
      salaryMentioned,
      showLoader,
      job_type,
      changedState,
    } = this.props;

    return (
      <>
        <Grid item sm={0} md={4}>
          <div className="sidebar">
            <div className="saved-filter mt-28">
              <Typography className="filter-heading">Saved Filters</Typography>
              <div className="saved-filter-container">
                <img src={heart} alt="img" className="icon z-2" />
                <Select
                  options={filters}
                  value={selectedFilters}
                  placeholder="Choose Filter"
                  onChange={this.props.handleFilterChange}
                  classNamePrefix="select"
                />
              </div>
            </div>
            <div onKeyDown={this.handleEnterPress}>
              <div className="search-keywords mt-28">
                <Typography className="filter-heading">
                  Search by Keywords
                </Typography>
                <div className="search-keywords-container">
                  <img src={searchIcon} alt="img" className="icon" />
                  <input
                    className={"signup-input-field"}
                    type="search"
                    placeholder="Job title, keywords or company"
                    autoComplete="off"
                    name="keyword"
                    value={search}
                    onChange={this.props.handleSetData}
                  />
                </div>
              </div>
              <div className="filter-by-location mt-28">
                <Typography className="filter-heading">Location</Typography>
                <div className="filter-by-location-container">
                  <img src={locationIcon} alt="img" className="icon" />
                  <GoogleAutoCompleteInput
                    location={location}
                    handleSetData={this.props.handleSetData}
                    setFindJobData={this.props.setFindJobData}
                  />
                </div>
              </div>
              <div className="radius-filter mt-28">
                <Typography className="filter-heading1">
                  Radius around selected destination
                </Typography>
                <div className="radius-filter-container">
                  <Slider
                    name="range"
                    value={range}
                    onChange={this.props.handleRangeValue}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    valueLabelFormat={this.valueText}
                    max={100}
                    min={0}
                    className="slider"
                  />
                </div>
              </div>

              <div className="job-type mt-50">
                <Typography className="filter-heading ">Job Type</Typography>
                <div className="job-type-container">
                  <Grid container spacing={2}>
                    {jobTypes.map((jobType, index) => (
                      <Grid item sm={12} md={6} key={index}>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={jobType.value === job_type}
                              onChange={this.props.handleJobType}
                              name={jobType.value}
                            />
                          }
                          label={jobType.label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </div>
              <div className="date-posted mt-28">
                <Typography className="filter-heading ">Date Posted</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="selectedDate"
                    name="date_posted"
                    value={selectedDate}
                    onChange={this.props.handleSetData}
                  >
                    {datePosted.map((date, index) => (
                      <FormControlLabel
                        key={index}
                        value={date.name}
                        control={<Radio />}
                        label={date.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="salary-filter mt-28">
                <Typography className="filter-heading1">Salary</Typography>
                <div className="salary-filter-container">
                  <Slider
                    name="salary"
                    value={salary}
                    onChange={this.props.handleSalaryValue}
                    valueLabelDisplay="on"
                    aria-labelledby="salary-slider"
                    valueLabelFormat={this.salaryValue}
                    max={60000}
                    className="slider"
                  />
                </div>
              </div>
              <div className="checkbox-filter mt-40 ">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={salaryMentioned}
                      onChange={this.props.handleSalaryChecked}
                      name="included_jobs_without_salary_mentioned"
                      color="primary"
                    />
                  }
                  label="Include jobs without salary mentioned"
                />
              </div>
            </div>
            <div className="btn-container mt-40 mb-20">
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    value="Submit"
                    variant="contained"
                    className=" text-capitalized btn-primary"
                    onClick={() => {
                      this.props.setFindJobData({
                        page: 1,
                      });
                      this.props.applyFilter({ fromApplyFilter: true });
                    }}
                  >
                    {showLoader ? (
                      <img src={Loader} height="20" alt="img" />
                    ) : (
                      "Apply"
                    )}
                  </Button>
                  <Button
                    value="Submit"
                    variant="outlined"
                    className=" text-capitalized btn-secondary"
                    onClick={this.props.handleSaveFilter}
                  >
                    Save Filter
                  </Button>
                </Grid>
                <Grid item>
                  {((selectedFilters?.value !== "none" &&
                    selectedFilters !== null) ||
                    changedState) && (
                    <DashboardIcons
                      type="clear"
                      onClick={this.props.handleClear}
                      color="#6a67ce"
                      style={{
                        paddingTop: "5px",
                        display: "block",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </>
    );
  }
}

export default FilterBar;
