import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AppHeader from "../../AppHeader";
import AppFooter from "../../AppFooter";

class MainLayout extends Component {
  render() {
    return (
      <>
        <AppHeader {...this.props}></AppHeader>
        {this.props.children}
        <div>
          <AppFooter {...this.props} />
        </div>
      </>
    );
  }
}

export default withRouter(MainLayout);
