import React, { useState } from "react";
import { Button } from "@material-ui/core";
import BankCards from "../../containers/Profile/BankCards";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Loader from "../../assets/images/loader.svg";

const CardSlider = props => {
  const [hoverState, setHoverState] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);
  const handleHover = index => {
    setHoverState(true);
    setHoveredCard(index);
  };

  const handleHoverRemove = () => {
    setHoverState(false);
    setHoveredCard(null);
  };
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      spaceBetween={20}
      slidesPerView={1}
      grabCursor={true}
    >
      {props.cards.length > 0 &&
        props.cards.map((card, index) => (
          <SwiperSlide key={index} style={{display:"block" , backgroundColor:"#ecf4fe"}}  >
            <BankCards
              last4={card.card.last4}
              month={card.card.exp_month}
              year={card.card.exp_year}
              cardType={card.card.brand}
              hoverState={hoverState}
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={() => handleHoverRemove()}
              hoveredCard={hoveredCard}
              index={index}
              onDeleteCard={index => props.onDeleteCard(index, card)}
              userName={props.name}
              defaultCard={props.defaultCard === card.id}
               />
            
            <div className="text-center pb-20 mt-8">
            
              {props.defaultCard === card.id ? (
                "Default Card"
              ) : (
                <div className="btn-container ">
                  <Button
                    value="Submit"
                    variant="contained"
                    className=" text-capitalized btn-primary"
                    onClick={() => props.makeDefault(card.id)}
                  >
                    {props.makeDefaultLoader ? (
                      <img src={Loader} height="20" alt="img" />
                    ) : (
                      "Make Default"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default CardSlider;
