import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import * as actions from "../../modules/actions";
import Loader from "../../assets/images/loader.svg";
import LockIcon from "../../assets/images/lock.svg";
import EyeIcon from "../../assets/images/eye.svg";
import EyeOffIcon from "../../assets/images/eye-off.svg";
import { passwordValidation } from "../../utils/validations";
import { MESSAGE } from "../../constants/messages";
import CheckCircleBlue from "../../assets/images/check-circle-blue.svg";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      currentPasswordVisibility: false,
      passwordVisibility: false,
      confPasswordVisibility: false,

      currentPassword: "",
      currentPasswordError: false,
      currentPasswordErrorString: "",
      password: "",
      passswordError: false,
      passwordErrorString: "",
      confPassword: "",
      confPassswordError: false,
      confPasswordErrorString: "",
    };
  }

  handleChangePassword = () => {
    let isValid = true;
    let formFields = ["currentPassword", "password", "confPassword"];
    formFields.forEach(field => {
      isValid = this.validateField(field) && isValid;
    });
    if (isValid) {
      this.setState({
        showLoader: true,
      });
      let payload = {
        current_password: this.state.currentPassword,
        new_password: this.state.password,
        confirm_password: this.state.confPassword,
      };
      actions.changePassword(this.props.user_id, payload, cb => {
        this.handleCancel();
      });
    }
  };

  handleInputChange = (evt, inputName) => {
    this.setState({
      [inputName]: evt.target.value,
    });
    this.validateField(inputName, evt.target.value);
  };

  validateCurrentPassword = val => {
    let error = "";
    let value = val || this.state.currentPassword;
    if (value.trim() === "") {
      error = MESSAGE.EMPTY_FIELD;
      this.setState({
        currentPasswordError: true,
        currentPasswordErrorString: error,
      });
    } else if (!passwordValidation(value)) {
      error = MESSAGE.PASSWORD_INVALID;
      this.setState({
        currentPasswordError: true,
        currentPasswordErrorString: error,
      });
    } else {
      this.setState({
        currentPasswordError: false,
        currentPasswordErrorString: "",
      });
    }
    return error === "";
  };

  validatePassword = val => {
    let error = "";
    let value = val || this.state.password;
    if (value.trim() === "") {
      error = MESSAGE.EMPTY_FIELD;
      this.setState({
        passswordError: true,
        passwordErrorString: error,
      });
    } else if (!passwordValidation(value)) {
      error = MESSAGE.PASSWORD_INVALID;

      this.setState({
        passswordError: true,
        passwordErrorString: error,
      });
    } else if (value === this.state.currentPassword) {
      error = "Current Pasword and New Password should not be same";

      this.setState({
        passswordError: true,
        passwordErrorString: error,
      });
    } else {
      this.setState({
        passswordError: false,
        passwordErrorString: "",
      });
    }
    return error === "";
  };

  validateConfirmPassword = val => {
    let error = "";
    let value = val || this.state.confPassword;
    if (value.trim() === "") {
      error = MESSAGE.EMPTY_FIELD;
      this.setState({
        confPassswordError: true,
        confPasswordErrorString: error,
      });
    } else if (value !== this.state.password) {
      error = MESSAGE.PASSWORD_NOT_MATCH;
      this.setState({
        confPassswordError: true,
        confPasswordErrorString: error,
      });
    } else {
      this.setState({
        confPassswordError: false,
        confPasswordErrorString: "",
      });
    }
    return error === "";
  };

  validateField = (name, val) => {
    let isValid = false;
    if (name === "currentPassword") {
      isValid = this.validateCurrentPassword(val);
    } else if (name === "password") {
      isValid = this.validatePassword(val);
    } else if (name === "confPassword") {
      isValid = this.validateConfirmPassword(val);
    }
    return isValid;
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.handleChangePassword();
    }
  };

  handleCancel = () => {
    this.setState({
      showLoader: false,
      currentPasswordVisibility: false,
      passwordVisibility: false,
      confPasswordVisibility: false,

      currentPassword: "",
      currentPasswordError: false,
      currentPasswordErrorString: "",
      password: "",
      passswordError: false,
      passwordErrorString: "",
      confPassword: "",
      confPassswordError: false,
      confPasswordErrorString: "",
    });
  };

  render() {
    let {
      password,
      confPassword,
      passswordError,
      confPassswordError,
      passwordErrorString,
      confPasswordErrorString,
      currentPassword,
      currentPasswordError,
      currentPasswordErrorString,
      currentPasswordVisibility,
      passwordVisibility,
      confPasswordVisibility,
      showLoader,
    } = this.state;
    return (
      <>
        <Grid
          container
          alignContent="center"
          direction="column"
          className="change-password"
        >
          <Grid item md={6} xs={12}>
            <div className="terms-heading mt-40 text-center">
              Change Password
            </div>

            <div onKeyDown={this.handleEnterPress}>
              <div className="mt-28">
                <img src={LockIcon} alt="img" className="icon" />
                <input
                  className={
                    currentPasswordError
                      ? "signup-input-field error-border "
                      : "signup-input-field "
                  }
                  type={currentPasswordVisibility ? "text" : "password"}
                  placeholder="Current Password"
                  autoComplete="off"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={e => this.handleInputChange(e, "currentPassword")}
                />
                {currentPasswordVisibility ? (
                  <img
                    onClick={() => {
                      this.setState({
                        currentPasswordVisibility: false,
                      });
                    }}
                    src={EyeOffIcon}
                    alt="img"
                    className="eye-icon"
                  />
                ) : (
                  <img
                    onClick={() => {
                      this.setState({
                        currentPasswordVisibility: true,
                      });
                    }}
                    src={EyeIcon}
                    alt="img"
                    className="eye-icon"
                  />
                )}

                {currentPasswordError && (
                  <div className="err ">{currentPasswordErrorString}</div>
                )}
              </div>
              <div className="mt-28">
                <img src={LockIcon} alt="img" className="icon" />
                <input
                  className={
                    passswordError
                      ? "signup-input-field error-border "
                      : "signup-input-field"
                  }
                  type={passwordVisibility ? "text" : "password"}
                  placeholder="New Password"
                  autoComplete="off"
                  name="password"
                  value={password}
                  onChange={e => this.handleInputChange(e, "password")}
                />
                {passwordVisibility ? (
                  <img
                    onClick={() => {
                      this.setState({
                        passwordVisibility: false,
                      });
                    }}
                    src={EyeOffIcon}
                    alt="img"
                    className="eye-icon"
                  />
                ) : (
                  <img
                    onClick={() => {
                      this.setState({
                        passwordVisibility: true,
                      });
                    }}
                    src={EyeIcon}
                    alt="img"
                    className="eye-icon"
                  />
                )}

                {passswordError && (
                  <div className="err ">{passwordErrorString}</div>
                )}
              </div>
              <div className="mt-28">
                <img src={CheckCircleBlue} alt="img" className="icon" />
                <input
                  className={
                    confPassswordError
                      ? "signup-input-field error-border "
                      : "signup-input-field "
                  }
                  type={confPasswordVisibility ? "text" : "password"}
                  placeholder="Confirm Password"
                  autoComplete="off"
                  name="confPassword"
                  value={confPassword}
                  onChange={e => this.handleInputChange(e, "confPassword")}
                />
                {confPasswordVisibility ? (
                  <img
                    onClick={() => {
                      this.setState({
                        confPasswordVisibility: false,
                      });
                    }}
                    src={EyeOffIcon}
                    alt="img"
                    className="eye-icon"
                  />
                ) : (
                  <img
                    onClick={() => {
                      this.setState({
                        confPasswordVisibility: true,
                      });
                    }}
                    src={EyeIcon}
                    alt="img"
                    className="eye-icon"
                  />
                )}

                {confPassswordError && (
                  <div className="err ">{confPasswordErrorString}</div>
                )}
              </div>
            </div>

            <div className="btn-container text-center">
              <Button
                value="Submit"
                className="btn-primary text-capitalized mt-26"
                onClick={this.handleChangePassword}
              >
                {showLoader ? (
                  <img src={Loader} height="20" alt="img" />
                ) : (
                  <span style={{marginTop:"11px"}}>Reset</span>
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user_id: auth.id,
});

export default connect(mapStateToProps, undefined)(ChangePassword);
