// eslint-disable-next-line
import S3 from "aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: "us-east-2",
  accessKeyId: process.env.REACT_APP_AWS_BUCKET_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_BUCKET_SECRET_KEY,
  dirName: "upload",
};

export const deleteFileFromS3 = (file, cb) => {
  if (file) {
    const fileName = file.split("/").pop();
    const S3Client = new S3(config);

    S3Client.deleteFile(fileName)
      .then(response => {
        console.log("Response from S3", response);
        cb(response);
      })
      .catch(error => {
        cb(false);
        console.log("Error", error);
      });
  } else {
    console.log("Not deleted S3 error");
  }
};

export const uploadFiletoS3 = (file, cb) => {
  if (file) {
    const fileName = file.name?.split(".").slice(0, -1).join(".");
    var d = new Date();
    const newFileName =
      d.getDate().toString() +
      "_" +
      d.getTime().toString() +
      "_" +
      fileName.replaceAll(" ", "_");

    const S3Client = new S3(config);
    Promise.resolve(S3Client.uploadFile(file, newFileName))
      .then(res => {
        cb(res);
      })
      .catch(error => {
        console.log("Error", error);
        cb(false);
      });
  } else {
    console.log("Not uploaded S3 error");
  }
};
