/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button, Container, Typography } from "@material-ui/core";
import MainImage from "../../assets/images/home-network.jpg";
import StickyNoteImg from "../../assets/images/asia-young-people-jobless-female-or-happy-trainee-2021-10-21-03-46-40-utc.jpg";
import UploadCvImg from "../../assets/images/download1.jpg";
import UserIcon from "../../assets/images/user-name-icon.svg";
import SecondStickyNoteImg from "../../assets/images/female-candidate-greeting-hr-recruiter-at-job-inte-2022-02-22-14-04-08-utc.jpg";
import ThirdStickyNoteImg from "../../assets/images/african-college-student-2021-08-31-15-14-22-utc.jpg";
import "./home.css";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { options: "", selectedCountry: "" ,popUp:true};
  }
  render() {
    return (
      <>
      {this.props.isAuth || !this.state.popUp ? null :  <div className="" style={{position:"fixed", backgroundColor:"whitesmoke",left:"50%",transform:"translate(-50%, 0)",padding:"10px",bottom: "0",width:"100%",textAlign:"center" ,zIndex:"2"}}>
      <span style={{marginRight:"8px"}}> Sign Up for a free 24 hour trial, no card, no commitments</span>    
        
                    <span style={{color:"blue", cursor:"pointer",textDecoration:"underline"}} onClick={() =>
                      this.props.history.push("/signup")
                      }>Join Now</span>
                  <span style={{ position:"absolute",top:-12,right:18,borderRadius:"50%", cursor:"pointer"}} onClick={() => this.setState({ popUp:false})}>
                  {/* <span style={{marginTop:"0px"}}>X</span> */}
                  <HighlightOffIcon/>
                  </span>
                  </div>}
       
        <div className="your-job-content">
          <video
            id="background-video"
            autoPlay
            loop
            muted
            src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/video/World.mp4"
            type="video/mp4"
          ></video>

          <Container
            style={{
              zindex: 99,
              position: "inherit",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <>
                  <div className="find-job-content">
                    <Typography>Building International</Typography>
                    <Typography>Bridges of Opportunity</Typography>
                  </div>
                  <div className="find-job-sub-content">
                    Visa Bridge Connects Visa-Required Workers with Companies
                    and Employment Opportunities in the United States.
                  </div>
                  <div className="desktop-btn">
                    <Button
                      className="join-btn text-capitalized ml-0 mt-32 "
                      onClick={() =>
                        this.props.isAuth
                          ? this.props.history.push("/dashboard")
                          : this.props.history.push("/signup")
                      }
                    >
                      {this.props.isAuth ? <span style={{marginTop:"-3px"}}>Dashboard</span> : <span style={{marginTop:"-3px"}}>Join Now</span>}
                    </Button>
                  </div>
                </>
              </Grid>
              <Grid item xs={1} sm={1} md={2}></Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Grid
                  container
                  style={{
                    height: "100%",
                    alignItems:"center",
                    display: "flex",
                  }}
                >
                  <Grid item sm={12} md={12} className="main-homeimage mt-10">
                    <img
                      className="main-image"
                      alt="box"
                      src={MainImage}
                      style={{ width: "72% !important" }}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div className="center-btn-mob">
                      <Button
                        className="join-btn text-capitalized ml-0 mt-32 "
                        onClick={() =>
                          this.props.isAuth
                            ? this.props.history.push("/dashboard")
                            : this.props.history.push("/signup")
                        }
                      >
                        {this.props.isAuth ? "Dashboard" : <span style={{marginTop:"-3px"}}>Join Now</span>}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Grid container className="mb-90">
          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <div className="who-are-we-content text-center">How We Do It</div>
            <div className="who-are-we-sub-content text-center">
              Our machine learning platform "Many Bridges" is the power behind
              our success.
            </div>
          </Grid>

          {/* Middle content for Desktop */}
          <Grid container className="desktop-mid-div">
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid className="mt-90" item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img">
                <img className="social-box-img" alt="img" src={StickyNoteImg} />
                <Button
                  className="images-btn access-data-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  Access company data{" "}
                </Button>
                <Button
                  className="images-btn  upload-cv-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  Upload your CV{" "}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className="center-element">
              <div className="sticky-note-img-content mt-90 pl-20">
                Visa-Required Workers
                <div className="sticky-note-img-sub-content mt-20 pl-20">
                  <ul>
                    <li>
                      Time- Save time searching for companies with the bandwidth
                      to hire Visa-Required workers.
                    </li>
                    <li>
                      Data- Data that give you insight to companies that are
                      able to sponsor your visa.
                    </li>
                    <li>
                      Relationships- build lasting relationships with partner
                      companies on the platform.
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} className="center-element">
              <div className="sticky-note-img-content mt-100 pl-20">
                Partner Companies
              </div>

              <div className="sticky-note-img-sub-content  pl-83">
                <ul>
                  <li>
                  Time- Highly Skilled Visa Required Candidates All in One
                    Place.
                  </li>
                  <li>Data- Insights On Visa Workforce Community.</li>
                  <li>
                    Relationships- Build Candidate Relationships And Pipelines
                    For Highly Technical Skills.
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid className="mt-90" item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img secondSticky">
                <img
                  className="social-box-img "
                  alt="img"
                  src={SecondStickyNoteImg}
                />
                <Button
                  className="images-btn pre-screen-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Pre-screened applicants{" "}
                </Button>
                <Button
                  className="images-btn  quali-visa-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Qualified visa-workforce{" "}
                </Button>
              </div>
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
            <Grid className="mt-90" item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img">
                <img
                  className="social-box-img "
                  alt="img"
                  src={ThirdStickyNoteImg}
                />
                <Button
                  className="images-btn access-data-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Increase international applications{" "}
                </Button>
                <Button
                  className="images-btn  upload-cv-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Enhance corporate partnerships{" "}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className="center-element">
              <div className="sticky-note-img-content mt-97 pl-20">
                Universities
              </div>
              <div className="sticky-note-img-sub-content pl-20">
                <ul>
                  <li>
                    Increase- Increase International Student US Job Acceptance
                    Rates.
                  </li>
                  <li>
                    Data- Deliver Insights to Future International Students On
                    Jobs Accepted In The US.
                  </li>
                  <li>
                    Relationships- Build Lasting International Alumni
                    Relationships.
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          </Grid>

          {/* Middle content for Mobile */}
          <Grid className="mobile-mid-div mt-100 " >
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <ul className="sticky-note-img-content ">
                Visa-Required Workers
                <li>
                  Time- Save time searching for companies with the bandwidth to
                  hire Visa-Required workers.
                </li>
                <li>
                  Data- Data that give you insight to companies that are able to
                  sponsor your visa.
                </li>
                <li>
                  Relationships- build lasting relationships with partner
                  companies on the platform.
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img">
                <img
                  className="social-box-img "
                  alt="img"
                  src={StickyNoteImg}
                />
                <Button
                  className="images-btn access-data-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Access company data{" "}
                </Button>
                <Button
                  className="images-btn  upload-cv-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Upload your CV{" "}
                </Button>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid className="mt-55" item xs={12} sm={12} md={4} lg={4}>
              <ul className="sticky-note-img-content ">
                Partner Companies
                <li>
                  Time- Highly skilled visa required candidates all in one
                  place.
                </li>
                <li>Data- Insights on visa workforce community.</li>
                <li>
                  Relationships- build candidate relationships and pipelines for
                  highly technical skills.
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img ">
                <img
                  className="social-box-img "
                  alt="img"
                  src={SecondStickyNoteImg}
                />
                <Button
                  className="images-btn pre-screen-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Pre-screened applicants{" "}
                </Button>
                <Button
                  className="images-btn  quali-visa-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Qualified visa-workforce{" "}
                </Button>
              </div>
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} className="mt-55">
              <ul className="sticky-note-img-content  pl-20">
                Universities
                <li>
                  Increase- Increase international student US job acceptance
                  rates.
                </li>
                <li>
                  Data- Deliver insights to future international students on
                  jobs accepted in the US.
                </li>
                <li>
                  Relationships- build lasting international alumni relationships.
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img">
                <img
                  className="social-box-img "
                  alt="img"
                  src={ThirdStickyNoteImg}
                />
                <Button
                  className="images-btn access-data-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Increase international applications{" "}
                </Button>
                <Button
                  className="images-btn  upload-cv-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Enhance corporate partnerships{" "}
                </Button>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          </Grid>

          {/* For Tablet View */}
          <Grid className="tablet-mid-div  mt-100" style={{ display: "none" }}>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img-content mt-90">
                Visa-Required Workers
                <div className="sticky-note-img-sub-content pl-83">
                  <ul>
                    <li>
                      Time- Save time searching for companies with the bandwidth
                      to hire Visa-Required workers.
                    </li>
                    <li>
                      Data- Data that give you insight to companies that are
                      able to sponsor your visa.
                    </li>
                    <li>
                      Relationships- build lasting relationships with partner
                      companies on the platform.
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img">
                <img
                  className="social-box-img "
                  alt="img"
                  src={StickyNoteImg}
                />
                <Button
                  className="images-btn access-data-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Access company data{" "}
                </Button>
                <Button
                  className="images-btn  upload-cv-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Upload your CV{" "}
                </Button>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid className="mt-55" item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img-content mt-100 pl-20">
                Partner Companies
              </div>

              <div className="sticky-note-img-sub-content  pl-83">
                <ul>
                  <li>
                    Time- Highly skilled visa required candidates all in one
                    place.
                  </li>
                  <li>Data- Insights on visa workforce community.</li>
                  <li>
                    Relationships- build candidate relationships and pipelines
                    for highly technical skills.
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img ">
                <img
                  className="social-box-img "
                  alt="img"
                  src={SecondStickyNoteImg}
                />
                <Button
                  className="images-btn pre-screen-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Pre-screened applicants{" "}
                </Button>
                <Button
                  className="images-btn  quali-visa-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Qualified visa-workforce{" "}
                </Button>
              </div>
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} className="mt-55">
              <div className="sticky-note-img-content mt-97 pl-20">
                Universities
              </div>
              <div className="sticky-note-img-sub-content pl-83">
                <ul>
                  <li>
                    Increase- Increase international student US job acceptance
                    rates.
                  </li>
                  <li>
                    Data- Deliver insights to future international students on
                    jobs accepted in the US.
                  </li>
                  <li>
                    Relationships- build lasting international alumni
                    relationships.
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="sticky-note-img">
                <img
                  className="social-box-img "
                  alt="img"
                  src={ThirdStickyNoteImg}
                />
                <Button
                  className="images-btn access-data-btn-position "
                  startIcon={<img src={UserIcon} alt="img" />}
                >
                  {" "}
                  Increase international applications{" "}
                </Button>
                <Button
                  className="images-btn  upload-cv-btn-position "
                  startIcon={<img src={UploadCvImg} alt="img" />}
                >
                  {" "}
                  Enhance corporate partnerships{" "}
                </Button>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
          </Grid>
        </Grid>
        
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth,
});

export default connect(mapStateToProps, undefined)(Home);
