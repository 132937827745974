import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route as DefaultRoute,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { getToken } from "../utils/localStorage";
import * as actions from "../modules/actions";
import RegisterationLayout from "../components/Layout/RegisterationLayout";
import MainLayout from "../components/Layout/MainLayout";
import BlankLayout from "../components/Layout/BlankLayout";
import DashboardLayout from "../components/Layout/DashboardLayout";
import Home from "./Home";
import HowItWorks from "./HowItWorks";
import ContactUs from "./ContactUs";
import TermsAndConditions from "./TermsAndConditions";
import SignUp from "./SignUp";
import Subscription from "./Subscription";
import SignIn from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyUser from "./VerifyUser";
import FindJobs from "./Dashboard/FindJobs";
import SavedJobs from "./Dashboard/SavedJobs";
import SavedFilter from "./Dashboard/SavedFilter";
import Profile from "./Profile";
import Job from "./Job";
import "./app.css";
// import AboutUs from "./AboutUs";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return children;
};

const Route = ({
  component: Component,
  layout: Layout = MainLayout,
  ...rest
}) => {
  return (
    <DefaultRoute
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const App = ({
  isAuth,
  subscription,
  getUserProfileOnDashboard,
  setSubscriptionStatus,
  trial,
  setTrialStatus,
}) => {
  const token = getToken();
  
    React.useEffect(() => {
        if (token) {
      getUserProfileOnDashboard(data => {
        if (data.trial_status) {
          setTrialStatus(true)
        } else {

          setTrialStatus(false)
        }
        if (data.subscription_status) {
          setSubscriptionStatus(true);
        } else {
          setSubscriptionStatus(false);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  let routes;
  if (!isAuth && !token) {
    routes = (
      <Switch>
        {/* <Route
          path={"/privacy-policy"}
          exact
          component={PrivacyPolicy}
          layout={BlankLayout}
        /> */}
        <Route
          path={"/terms-and-conditions"}
          exact
          component={TermsAndConditions}
          layout={BlankLayout}
        />

        <Route path={"/home"} exact component={Home} layout={MainLayout} />
        <Route
          path={"/how-it-works"}
          exact
          component={HowItWorks}
          layout={MainLayout}
        />
        {/* <Route
          path={"/about-us"}
          exact
          component={AboutUs}
          layout={MainLayout}
        /> */}
        <Route
          path={"/contact"}
          exact
          component={ContactUs}
          layout={MainLayout}
        />

        <Route
          path={"/signup"}
          exact
          component={SignUp}
          layout={RegisterationLayout}
        />

        <Route
          path={"/login"}
          exact
          component={SignIn}
          layout={RegisterationLayout}
        />

        <Route
          path={"/forgot-password"}
          exact
          component={ForgotPassword}
          layout={RegisterationLayout}
        />
        <Route
          path={"/reset-password"}
          exact
          component={ResetPassword}
          layout={RegisterationLayout}
        />
        <Route
          path={"/verify-user"}
          exact
          component={VerifyUser}
          layout={RegisterationLayout}
        />
        <Redirect to="/home" />
      </Switch>
    );
  } else if (isAuth && !subscription && !trial) {
    routes = (
      <Switch>
        <Route
          path={"/subscription-plan"}
          exact
          component={Subscription}
          layout={BlankLayout}
        />
        <Route
          path={"/subscription-payment"}
          exact
          component={Subscription}
          layout={BlankLayout}
        />
        <Redirect to={"/subscription-plan"} />
      </Switch>
    );
  } else if (isAuth && (subscription || trial)) {
    routes = (
      <Switch>
        {/* <Route
          path={"/privacy-policy"}
          exact
          component={PrivacyPolicy}
          layout={BlankLayout}
        /> */}
        <Route
          path={"/terms-and-conditions"}
          exact
          component={TermsAndConditions}
          layout={BlankLayout}
        />

        <Route path={"/home"} exact component={Home} layout={MainLayout} />
        <Route
          path={"/how-it-works"}
          exact
          component={HowItWorks}
          layout={MainLayout}
        />
        {/* <Route
          path={"/about-us"}
          exact
          component={AboutUs}
          layout={MainLayout}
        /> */}
        <Route
          path={"/contact"}
          exact
          component={ContactUs}
          layout={MainLayout}
        />

        <Route
          path={"/dashboard"}
          component={FindJobs}
          layout={DashboardLayout}
          exact
        />
        <Route
          path={"/saved-jobs"}
          component={SavedJobs}
          layout={DashboardLayout}
          exact
        />
        <Route
          path={"/saved-filters"}
          component={SavedFilter}
          layout={DashboardLayout}
          exact
        />
        <Route path={"/profile"} component={Profile} layout={DashboardLayout} />
        <Route
          path={"/dashboard/job/:id"}
          component={Job}
          layout={DashboardLayout}
        />

        <Redirect to="/dashboard" />
      </Switch>
    );
  }

  return (
    <BrowserRouter>
      <div id="appjs">
        <ScrollToTop>{routes}</ScrollToTop>
        {/* <Subscription/> */}
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAuth: auth.isAuth,
  subscription: auth.subscription_status,
  trial: auth.trial_status,
});

const mapDispatchToProps = dispatch => {
  return {
    getUserProfileOnDashboard: cb =>
      dispatch(actions.getUserProfileOnDashboard(cb)),
    setSubscriptionStatus: status =>
      dispatch(actions.setSubscriptionStatus(status)),
    setTrialStatus:status=>
      dispatch(actions.setTrialStatus(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
