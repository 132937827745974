import api from "../../services";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import { saveLoginInfo } from "../../utils/localStorage";
import { setDefaultJobs } from "./dashboardActions";

export const SignUp = (payload, cb) => {
  api
    .signUp(payload)
    .then(response => {
      let data = response.data;

      if (data.status_code === 201) {
        cb(true);
        // toast.success(data.message, {
        //   position: toast.POSITION.TOP_CENTER,
        //   closeButton: true,
        //   autoClose: false, 
        // });
      }
    })
    .catch(error => {
      let err = error.response.data;
      cb(err);
      if (err.status_code === 409) {
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
      }
      console.log("Error: ", error.response);
    });
};

export const SignIn = (payload, cb) => {
  return dispatch => {
    api
      .signIn(payload)
      .then(response => {
        let data = response.data;

        if (data.status_code === 200) {
          let { access, refresh, ...rest } = data.data;
          let { ...customer_data } = data.customer_data;

          //Saving login info to local storage
          saveLoginInfo({
            token: access,
            refreshToken: refresh,
            customerData: customer_data,
          });
          dispatch(
            setLoginAuth({
              isAuth: true,
              ...rest,
            })
          );

          if (data?.data?.subscription_status) {
            cb("login");
          } else {
            if (!data.customer_data.customer_id) {
              dispatch(addCustomer({ email: payload?.email }));
            }
            cb(true);
          }
        }
      })
      .catch(error => {
        let err = error.response.data;
        cb(false);
        if (err.status_code === 404) {
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            closeButton: true,
            autoClose: true,
          });
          console.log("Error: ", error.response);
        }
      });
  };
};

export const ForgotPassword = (payload, cb) => {
  api
    .forgotPassword(payload)
    .then(response => {
      let data = response.data;

      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      cb(err);

      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });

      console.log("Error: ", error.response);
    });
};

export const VerifyUserAction = (payload, cb) => {
  api
    .VerifyUser(payload)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      cb(err);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      console.log("Error: ", error.response);
    });
};

export const ResetPassword = (payload, cb) => {
  api
    .resetPassword(payload)
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      cb(err);
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });
      console.log("Error: ", error.response);
    });
};

export const setLoginAuth = payload => {
  return dispatch =>
    dispatch({
      type: actionTypes.SET_LOGIN,
      payload,
    });
};

export const setLogoutAuth = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_LOGOUT,
    });
    dispatch(setDefaultJobs());
  };
};

export const LogoutAuth = cb => {
  return dispatch => {
    api
      .logoutService()
      .then(response => {
        let data = response.data;
        if (data.status_code === 200) {
          toast.success(data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            closeButton: true,
            autoClose: true,
          });
          cb(true);
          localStorage.clear();
          dispatch(setLogoutAuth());
        }
      })
      .catch(error => {
        let err = error.response.data;
        cb(false);
        localStorage.clear();
        dispatch(setLogoutAuth());//if token deleted directly from localstorage ==> this code work for that condition
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        console.log("Error: ", error.response);
      });
  };
};

export const addCustomer = data => {
  return dispatch => {
    api
      .addCustomerService(data)
      .then(response => {})
      .catch(error => {
        let err = error.response.data;
        console.log("Error: ", err);
      });
  };
};

export const changedImage = url => {
  return dispatch => {
    dispatch({
      type: actionTypes.CHANGED_IMAGE,
      payload: url,
    });
  };
};

export const setSubscriptionStatus = status => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SUBSCRIPTION_STATUS,
      payload: status,
    });
  };
};

export const setTrialStatus = status => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_TRIAL_STATUS,
      payload: status,
    });
  };
};
