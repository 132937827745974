import React, { memo } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Skeleton } from "@material-ui/lab";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const containerStyle = {
  width: "100%",
  height: "380px",
};

const Map = props => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey, // ,
    // ...otherOptions
  });

  const [center, setCenter] = React.useState({
    lng: 0,
    lat: 0,
  });

  React.useEffect(() => {
    if (props?.position) {
      setCenter(props.position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderMap = () => {
    return (
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12}>
        <Marker position={props.position} />
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    RenderMap()
  ) : (
    <Skeleton variant="rect" animation="wave" width="95%" height="20vh" />
  );
};

const MemoMap = memo(Map, (prevProps, nextProps) => {
  return String(prevProps.position) === String(nextProps.position);
});

export default MemoMap;
