/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  TextField,
  Button,
  Typography,
  Chip,
  Divider,
} from "@material-ui/core";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
// import Elements from "@stripe/react-stripe-js";
import "./AddCardForm.css";
import * as Action from "../../modules/actions";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#117ACA",
      color: "#000000",
      fontWeight: 500,
      fontFamily: "URW DIN-reg",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        fontfamily: "URW DIN-reg",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.35)",
      },
      backgroundColor: "transperant",
    },
    invalid: {
      iconColor: "#eb1c26",
      color: "#eb1c26",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const SubmitButton = ({
  showLoader,
  error,
  children,
  disabled,
  handleSubmit,
}) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={showLoader || disabled || error}
    onClick={handleSubmit}
  >
    {showLoader ? "Loading....!!!" : children}
  </button>
);

const CheckoutForm = ({
  handleCardFormOpen,
  email,
  getCards,
  total,
  applyCoupon,
  couponValue,
  coupon,
  handleCouponChange,
  removeCoupon,
  fromMain,
  loader1,
  amountOff,
}) => {
  const stripe = useStripe();

  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [paymentCard, setPaymentCard] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    if (cardComplete) {
      setShowLoader(true);
    }

    const card = elements.getElement(CardElement);

    const payload = await stripe.createToken(card);

    const cardToken = payload.token.id;

    if (payload.error) {
      setError(payload.error);
    } else {
      let payloadCard = { email: email, token: cardToken };

      Action.AddCardCall(payloadCard, cb => {
        if (cb) {
          sessionStorage.setItem("card", JSON.stringify(payload.token));
          setPaymentCard(payload.token.card);
          window.showCardDetails(payload.token);
          handleCardFormOpen();
          if (getCards !== null) {
            getCards();
          }
        }
        setShowLoader(false);
      });
    }
  };

  return (
    <div
      className="Form"
    // onSubmit={handleSubmit}
    >
      <div className="FormGroup">
        <CardField
          onChange={e => {
            setError(e.error);
            setCardComplete(e.complete);
            setDisabled(false);
          }}
        />
      </div>
      {error && (
        <div className="input-error-style input-error-style-new-red pt-10">
          {error.message}
        </div>
      )}
      {fromMain && (
        <>
          <div className="mt-32 coupon-container">
            {couponValue === 0 ? (
              <>
                <TextField
                  id="coupons"
                  label="Add Coupon"
                  variant="outlined"
                  className="coupon-input"
                  value={coupon}
                  onChange={handleCouponChange}
                  disabled={!stripe || disabled}
                />
                <Button
                  variant="contained"
                  className="apply-coupon-btn"
                  onClick={applyCoupon}
                  disabled={!stripe || disabled}
                >
                  {loader1 ? "Processing..." : "Apply"}
                </Button>
              </>
            ) : (
              <Typography className="coupon-text">
                <Chip label={coupon} variant="outlined" />{" "}
                <span onClick={removeCoupon} className="remove-coupon">
                  remove coupon
                </span>
              </Typography>
            )}
          </div>
          <div className="mt-32">
            <div className="total-amount">
              <Typography variant="h6">Price:</Typography>
              <Typography variant="h6">${total}</Typography>
            </div>
            {couponValue ? (
              <div className="applied-amount total-amount mt-5">
                <Typography variant="h6">Discount %:</Typography>
                <Typography variant="h6"> {couponValue}%</Typography>
              </div>
            ) : (
              ""
            )}
            {amountOff ? (
              <div className="applied-amount total-amount mt-5">
                <Typography variant="h6">Discount:</Typography>
                <Typography variant="h6">${amountOff / 100}</Typography>
              </div>
            ) : (
              ""
            )}
            <Divider
              style={{
                opacity: "0.6",
                borderColor: "rgba(0, 0, 0, 0.6)",
                margin: "10px 0",
              }}
            />
            <div className="total-amount mt-5">
              <Typography variant="h6">Total:</Typography>
              <Typography variant="h6">
                $
                {couponValue || amountOff
                  ? amountOff > 0
                    ? total - amountOff / 100
                    : (total - (total * couponValue) / 100).toFixed(2)
                  : total}
              </Typography>
            </div>
          </div>{" "}
        </>
      )}

      <SubmitButton
        showLoader={showLoader}
        error={error}
        disabled={!stripe || disabled}
        handleSubmit={handleSubmit}
      >
        ADD CARD
      </SubmitButton>
    </div>
  );
};

const AddCardForm = ({
  stripePromise,
  handleCardFormOpen,
  email,
  getCards,
  total,
  applyCoupon,
  couponValue,
  coupon,
  handleCouponChange,
  removeCoupon,
  fromMain,
  loader1,
  amountOff,
}) => {
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise}>
        <CheckoutForm
          handleCardFormOpen={handleCardFormOpen}
          email={email}
          getCards={getCards}
          total={total}
          couponValue={couponValue}
          applyCoupon={applyCoupon}
          coupon={coupon}
          handleCouponChange={handleCouponChange}
          removeCoupon={removeCoupon}
          fromMain={fromMain}
          loader1={loader1}
          amountOff={amountOff}
        />
      </Elements>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  email: auth.email,
});

export default connect(mapStateToProps, undefined)(AddCardForm);
