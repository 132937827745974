/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Container,
} from "@material-ui/core";
import { Modal } from "react-responsive-modal";
import { isEmpty } from "lodash";
// import { toast } from "react-toastify";
import Loader from "../../assets/images/loader.svg";
import CheckCircleBlue from "../../assets/images/check-circle-blue.svg";
import stripe from "../../assets/images/stripe-logo.svg";

import * as Action from "../../modules/actions";
import { setCoupon, getCoupon } from "../../utils/localStorage";
import "react-responsive-modal/styles.css";
import CardDialog from "../../components/StripeCard/CardDialog";
import AppHeader from "./header";
import "./subscription.css";
class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      couponCodeError: null,
      setOpen: "",
      showLoader: false,
      showCard: null,
      makeDefaultLoader: false,
      newDefaultCard: "",
      defaultCard: null,
      allCards: [],
      cardSelectModal: false,
      contentLoader: false,
      loader1: false,
      loader2: false,
      price: "",
      couponValue: 0,
      amountOff: 0,
      isAmountOff: false,
    };

    window.showCardDetails = this.showCardDetails;
  }

  componentDidMount() {
    this.getCards();
    this.getPrice();
  }

  getPrice = () => {
    this.setState({ contentLoader: true });

    Action.GetPriceCall(data => {
      if (data) {
        this.setState({
          price: data.price,
        });
      }
      this.setState({ contentLoader: false });
    });
  };

  getCards = () => {
    this.setState({ contentLoader: true });

    Action.getCustomerCards({ email: this.props.email }, data => {
      if (data) {
        this.setState({
          allCards: data.data,
          defaultCard: data.defaultCard,
        });
      }
      this.setState({ contentLoader: false });
    });
  };

  handleLogout = () => {
    this.setState({ showLoader: true });
    this.props.SignOut(cb => {
      if (cb === true) {
        this.props.history.push("/home");
      }
      this.setState({ showLoader: false });
    });
  };

  showCardDetails = data => {
    this.setState({ showCard: data });
  };

  // handleChange = e => {
  //   const { name, value } = e.target;
  //   this.setState({ [name]: value });
  // };

  // onOpenModal = () => {
  //   this.setState({
  //     setOpen: true,
  //   });
  // };

  onCloseModal = () => {
    this.setState({
      setOpen: false,
    });
  };

  ApplyCoupon = () => {
    if (this.state.couponCode !== null) {
      this.setState({
        loader1: true,
      });
      Action.ApplyCoupon(
        {
          coupon_code: this.state.couponCode,
        },
        cb => {
          if (cb) {
            setCoupon(this.state.couponCode);
            this.setState({
              couponValue: cb.valid ? cb.percent_off : 0,
              amountOff: cb.amount_off,
              isAmountOff: cb.amount_off > 0 ? true : false,
            });
          } else {
            this.setState({
              loader1: false,
              couponCodeError: cb.message,
            });
          }
        }
      );
    } else {
      this.setState({ couponCodeError: "Field can’t be empty." });
    }
  };

  CreateSubscription = () => {
    this.setState({
      makeDefaultLoader: true,
    });
    let coupon = getCoupon();
    let payload = {
      email: this.props?.email,
    };
    if (coupon) {
      payload = {
        ...payload,
        coupon,
      };
    }

    Action.CreateSubscription(payload, cb => {
      if (cb) {
        window.open(
          cb.payment_intent.next_action.redirect_to_url.url,
          "_blank"
        );
        this.props.history.push("/home");
      }
      this.props.history.push("/home");
      this.setState({
        makeDefaultLoader: false,
      });
      window.reload();
    });
  };

  openAddCard = () => {
    window.openAddCardAcc(true);
  };

  addCardDetails = () => {
    let cardData = JSON.parse(sessionStorage.getItem("card"));
    return (
      <>
        {!isEmpty(cardData) && (
          <div className="card-showcase">
            <div style={{ marginBottom: "10px" }}>
              CARD NUMBER: xxxx xxxx xxxx {cardData?.card?.last4 || ""}
            </div>
            <div>
              EXPIRES ON: {cardData?.card.exp_month || ""}/
              {cardData?.card?.exp_year || ""}
            </div>
          </div>
        )}
      </>
    );
  };

  // onDeleteCard = (index, card) => {
  //   let cards = this.state.allCards;
  //   if (cards.length <= 1) {
  //     toast.success("Can't Remove All Cards", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       closeButton: true,
  //       autoClose: true,
  //     });
  //   } else {
  //     if (this.state.defaultCard === card.id) {
  //       toast.success("Can't remove default card", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         closeButton: true,
  //         autoClose: true,
  //       });
  //     } else {
  //       cards.splice(index, 1);
  //       this.setState({
  //         cards,
  //       });

  //       let payload = {
  //         email: this.props.email,
  //         card_id: card.id,
  //       };
  //       Action.deleteCustomerCard(payload, data => {
  //         console.log("delete card", data);
  //       });
  //     }
  //   }
  // };

  makeDefault = cardId => {
    this.setState({
      makeDefaultLoader: true,
    });
    let payload = {
      email: this.props.email,
      card_id: cardId,
    };
    Action.setDefaultCard(payload, data => {
      this.setState({
        makeDefaultLoader: false,
      });
      this.getCards();
    });
  };

  handleDefaultCard = e => {
    this.setState({
      newDefaultCard: e.target.value,
    });
  };

  setSelectCard = () => {
    const { defaultCard, newDefaultCard, allCards } = this.state;
    let default_card = null;

    if (defaultCard !== newDefaultCard) {
      if (newDefaultCard !== "") {
        this.makeDefault(newDefaultCard);

        default_card = newDefaultCard;
      } else {
        default_card = defaultCard;
      }
    }

    allCards.some(card => {
      if (card.id === default_card) {
        sessionStorage.setItem("card", JSON.stringify(card));
        return true;
      }
    });
    this.setState({
      cardSelectModal: false,
      showCard: true,
    });
  };

  handleCouponChange = e => {
    this.setState({
      couponCode: e.target.value,
    });
  };

  removeCoupon = () => {
    this.setState({
      couponValue: 0,
      couponCode: "",
      couponCodeError: null,
      loader1: false,
      amountOff: 0,
    });
  };

  handleFreeTrial = () => {
    this.setState({
      loader2: true,
    });
    Action.activateTrialPeriodCall(cb => {
      if (cb) {
        this.props.setTrialStatus(true);
        this.props.history.push("/dashboard");
      }
      this.setState({
        loader2: false,
      });
    });
  };

  render() {
    let {
      // setOpen,
      couponCode,
      // couponCodeError,
      showLoader,
      showCard,
      allCards,
      defaultCard,
      makeDefaultLoader,
      newDefaultCard,
      cardSelectModal,
      contentLoader,
      loader1,
      price,
      couponValue,
      loader2,
      amountOff,
      isAmountOff,
    } = this.state;
    return (
      <div className="subscription-container">
        <AppHeader
          handleLogout={() => this.handleLogout()}
          showLoader={showLoader}
        />

        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="subscription-title mt-97">
              One Subscription.
              <br />
              Endless Opportunities
            </div>
            <div className="subscription-content mt-35">
              A small monthly fee opens the door to
              <br />
              numerous in-demand, well-paying visa
              <br />
              positions
            </div>
          </Grid>


          <Grid item xs={12} sm={12} md={6} lg={6}>

            <div
              className={
                window.location.pathname.split("/")[1] === "subscription-plan"
                  ? "plan-box"
                  : "payment-no-plan-box "
              }
            >
              <div className="subscription-content" style={{ fontWeight: "lighter" }}>
                BUILT TO BE DELETED
              </div>

              <div className="plan-amount">
                $
                {couponValue || isAmountOff
                  ? isAmountOff
                    ? price - amountOff / 100
                    : (price - (price * couponValue) / 100).toFixed(2)
                  : price}
                <span className="currency-title">USD</span><span className="currency-title"> / month</span>
              </div>
              {/* <p className="plan-amount-sub-text"> *Local taxes may apply</p> */}
              {window.location.pathname.split("/")[1] ===
                "subscription-plan" && (
                  <div className="mt-28">
                    <div className="disply-flex">
                      <img src={CheckCircleBlue} alt="img" />
                      <div className="sub-list-text pl-10 ">
                        Month to Month subscriptions, cancel subscription when you
                        receive your dream offer
                      </div>
                    </div>
                    <div className="disply-flex mt-30">
                      <img src={CheckCircleBlue} alt="img" />
                      <div className="sub-list-text pl-10">
                        Access to sponsor ready job opportunities
                      </div>
                    </div>
                    <div className="disply-flex mt-30">
                      <img src={CheckCircleBlue} alt="img" />
                      <div className="sub-list-text pl-10 ">
                        First access to new Visa sponsorship opportunities{" "}
                      </div>
                    </div>
                  </div>
                )}

              {window.location.pathname.split("/")[1] ===
                "subscription-plan" ? (
                <div className="display-flex btn-box">
                  <Button
                    value="Submit"
                    className="subscribe-btn text-capitalized mt-28"
                    disabled={contentLoader}
                    onClick={() => {
                      if (!showCard) {
                        !isEmpty(allCards)
                          ? this.setState({ cardSelectModal: true })
                          : this.openAddCard();
                      } else {
                        this.props.history.push("/subscription-payment");
                      }
                    }}
                  >
                    {makeDefaultLoader || contentLoader ? (
                      <img src={Loader} height="20" alt="img" />
                    ) : (
                      "Subscribe"
                    )}
                  </Button>
                  {this.props.trial === null && (
                    <Button
                      value="Submit"
                      className="have-any-btn text-capitalized mt-28"
                      disabled={loader2}
                      style={{
                        opacity: loader2 ? 0.5 : 1,
                      }}
                      onClick={this.handleFreeTrial}
                    >
                      24-Hour Free Trial
                    </Button>
                  )}
                  <br />
                  {showCard && this.addCardDetails()}
                </div>
              ) : (
                <Button
                  value="Submit"
                  className="subscribe-btn make-payment-btn text-capitalized mt-28"
                  onClick={() => {
                    this.CreateSubscription();
                  }}
                >
                  {makeDefaultLoader ? (
                    <img src={Loader} height="20" alt="img" />
                  ) : (
                    "Make Payment"
                  )}
                </Button>
              )}
             <div className="stripe-logo mt-10 mr-20 mb-10" style={{}} >
               <img src={stripe} style={{width:"140px"}} alt="img" />
             </div>
            </div>
          </Grid>
        </Grid>

        <CardDialog
          fromMain={true}
          coupon={couponCode}
          total={price}
          applyCoupon={this.ApplyCoupon}
          couponValue={couponValue}
          handleCouponChange={this.handleCouponChange}
          removeCoupon={this.removeCoupon}
          loader1={loader1}
          onCloseModal={this.onCloseModal}
          amountOff={amountOff}
        />

        <div>
          <Modal
            open={cardSelectModal}
            onClose={() => this.setState({ cardSelectModal: false })}
            center
            classNames={{ root: "card-select-modal" }}
          >
            <Container className="cards-list">
              <div className="select-card-top">
                <p> Select Card</p>
                <Button
                  className="join-btn text-capitalized"
                  onClick={this.openAddCard}
                >
                  Add Card
                </Button>
              </div>
              <FormControl>
                <RadioGroup
                  name="select-card"
                  value={newDefaultCard || defaultCard}
                  onChange={this.handleDefaultCard}
                  style={{ justifyContent: "space-between" }}
                >
                  {!isEmpty(allCards) &&
                    allCards.map((card, index) => (
                      <FormControlLabel
                        key={index}
                        value={card.id}
                        control={<Radio />}
                        label={
                          <div className="card-showcase">
                            <div style={{ marginBottom: "10px" }}>
                              CARD NUMBER: xxxx xxxx xxxx {card.card.last4}
                            </div>
                            <div>
                              EXPIRES ON: {card.card.exp_month}/
                              {card.card.exp_year}
                            </div>
                          </div>
                        }
                      />
                    ))}
                </RadioGroup>
              </FormControl>
              <div className="display-flex btn-box">
                <Button
                  value="Submit"
                  className="join-btn text-capitalized mt-28"
                  onClick={this.setSelectCard}
                >
                  {showLoader ? (
                    <img src={Loader} height="20" alt="img" />
                  ) : (
                    "Select Card"
                  )}
                </Button>
                <Button
                  value="Submit"
                  className="cancel-btn text-capitalized mt-28 ml-20"
                  onClick={() => this.setState({ cardSelectModal: false })}
                >
                  Cancel
                </Button>
              </div>
            </Container>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  email: auth.email,
  name: auth.name,
  trial: auth.trial_status,
});

const mapDispatchToProps = dispatch => ({
  SignOut: cb => dispatch(Action.LogoutAuth(cb)),
  setTrialStatus: status => dispatch(Action.setTrialStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
