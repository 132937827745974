import axios from "axios";
import { getToken } from "./utils/localStorage";

const instance = axios.create({
  baseURL: `${window.location.protocol}//`,
});

instance.all = axios.all;
instance.spread = axios.spread;

axios.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default instance;
