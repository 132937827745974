import * as actionTypes from "../actions/actionTypes";

const initialState = {
  page: 1,
  count: null,
  next: null,
  previous: null,
  results: [],

  filters: [],
  selectedFilter: null,
  keyword: "",
  location: "",
  radius: 20,
  job_type: "",
  date_posted: "All",
  salary: [11200, 30000],
  included_jobs_without_salary_mentioned: true,

  showLoader: false,
  mobileDrawer: false,
  contentLoader: false,

  changedState: false,
  getJobsCheck: false,
  clearData: false,
  fromFilter: false,
  applyFilter: false,

  liveJobs: null,
  todayJobs: null,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FIND_JOBS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CONTENT_LOADER:
      return {
        ...state,
        contentLoader: action.payload,
      };
    case actionTypes.GET_JOBS_CHECK:
      return {
        ...state,
        getJobsCheck: action.payload,
      };
    case actionTypes.SET_DEFAULT_JOBS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
