import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import SocialIcons from "../icons/SocialIcons";
import Logo from "../../assets/images/logo.png";
import stripe from "../../assets/images/stripe-logo.svg";
import "./appfooter.css";

class AppFooter extends Component {
  constructor(props) {
    super(props);

    this.state = { email: "", email_error: "" };
  }

  render() {
    return (
      <div className="footer-div ">
        {/* Desktop screen  */}
        <Grid container className="footer-desktop">
          <Grid item md={4} lg={4} className="pb-65">
            <div className="mt-10 ml-80 logo ">
              {" "}
              <img
                className="cursor-pointer"
                src={Logo}
                onClick={() => {
                  this.props.history.push("/home");
                }}
                alt="logo"
                height="50px"
                width={"180px"}
              />
            </div>

            <div className="email-text ml-86 mt-20">
              <a className="mailto" href="mailto:support@visabridge.co">
                support@visabridge.co
              </a>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            lg={4}
            style={{
              textAlign: "center",
            }}
          >
            <div className="footer-text mt-8">Company</div>
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/how-it-works");
              }}
            >
              How it works
            </div>
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/about-us");
              }}
            >
              About us
            </div>
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/contact");
              }}
            >
              Contact
            </div>
          </Grid>
          <Grid
            item
            md={4}
            lg={4}
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/terms-and-conditions");
              }}
            >
              Terms & conditions
            </div>
          </Grid>
          <hr className="bottom-line" />
          <Grid
            item
            xs={6}
            sm={6}
            md={12}
            lg={12}
            className="rights-res-text disply-flex ml-86 mr-86"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{ display: "flex", paddingTop: "6", paddingRight: "5" }}
            >
              <p className="rights-symbol pt-6 pr-5"> ©</p>
              <p style={{ marginTop: "23px" }}>
                2022 VisaBridge, All Rights Reserved.{" "}
              </p>{" "}
            </div>

            <img src={stripe} style={{ width: "140px" }} alt="img" />
          </Grid>
          {/* <Grid item xs={6} sm={6} md={2} lg={2} className="disply-flex">
            <SocialIcons
              type="facebook"
              color="#000"
              style={{
                paddingTop: "12px",
                paddingRight: "15px",
              }}
            />
            <SocialIcons
              type="twitter"
              color="#000"
              style={{ paddingTop: "12px", paddingRight: "15px" }}
            />
            <SocialIcons
              type="linkedin"
              color="#000"
              style={{ paddingRight: "15px" }}
            />
            <SocialIcons
              type="insta"
              color="#000"
              style={{ paddingRight: "5px" }}
            />
          </Grid> */}
        </Grid>

        {/* Mobile screen  */}
        <Grid container className="footer-mobile">
          <Grid item xs={6} sm={6} md={3} lg={3} className="pb-65">
            <div className="mt-10 logo">
              <img
                className="cursor-pointer ml-20"
                src={Logo}
                alt="logo"
                height="50px"
                width={"180px"}
              />
            </div>

            <div className="email-text  mt-20 ml-20" style={{ color: "#fff" }}>
              <a className="mailto" href="mailto:support@visabridge.co">
                support@visabridge.co
              </a>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}></Grid>
          <Grid item xs={5} sm={5} md={2} lg={2} className="ml-20">
            <div className="footer-text mt-8">Company</div>
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/how-it-works");
              }}
            >
              How it works
            </div>
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/about-us");
              }}
            >
              About us
            </div>
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/contact");
              }}
            >
              Contact
            </div>
          </Grid>
          <Grid item xs={5} sm={5} md={4} lg={4} className="ml-20">
            <div
              className="footer-sub-text mt-8"
              onClick={() => {
                this.props.history.push("/terms-and-conditions");
              }}
            >
              Terms & conditions
            </div>
          </Grid>
          <hr className="bottom-line mt-40" />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="rights-res-text disply-flex ml-20 mr-20"
            style={{ justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", }}>
              <p className="rights-symbol pt-6 pr-5"> ©</p>
              <p style={{ marginTop: "23px" }}>
                2022 VisaBridge, All Rights Reserved.{" "}
              </p>{" "}
            </div>

            <img src={stripe} style={{ width: "140px" }} alt="img" />
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="disply-flex ml-20 pb-20"
          >
            <SocialIcons
              type="facebook"
              color="#000"
              style={{
                paddingTop: "12px",
                paddingRight: "15px",
              }}
            />
            <SocialIcons
              type="twitter"
              color="#000"
              style={{ paddingTop: "12px", paddingRight: "15px" }}
            />
            <SocialIcons
              type="linkedin"
              color="#000"
              style={{ paddingRight: "15px" }}
            />
            <SocialIcons
              type="insta"
              color="#000"
              style={{ paddingRight: "5px" }}
            />
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default AppFooter;
