/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import Loader from "../../assets/images/loader.svg";
import UserProfileIcon from "../../assets/images/user-name-icon.svg";
import MailIcon from "../../assets/images/mail.svg";
import LockIcon from "../../assets/images/lock.svg";
import MapPinIcon from "../../assets/images/map-pin.svg";
import EyeIcon from "../../assets/images/eye.svg";
import EyeOffIcon from "../../assets/images/eye-off.svg";
import Select from "react-select";
import { countries } from "../../utils/countries";
import { validateEmail, passwordValidation } from "../../utils/validations";
import { MESSAGE } from "../../constants/messages";
import * as Action from "../../modules/actions";
import "./sign-up.css";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: "",
      emailSendMessage: "Account successfully created. Email verification link has been sent.",
      emailSendStatus: false,
      selectedCountry: null,
      form: {
        name: "",
        email: "",
        password: "",
      },
      countryErrorMsg: null,
      passwordVisibility: false,
      formErrors: {
        name: null,
        email: null,
        password: null,
      },
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;

    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(name, value);
      formErrorsObj = { ...formErrors, [name]: errorMsg };

      this.setState({ formErrors: formErrorsObj });
    });
  };
  validateField = (name, value) => {
    let errorMsg = null;
    switch (name) {
      case "name":
        if (!value) errorMsg = MESSAGE.EMPTY_FIELD;
        break;
      case "email":
        if (!value) {
          errorMsg = MESSAGE.EMPTY_FIELD;
        } else {
          if (!validateEmail(value)) {
            errorMsg = MESSAGE.EMAIL_INVALID;
          }
        }
        break;
      case "password":
        if (!value) {
          errorMsg = MESSAGE.EMPTY_FIELD;
        } else {
          if (!passwordValidation(value)) {
            errorMsg = MESSAGE.PASSWORD_INVALID;
          }
        }
        break;
      case "message":
        if (!value) errorMsg = MESSAGE.EMPTY_FIELD;
        break;
      default:
        break;
    }
    return errorMsg;
  };

  handleCountryChange = selectedOption => {
    this.setState({ selectedCountry: selectedOption });
    this.setState({
      countryErrorMsg: null,
    });
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleEnterPress = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      this.handleSignUp();
    }
  };

  handleSignUp = () => {
    const { form, formErrors } = this.state;
    if (this.state.selectedCountry === null) {
      this.setState({
        countryErrorMsg: "Field can’t be empty.",
      });
    } else {
      this.setState({
        countryErrorMsg: null,
      });
    }
    if (this.state.designation !== "") {
      this.setState({
        designationError: false,
        designationErrorMsg: "",
      });
    } else {
      this.setState({
        designationError: true,
        designationErrorMsg: "Field can’t be empty.",
      });
    }

    const errorObj = this.validateForm(form, formErrors, this.validateField);

    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      let payload = {
        name: form.name,
        email: form.email,
        password: form.password,
        country: this.state.selectedCountry.label,
      };
      this.setState({
        showLoader: true,
      });

      Action.SignUp(payload, cb => {
        if (cb === true) {
          this.setState({
            showLoader: false,
            options: "",
            selectedCountry: null,
            form: {
              name: "",
              email: "",
              password: "",
            },
            countryErrorMsg: null,
            passwordVisibility: false,
            formErrors: {
              name: null,
              email: null,
              password: null,
            },
          });
          //  this.props.history.push("/login");
          this.setState({
            emailSendStatus: true
          })
        }
        this.setState({
          showLoader: false,
        });
      });
    }
  };
  render() {
    console.log(this.state)
    let {
      selectedCountry,
      form,
      formErrors,

      countryErrorMsg,
      passwordVisibility,
      showLoader,
    } = this.state;
    return (
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div
          className="mt-90"
          onClick={() => {
            this.props.history.push("/home");
          }}
        >
          <img
            className="cursor-pointer visabridge"
            src="https://visa-bridge-upload.s3.us-east-2.amazonaws.com/resources/visa-bridge_logo.png"
            width={"180px"}
            alt="logo"
            height="50px"
          />
        </div>
        <div className="terms-heading mt-40">Sign Up</div>
        <div onKeyDown={this.handleEnterPress}>
          <div className="mt-28">
            <img src={UserProfileIcon} alt="img" className="icon" />
            <input
              className={
                formErrors.name
                  ? "signup-input-field error-border"
                  : "signup-input-field"
              }
              type="text"
              placeholder="Name"
              autoComplete="off"
              name="name"
              value={form.name}
              onChange={this.handleChange}
            />
            {formErrors.name && <div className="err">{formErrors.name}</div>}
          </div>
          <div className="mt-28">
            <img src={MailIcon} alt="img" className="icon" />
            <input
              className={
                formErrors.email
                  ? "signup-input-field error-border"
                  : "signup-input-field"
              }
              type="text"
              placeholder="Email"
              autoComplete="off"
              name="email"
              value={form.email}
              onChange={this.handleChange}
            />

            {formErrors.email && <div className="err">{formErrors.email}</div>}
          </div>
          <div className="mt-28">
            <img src={LockIcon} alt="img" className="icon" />
            <input
              className={
                formErrors.password
                  ? "signup-input-field error-border pr-50"
                  : "signup-input-field pr-50"
              }
              type={passwordVisibility ? "text" : "password"}
              placeholder="Password"
              autoComplete="off"
              name="password"
              value={form.password}
              onChange={this.handleChange}
            />
            {passwordVisibility ? (
              <img
                onClick={() => {
                  this.setState({
                    passwordVisibility: false,
                  });
                }}
                src={EyeOffIcon}
                alt="img"
                className="eye-icon"
              />
            ) : (
              <img
                onClick={() => {
                  this.setState({
                    passwordVisibility: true,
                  });
                }}
                src={EyeIcon}
                alt="img"
                className="eye-icon"
              />
            )}

            {formErrors.password && (
              <div className="err err-w-59">{formErrors.password}</div>
            )}
          </div>

          <div className="mt-28 country-div">
            <img src={MapPinIcon} alt="img" className="icon z-2" />

            <Select
              className={countryErrorMsg ? "error-border" : ""}
              options={countries}
              value={selectedCountry}
              placeholder="Country"
              onChange={this.handleCountryChange}
              classNamePrefix="select"
            />
            {countryErrorMsg && <div className="err">{countryErrorMsg}</div>}
            {this.state.emailSendStatus ? <div style={{ color: "green" }} className="err">{this.state.emailSendMessage}</div> : null}

          </div>
        </div>

        <div>
          <Button
            value="Submit"
            className="create-btn text-capitalized mt-28"
            onClick={this.handleSignUp}
          >
            {showLoader ? (
              <img src={Loader} height="20" alt="img" />
            ) : (
              "Create Account"
            )}
          </Button>
        </div>
        <div className="horizontal-line-div mt-30">
          <span>OR</span>
        </div>
        <div className="disply-flex bottom-text-signin">
          <div>Already have an Account?</div>
          <div
            className="blue-color pl-6 cursor-pointer"
            onClick={() => {
              this.props.history.push("/login");
            }}
          >
            Log In
          </div>
        </div>
      </Grid>
    );
  }
}

export default SignUp;
