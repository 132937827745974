/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import api from "../../services";
import axios from "../../axios";

//Verify Coupon Action
export const ApplyCoupon = (payload, cb) => {
  api
    .VerifyCoupon(payload)
    .then(response => {
      let data = response.data;

      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(data.response);
      }
    })
    .catch(error => {
      let err = error.response.data;
      cb(err);
      // if (err.status_code === 409) {
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });

      console.log("Error: ", error.response);
    });
};

//Subscription Action
export const CreateSubscription = (payload, cb) => {
  api
    .CreateSubscription(payload)
    .then(response => {
      let data = response.data;

      console.log("data", data);

      if (data.status_code === 201) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
      }
      cb(data);
    })
    .catch(error => {
      let err = error.response.data;
      cb(false);

      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });

      console.log("Error: ", error.response);
    });
};

//Add Card Action
export const AddCardCall = (payload, cb) => {
  api
    .addCard(payload)
    .then(response => {
      let data = response.data;

      if (data.status_code === 200) {
        toast.success(data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          closeButton: true,
          autoClose: true,
        });
        cb(true);
      }
    })
    .catch(error => {
      let err = error.response.data;
      cb(false);

      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: true,
        autoClose: true,
      });

      console.log("Error: ", error.response);
    });
};

export const GetPriceCall = cb => {
  api
    .getPriceService()
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        cb(data);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", err);
      cb(false);
    });
};

export const activateTrialPeriodCall = cb => {
  api
    .activateTrialService()
    .then(response => {
      let data = response.data;
      if (data.status_code === 200) {
        cb(data);
      }
    })
    .catch(error => {
      let err = error.response.data;
      console.log("Error: ", err);
      cb(false);
    });
};
